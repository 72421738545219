import React from "react";

import ScreenTypeC from "../../components/ScreenTypeC";
import { QUESTION_LABELS } from "../../utils/constants";
import Title from "../../typocomps/Title";
import television from "../../images/television.jpg";
import PATHS from "../../utils/constants";

const GamesScreen = () => {
  return (
    <ScreenTypeC
      text={
        <Title>
          Do you enjoy
          <br />
          virtual realities
          <br />
          or playing
          <br />
          games?
        </Title>
      }
      optionUp={"yes"}
      optionDown={"no"}
      pathUp={PATHS.Tickets + "14"}
      pathDown={PATHS.Tickets + "15"}
      image={television}
      questionLabel={QUESTION_LABELS.ONEMORE}
      showLine={true}
      sound="VirtualSpace"
    />
  );
};

export default GamesScreen;
