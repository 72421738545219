import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import aging from "../../images/aging.jpg";
import line from "../../images/lines/lineC2pt.png";
import PATHS from "../../utils/constants";

const AgingScreen = () => {
  return (
    <WiredChoices
      labelUp={
        <BoldLabel>
          preserve the <br />
          body and mind
        </BoldLabel>
      }
      labelDown={
        <BoldLabel>
          reverse the <br />
          process of aging
        </BoldLabel>
      }
      pathUp={PATHS.Tickets + "27"}
      pathDown={PATHS.Tickets + "28"}
      image={aging}
      line={line}
      questionLabel={QUESTION_LABELS.LAST}
      labelNumlines={2}
      sound="Body"
    />
  );
};

export default AgingScreen;
