import React from "react";
import { Link } from "react-router-dom";

import * as manager from "../utils/manager";
import PATHS from "../utils/constants";

const MyTicket = () => {

    const TicketID =  JSON.parse(window.localStorage.getItem("firstTicketID"));

    const userTicket = () => {
        console.log("users ticket: " + TicketID);
    }

    return(
        <div className="myTicketOuter">
            <button className="myTicketButton">
                <Link to={`${PATHS.Tickets}${TicketID}`} className="no-decoration" >
                    Return to my Ticket
                </Link>
            </button>     
            <div className="myTicketDivider"></div> 
        </div>
        
    );
}

export default MyTicket;