import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import TicketContent from "./TicketContent";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketType1 = ({
  id,
  avail,
  cost,
  title,
  text,
  image,
  imageTitle,
  imageRatio,
  titleLevel,
  gapFraction,
  titlev2,
  textv2,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.LANDSCAPE[versionToShow - 1][id];

  const SECTIONS =
    versionToShow <= 2
      ? ["0%", "70%", "30%", "0%"]
      : ["18%", "39%", "25%", "18%"];

  const tmpTitleLevel = versionToShow <= 2 ? 1 : titleLevel;
  const tmpGapFraction = versionToShow <= 2 ? 5 : gapFraction;
  const tmpTitle = versionToShow <= 2 && titlev2 ? titlev2 : title;
  const tmpText = versionToShow <= 2 && textv2 ? textv2 : text;

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      title={title}
      text={text}
      image={image}
      id={id}
    >
      <TicketContent>
        <div
          style={{
            position: "relative",
            top: tmpTitleLevel === 1 || tmpTitleLevel === 2 ? "0px" : "50%",
            height: "100%",
            width: SECTIONS[1],
            left: SECTIONS[0],
          }}
        >
          <div
            style={{
              position: "relative",
              height: tmpTitleLevel === 2 ? "50%" : null,
              display: tmpTitleLevel === 2 ? "flex" : null,
              flexDirection: tmpTitleLevel === 2 ? "column" : null,
              justifyContent: tmpTitleLevel === 2 ? "flex-end" : null,
            }}
          >
            {tmpTitle}
          </div>
          <div
            style={{
              position: "relative",
              top: `${tmpGapFraction}%`,
              width: "100%",
            }}
          >
            {tmpText}
          </div>
        </div>
        <div
          style={{
            position: "relative",
            width: SECTIONS[2],
            left: SECTIONS[0],
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Image
            alt=""
            src={image}
            style={{
              height: `${IMAGE_H}px`,
              width: `${IMAGE_W}px`,
            }}
          />
          {imageTitle}
        </div>
      </TicketContent>
    </Ticket>
  );
};

export default TicketType1;
