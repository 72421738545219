import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import PATHS from "../utils/constants";
import { Link } from "react-router-dom";
import BoldLabel from "../typocomps/BoldLabel";

import UnsplashSearch from "../components/UnsplashSearch";

import Usertickets from "../components/userTickets";

    // Set the configuration for your app
    const firebaseConfig = {
        apiKey: "AIzaSyCdReuu98P1G3H6QBlEaBAEmHPfBwS0ZM8",
        authDomain: "loveafterdeath-5ba5c.firebaseapp.com",
        databaseURL: "https://loveafterdeath-5ba5c-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "loveafterdeath-5ba5c",
        storageBucket: "loveafterdeath-5ba5c.appspot.com",
        messagingSenderId: "828316428743",
        appId: "1:828316428743:web:5fa03e265b70be981511e2",
        measurementId: "G-WLL87W5PYQ"
    };

    const firebaseApp = initializeApp(firebaseConfig);
    
    // Get a reference to the storage service, which is used to create references in your storage bucket
    const storage = getStorage(firebaseApp);

    function CreateTicket() {

        const storage = getStorage();

        // Create the file metadata
        /** @type {any} */
        const metadata = {
            contentType: 'image/jpeg'
        };

        const db = getFirestore();

        const [fileUrl, setFileUrl] = React.useState(null);
        const [users, setUsers] = React.useState([]);

        //upload image file to cloud storage
        const imageUpload = async (e) => {
            e.preventDefault();
            console.log(document.getElementById('TicketImagePreview').src);
            if(!document.getElementById('TicketImagePreview').src){
                alert("please choose an image");
                return;
            }
            document.getElementById('progressBarOuter').style.opacity = 1; //loading
            document.getElementById("progressBar").style.width = ("0%"); //loading
            const title = document.getElementById('titleUpload').value;

            if(await DuplicateName(title) == false){
                const file = document.getElementById('FileUpload').files[0];

                if(file){
                    console.log("NO file found");
                    //usng image uplaod to firestore
                
                    // Upload file and metadata to the object 'images/mountains.jpg'
                    const storageRef = ref(storage, 'images/' + file.name);
                    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

                    // Listen for state changes, errors, and completion of the upload.
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            document.getElementById("progressBar").style.width = (progress + "%");
                            switch (snapshot.state) {
                                case 'paused':
                                    console.log('Upload is paused');
                                break;
                                case 'running':
                                    console.log('Upload is running');
                                break;
                            }
                    }, 
                    (error) => {
                        switch (error.code) {
                            case 'storage/unauthorized':
                                console.log('err unauthorized');
                                alert("There was an error uploading, please try a different image");
                                document.getElementById('progressBarOuter').style.opacity = 0; //NOT loading
                            break;
                            case 'storage/canceled':
                                console.log('err canceled');
                                document.getElementById('progressBarOuter').style.opacity = 0; //NOT loading
                            break;
                            case 'storage/unknown':
                                console.log('err Unknown');
                                alert("sorry, there was an error uplaoding your ticket, please try again");
                                document.getElementById('progressBarOuter').style.opacity = 0; //NOT loading
                            break;
                        }
                    }, 
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            console.log('image name: ' + file.name);
                            dataBaseUpload( downloadURL, file.name);
                        });
                    });
                }else{
                    //unsplash selected, NO image uploaded
                    console.log("NO file found");
                    dataBaseUpload("unsplashImage", false);
                    return;
                }
            }else{
                document.getElementById('progressBarOuter').style.opacity = 0; //NOT loading
                alert("ticket name is already taken, please try a different one");
            }


        };

        //check if ticket name is unique
        const DuplicateName = async (title) => {
            const docRef = doc(db, "userTickets", title);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                    // doc title is uniques
                    return true;
                } else {
                    // doc already exists with same title
                    return false;
                }
        }

        //upload data to firestore database
        const dataBaseUpload = async ( imageName, downloadURL ) => {
            const utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');
            const title = document.getElementById('titleUpload').value;
            const text = document.getElementById('textUpload').value;
            if(downloadURL != false){
                console.log("Firebase image");
                var imageURL = imageName;
            }else{
                console.log("DOM image");
                var imageURL = document.getElementById('TicketImagePreview').src;
            }


            if(await DuplicateName(title) == false){
                let tag1 = document.getElementById('tag1input').value;
                let tag2 = document.getElementById('tag2input').value;
                if(tag1 == "no tag"){tag1 = null};
                if(tag2 == "no tag"){tag2 = null};

                await setDoc(doc(db, "userTickets", title), {
                    index: Math.floor((Date.now() * Math.random())), //maybe think about this one
                    title: title,
                    text: text,
                    imageName: imageName,
                    imgURL: imageURL,
                    //imgURL: document.getElementById('TicketImagePreview').src,
                    uploadDate: utc,
                    reveiwed: false,
                    tag1: tag1,
                    tag2: tag2,
                    BookRecommendation: document.getElementById('bookUpload').value,
                });
                document.getElementById('progressBarOuter').style.opacity = 0; //NOT loading
                alert("ticket uploaded");
            }else{
                document.getElementById('progressBarOuter').style.opacity = 0; //NOT loading
                alert("ticket is duplicate, not uploading");
            }
        };

        const fetchIndex = async () => {

            let exisitingItems = [];

            const q = query(collection(db, "userTickets"), where("reveiwed", "==", false));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                exisitingItems.push(doc.data());
            });
            console.log(exisitingItems.length); //array of objects for all records in userTickets database
            return(exisitingItems.length);
        };


        var loadFile = function(event) {
            var output = document.getElementById('TicketImagePreview');
            output.src = URL.createObjectURL(event.target.files[0]);
            output.onload = function() {
                URL.revokeObjectURL(output.src) // free memory
            }
        };

        var libraryPreveiw = function(event){
            var output = document.getElementById('TicketImagePreview');
            var input = document.getElementById('ImageSelector').value;
            output.src = input;
        };



        var charsLeft = (e) => {
            console.log(e.target.value.length); 
            document.getElementById("chars").innerHTML = (300 -e.target.value.length);
        }

        return (
            <div>
                <div className="CreateTicektPageHeader">
                    <p className="title">create your own ticket</p>
                    <p>
                        Tickets for the Afterlife is an evolving research project. If you think something is missing from our collection, you have the opportunity to suggest it here. 
                        Your suggestions could be anything from existing religious or cultural rituals to completely fictional or theoretical practices. We want to capture your unique
                        ideas on what you can do with your body, memories and legacies, once you depart from the living world.
                    </p>
                    <Link to={PATHS.Thanks} className="no-decoration">
                        <BoldLabel style={{fontSize: 16, marginTop: "1rem"}}>
                            Back to Quiz {'\u00A0'}
                        </BoldLabel>
                    </Link>
                </div>
                
                <div id="uploadOuter">
                    <div id="uploadLeft">
                        <form onSubmit={imageUpload}>
                            <div id="uploadinputsContainer">
                                <div id="uploadImageContainer">
                                    <img id="TicketImagePreview" alt="" />
                                </div>
                                <div id="uploadTextContainer">
                                    <input type="text" id="titleUpload" name="title" required="required" maxlength="40" placeholder="ADD A TITLE" />
                                    <textarea onChange={charsLeft} maxlength="300" rows="8" cols="50" id="textUpload" name="text" required="required"  placeholder="ADD SOME TEXT" />
                                    <p id="charsLeft"><span id="chars">300</span>&nbsp; characters remaining.</p>
                                </div>
                                <div id="uploadTagsContainer">
                                    <p id="uploadTagstext">Select tags that describe this ticket</p>
                                    <select name="tag1" id="tag1input">
                                    <option >no tag</option>
                                        <option value="future">future</option>
                                        <option value="fictional">fictional</option>
                                        <option value="traditional">traditional</option>
                                        <option value="past">past</option>
                                        <option value="unavailable">unavailable</option>
                                        <option value="available">available</option>
                                        <option value="high cost">high cost</option>
                                        <option value="low cost">low cost</option>
                                    </select>
                                    <select name="tag2" id="tag2input">
                                        <option >no tag</option>
                                        <option value="future">future</option>
                                        <option value="fictional">fictional</option>
                                        <option value="traditional">traditional</option>
                                        <option value="past">past</option>
                                        <option value="unavailable">unavailable</option>
                                        <option value="available">available</option>
                                        <option value="high cost">high cost</option>
                                        <option value="low cost">low cost</option>
                                    </select>
                                </div>
                            </div>
                            <div id="UnsplashSearchContainer">
                                <UnsplashSearch />
                            </div>
                            <div id="FileUploadInoutContainer">
                                <p><b>Or upload your own image</b> </p>
                                <p>We support images up to 5Mb in the formats PNG, JPG, GIF and WebP. </p>
                                <input type="file" id="FileUpload" onChange={loadFile}/>
                            </div>
                            <div id="BookInputContainer">
                                <p id="uploadBooktext">Optionally, provide any book recommendations for this ticket, please include title and authors name.</p>
                                <input type="text" id="bookUpload" name="bookreq" maxlength="100" placeholder="TITLE - AUTHOR" />
                            </div>
                            <button>Submit Ticket</button>
                            <div id="progressBarOuter">
                                <div id="progressBar"></div>
                            </div>  
                        </form>
                    </div>
                    <div id="uploadRight">
                    <div id="uploadDisclaimer">
                            <p>
                                Please be aware that all submitted tickets will be moderated and may be rejected or subject to small 
                                changes based on subject sensitivities, image content or editorial discretion. Any opinions expressed 
                                in these tickets are the author's own and not those of the Death Positive Libraries. 
                            </p>
                        </div>
                    </div>

                </div>
                <Usertickets />
            </div>
        );
    }

export default CreateTicket;