import React from "react";

import CoupledChoices from "../../components/CoupledChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import materials from "../../images/materials.jpg";
import decomposition from "../../images/decomposition.jpg";
import PATHS from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";

const BioScreen = () => {
  return (
    <CoupledChoices
      labelUp={
        <BoldLabel>
          biodegradable
          <br />
          materials
        </BoldLabel>
      }
      labelDown={
        <BoldLabel>
          facilitating
          <br />
          decomposition
        </BoldLabel>
      }
      imageUp={materials}
      imageDown={decomposition}
      pathUp={PATHS.Tickets + "42"}
      pathDown={PATHS.Tickets + "43"}
      questionLabel={QUESTION_LABELS.LAST}
      sound="BurialEnclosure"
    />
  );
};

export default BioScreen;
