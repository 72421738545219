import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import scripted from "../../images/scripted.jpg";
import lineC from "../../images/lines/lineC2pt.png";
import PATHS from "../../utils/constants";

const ScriptedScreen = () => {
  return (
    <WiredChoices
      labelUp={<BoldLabel>scripted messages</BoldLabel>}
      labelDown={<BoldLabel>unscripted moments</BoldLabel>}
      pathUp={PATHS.Tickets + "29"}
      pathDown={PATHS.Tickets + "30"}
      image={scripted}
      line={lineC}
      questionLabel={QUESTION_LABELS.LAST}
      labelNumlines={1}
      sound="Yes"
    />
  );
};

export default ScriptedScreen;
