import React from "react";
import { useMediaQuery } from "react-responsive";

import * as device from "../../src/utils/device";
import * as manager from "../../src/utils/manager";
import * as Constants from "../utils/constants";

import TicketType1 from "../components/TicketType1";
import TicketType2 from "../components/TicketType2";
import TicketType3 from "../components/TicketType3";
import TicketType4 from "../components/TicketType4";
import TicketType5 from "../components/TicketType5";
import TicketType6 from "../components/TicketType6";
import TicketType7 from "../components/TicketType7";

import TicketTypePortrait1 from "../components/TicketTypePortrait1";
import TicketTypePortrait2 from "../components/TicketTypePortrait2";
import TicketTypePortrait3 from "../components/TicketTypePortrait3";
import TicketTypePortrait4 from "../components/TicketTypePortrait4";
import TicketTypePortrait5 from "../components/TicketTypePortrait5";
import PATHS from "../utils/constants";

import TicketsData from "./TicketsData";

const TicketScreen = ({ match, history }) => {
  const id = match ? parseInt(match.params.id) : 1;
  const data = TicketsData[id - 1];

  window.localStorage.setItem("ticket", id);

  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const restart = () => {
    history.push(PATHS.Conventional);
  };

  const goNext = () => {
    history.push(`${PATHS.Books}${id}`);
  };

  const onAllTicketsClick = () => {
    history.push(PATHS.AllTickets);
  };

  return showPortrait && versionToShow === 3 && data.pmtype === 3 ? (
    <TicketTypePortrait3
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      imageTitle={data.imageTitle}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      titleLevel={data.titleLevel}
      gapFraction={data.gapFraction}
      pmtop={data.pmtop}
      psTitle={data.psTitle}
      psImageW={data.psImageW}
      psParPosition={data.psParPosition}
      psImagePosition={data.psImagePosition}
      psTitlePosition={data.psTitlePosition}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
      //onContinue={() => goNext(id)}
    />
  ) : showPortrait && versionToShow === 3 && data.pmtype === 4 ? (
    <TicketTypePortrait4
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      imageTitle={data.imageTitle}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : showPortrait && versionToShow === 3 && data.pmtype === 5 ? (
    <TicketTypePortrait5
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      imageTitle={data.imageTitle}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      pmImageW={data.pmImageW}
      imagePosition={data.pmImagePosition}
      titlePosition={data.pmTitlePosition}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : showPortrait && data.pstype === 1 ? (
    <TicketTypePortrait1
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      imageTitle={data.imageTitle}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      titleLevel={data.titleLevel}
      gapFraction={data.gapFraction}
      psTitle={data.psTitle}
      psImageW={data.psImageW}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : showPortrait && data.pstype === 2 ? (
    <TicketTypePortrait2
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      imageTitle={data.imageTitle}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      titleLevel={data.titleLevel}
      gapFraction={data.gapFraction}
      psTitle={data.psTitle}
      psImageW={data.psImageW}
      psParPosition={data.psParPosition}
      psImagePosition={data.psImagePosition}
      psTitlePosition={data.psTitlePosition}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : // LANDSCAPE VERSIONS FROM NOW ON
  versionToShow <= 2 && data.typev2 === 1 ? (
    <TicketType1
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      titlev2={data.titlev2}
      imageTitle={data.imageTitle}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      titleLevel={data.titleLevelv2}
      gapFraction={data.gapFractionv2}
      textv2={data.textv2}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : versionToShow <= 2 && data.typev2 === 7 ? (
    <TicketType7
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      titlev2={data.titlev2}
      imageTitle={data.imageTitle}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      textv2={data.textv2}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : versionToShow <= 2 && data.typev2 === 5 ? (
    <TicketType5
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      textv2={data.textv2}
      title={data.title}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      imageTop={data.imageTop}
      imageLeft={data.imageLeft}
      titleLevel={data.titleLevel}
      gap={data.gap}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : data.type === 1 ? (
    <TicketType1
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      imageTitle={data.imageTitle}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      titleLevel={data.titleLevel}
      gapFraction={data.gapFraction}
      titlev2={data.titlev2}
      textv2={data.textv2}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : data.type === 2 ? (
    <TicketType2
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      titleLevel={data.titleLevel}
      section={data.section}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : data.type === 3 ? (
    <TicketType3
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      left={data.left}
      titleLevel={data.titleLevel}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : data.type === 4 ? (
    <TicketType4
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : data.type === 5 ? (
    <TicketType5
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      imageTop={data.imageTop}
      imageLeft={data.imageLeft}
      titleLevel={data.titleLevel}
      gap={data.gap}
      titlev2={data.titlev2}
      textv2={data.textv2}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : data.type === 6 ? (
    <TicketType6
      id={id}
      avail={data.avail}
      cost={data.cost}
      text={data.text}
      title={data.title}
      image={data.image}
      imageW={data.imageW}
      imageRatio={data.ratio}
      imageSide={data.imageSide}
      onRestart={() => restart()}
      onContinue={() => goNext(id)}
      onAllTickets={() => onAllTicketsClick()}
    />
  ) : null;
};

export default TicketScreen;
