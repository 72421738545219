import React from "react";
import { useMediaQuery } from "react-responsive";

import WiredChoices from "../components/WiredChoices";
import QuestionsFrame from "../components/QuestionsFrame";
import { QUESTION_LABELS } from "../utils/constants";
import mountains from "../images/mountains.jpg";
import lineD from "../images/lines/lineD2pt.png";
import PATHS from "../utils/constants";
import * as manager from "../utils/manager";
import Title from "../typocomps/Title";
import SmallText from "../typocomps/SmallText";
import Image from "../components/Image";
import Sounds from "../components/Sounds";

const VoidScreen = () => {
  const { showPortrait } = manager.getVersionToShow(useMediaQuery);

  const landscapeComp = (
    <div style={{ height: "100%" }}>
      <div
        style={{
          paddingTop: "75px",
          paddingLeft: "75px",
          //alignItems: "flex-end",
          display: "inline-block",
        }}
      >
        <Title>
          We don't die so much <br />
          as we return to the void.
        </Title>
        <SmallText
          style={{
            textAlign: "right",
          }}
        >
          "R.F. Kuang, The Poppy War"
        </SmallText>
      </div>
      <div style={{ height: "70%" }}>
        <WiredChoices
          labelUp="space"
          labelDown="virtual reality"
          pathUp={PATHS.Tickets + "25"}
          pathDown={PATHS.Tickets + "26"}
          image={mountains}
          line={lineD}
          subScreen={true}
          labelNumlines={1}
          sound="WeDont"
        />
      </div>
    </div>
  );

  /*  const portraitComp = (
    <div
      className="centered-content"
      style={{ height: "100%", position: "relative" }}
    >
      <p
        className="title-small"
        style={{
          position: "absolute",
          margin: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
          zIndex: 3,
          color: "white",
          lineHeight: "1.5rem",
        }}
      >
        We don't <br /> die so much <br />
        as we return <br /> to the void.
      </p>

      <div style={{ height: "70%" }}>
        <WiredChoices
          labelUp="space"
          labelDown="virtual reality"
          pathUp={PATHS.Tickets + "25"}
          pathDown={PATHS.Tickets + "26"}
          image={mountains}
          line={lineD}
          subScreen={true}
        />
      </div>
    </div>
  );*/

  const portraitComp = (
    <WiredChoices
      labelUp="space"
      labelDown="virtual reality"
      pathUp={PATHS.Tickets + "25"}
      pathDown={PATHS.Tickets + "26"}
      image={mountains}
      line={lineD}
      sound="WeDont"
      //subScreen={true}
    />
  );

  return (
    <QuestionsFrame uplabel={QUESTION_LABELS.LAST}>
      {showPortrait ? portraitComp : landscapeComp}
    </QuestionsFrame>
  );
};

export default VoidScreen;
