import React from "react";

import ChooseScreen from "../../components/ChooseScreen";

import funerary from "../../images/funerary.jpg";
import circle from "../../images/circle.jpg";
import records from "../../images/records.jpg";
import PATHS from "../../utils/constants";

const FuneraryScreen = () => {
  return (
    <ChooseScreen
      optionsData={{
        option1: {
          label: "funerary activities",
          image: funerary,
          path: PATHS.Elaborate,
        },

        option2: {
          label: "community",
          image: circle,
          path: PATHS.Sharing,
        },
        option3: {
          label: "records and belongings",
          image: records,
          path: PATHS.Material,
        },
      }}
      rotateImagesForPortrait={true}
      rotateImagesForMobileLandscape={true}
      imageTypeSmall={false}
      sound="ModernPractices2"
    />
  );
};

export default FuneraryScreen;
