import React from "react";

import ScreenTypeA from "../../components/ScreenTypeA";
import conventional from "../../images/conventional.jpg";
import unusual from "../../images/unusual.jpg";
import PATHS from "../../utils/constants";
import MixedParagraphs from "../../components/MixedParagraphs";


const ConventionalScreen = () => {

  //reset
  window.localStorage.setItem("isFirstTicket", 1);
  console.log("first ticket is now: " + (window.localStorage.getItem("isFirstTicket")));

  return (
    <ScreenTypeA
      mainComp={MixedParagraphs.CONVENTIONAL}
      imageUp={conventional}
      imageDown={unusual}
      textUp="traditional"
      textDown="unusual"
      pathUp={PATHS.Traditions}
      pathDown={PATHS.Transform}
      noLeft={true}
      sound={"AreYouInterested"}
    />
  );
};

export default ConventionalScreen;
