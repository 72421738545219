import React from "react";

import ChooseScreen from "../../components/ChooseScreen";
import { QUESTION_LABELS } from "../../utils/constants";

import energy from "../../images/energy.jpg";
import water from "../../images/water.jpg";
import compost from "../../images/compost.jpg";
import PATHS from "../../utils/constants";

const EnergyScreen = () => {
  return (
    <ChooseScreen
      optionsData={{
        option1: {
          label: "energy",
          image: energy,
          path: PATHS.Tickets + "44",
        },
        option2: {
          label: "water",
          image: water,
          path: PATHS.Tickets + "45",
        },
        option3: {
          label: "compost",
          image: compost,
          path: PATHS.Tickets + "46",
        },
      }}
      questionLabel={QUESTION_LABELS.LAST}
      rotateImagesForPortrait={false}
      imageTypeSmall={true}
      sound="Other"
    />
  );
};

export default EnergyScreen;
