import React from "react";
import { useMediaQuery } from "react-responsive";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";

import * as device from "../../src/utils/device";
import * as manager from "../../src/utils/manager";
import * as Constants from "../utils/constants";

import UserTicketType1 from "../components/userTicketType1";
import TicketTypePortrait1 from "../components/TicketTypePortrait1";
import PATHS from "../utils/constants";
import TicketsData from "./TicketsData";

const TicketScreen = ({ match, history }) => {
    const id = match ? parseInt(match.params.id) : 1;
    const data = TicketsData[id - 1];


    const { showPortrait, versionToShow } =
        manager.getVersionToShow(useMediaQuery);

    const restart = () => {
        history.push(PATHS.CreateTicket);
    };

    const goNext = () => {
        history.push(`${PATHS.Books}${id}`);
    };

    const onAllTicketsClick = () => {
        history.push(PATHS.AllTickets);
    };

    let ticketTitle = "unassigned Title";
    let ticketText = "unassigned Text";
    let ticketImg = "unassigned img";

    return (
        <UserTicketType1
            id={id}
            text={ticketText}
            title={ticketTitle}
            onRestart={() => restart()}
            onContinue={() => goNext(id)}
            onAllTickets={() => onAllTicketsClick()}
        />
    )
  
};

export default TicketScreen;
