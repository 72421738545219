import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import sea from "../../images/sea.jpg";
import line from "../../images/lines/lineD2pt.png";
import PATHS from "../../utils/constants";

const SeaScreen = () => {
  return (
    <WiredChoices
      labelUp={<BoldLabel>sea</BoldLabel>}
      labelDown={<BoldLabel>land</BoldLabel>}
      pathUp={PATHS.Tickets + "36"}
      pathDown={PATHS.Tickets + "37"}
      image={sea}
      line={line}
      questionLabel={QUESTION_LABELS.LAST}
      labelNumlines={1}
      sound="Nature"
    />
  );
};

export default SeaScreen;
