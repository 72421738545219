import React from "react";

import CoupledChoices from "../../components/CoupledChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import create1 from "../../images/create1.jpg";
import create2 from "../../images/create2.jpg";
import PATHS from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";

const CreatingScreen = () => {
  return (
    <CoupledChoices
      labelUp={<BoldLabel>re-creating</BoldLabel>}
      labelDown={<BoldLabel>deleting</BoldLabel>}
      imageUp={create1}
      imageDown={create2}
      pathUp={PATHS.Tickets + "49"}
      pathDown={PATHS.Tickets + "50"}
      questionLabel={QUESTION_LABELS.LAST}
      sound="EmbracingDeath"
    />
  );
};

export default CreatingScreen;
