import React from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, deleteDoc, onSnapshot, query, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import ListUsertickets from "./ListUsertickets";

const UserticketsApprove = (signedIn) => {

    let ticketObjs = [];
    let itemList = [];



    const firebaseConfig = {
        apiKey: "AIzaSyCdReuu98P1G3H6QBlEaBAEmHPfBwS0ZM8",
        authDomain: "loveafterdeath-5ba5c.firebaseapp.com",
        databaseURL: "https://loveafterdeath-5ba5c-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "loveafterdeath-5ba5c",
        storageBucket: "loveafterdeath-5ba5c.appspot.com",
        messagingSenderId: "828316428743",
        appId: "1:828316428743:web:5fa03e265b70be981511e2",
        measurementId: "G-WLL87W5PYQ"
    };

    const firebaseApp = initializeApp(firebaseConfig);

    const db = getFirestore();

    const FetchData = async () => {
        const docRef = doc(db, "userTickets", "test");
        const docSnap = await getDoc(docRef);

        const q = query(collection(db, "userTickets"), where("reveiwed", "==", false));
        
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          ticketObjs.push(doc.data());
        });
        //console.log(ticketObjs); //array of objects for all records in userTickets database
        await mapData();
    }

    FetchData();

    const mapData = async () => {
        let items = ticketObjs;
        items.forEach((item, index)=>{
            itemList.push( <li key={index}>{item.title}</li>);
            document.getElementById("UserTicketsOutput").innerHTML += (
                "<script>fucntion Approve(index){console.log('approve: ' + index);</script>" +
                "<div class='userticketCard' id=userticketCard" + index + ">" + 
                    "<div id=userticketCardTitle> " + item.title + "</div>" +
                    "<div id=userticketCardImgOuter>" +
                        "<div id=userticketCardAspectDummy></div>" + 
                        "<img id=userticketCardImg src='" + item.imgURL + "'></img>" +
                    "</div>" +
                    "<div class=cardTags id=cardTags" + index + "><div class=carTag1 id=carTag1" + index + ">" + item.tag1 +"</div><div class=carTag2  id=carTag2" + index + ">" + item.tag2 +"</div></div>" +
                    "<div id=userticketCardText> " + item.text + "</div>" +
                    "<div id=userticketCardText>Book Recommendation: " + item.BookRecommendation + "</div>" +
                    "<button id=ApproveButton" + item.index + "> Approve </button>" +
                    "<button id=DeleteButton" + item.index + "> Delete </button>" +
                "</div>"
            );

            if(item.tag1 == null ){document.getElementById("carTag1" + index).style.display = "none";}
            if(item.tag2 == null ){document.getElementById("carTag2" + index).style.display = "none";}
            if(item.tag2 == null && item.tag1 == null ){document.getElementById("cardTags" + index).style.display = "none";}
        })
        items.forEach((item, index)=>{
            document.getElementById("ApproveButton" + item.index).addEventListener("click", ApproveCard);
            document.getElementById("DeleteButton" + item.index).addEventListener("click", DeleteCard);
        });

    }

    function ApproveCard(e) {
        var pin = prompt("Please enter pin");
        if (pin != 1369) {
            alert("incorrect Pin");
            return null;
        }
        let DatabaseIndex = parseInt(e.srcElement.id.replace('ApproveButton',''));

        const Approve = async (index) => {
            const q = query(collection(db, "userTickets"), where("index", "==", index));
            
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              const title = doc.id
              //update record 
              updateDB(doc.id, doc.data());
            });
        }

        const updateDB = async (title, data)  => {
            let thisTag1 = data.tag1;
            let thisTag2 = data.tag2;
            if(data.tag1 == null){thisTag1 = null;}else{thisTag1 = data.tag1;}
            if(data.tag2 == null){thisTag2 = null;}else{thisTag2 = data.tag2;}
            
            await setDoc(doc(db, "userTickets", title), {
                index: data.index,
                title: data.title,
                text: data.text,
                imageName: data.imageName,
                imgURL: data.imgURL,
                //imgURL: document.getElementById('TicketImagePreview').src,
                uploadDate: data.uploadDate,
                reveiwed: true,
                tag1: thisTag1,
                tag2: thisTag2,
                BookRecommendation: data.BookRecommendation,
            });
            alert("ticket approved");
            window.location.reload();
        }

        Approve(DatabaseIndex);    
    }

    function DeleteCard(e) {
        var pin = prompt("Please enter pin");
        if (pin != 1369) {
            alert("incorrect Pin");
            return null;
        }
        if(signedIn == false){
            alert("sign in to reviewTickets");
            return;
        }
        console.log("DeleteCard");
        let DatabaseIndex = parseInt(e.srcElement.id.replace('DeleteButton',''));

        const Delete = async (index) => {
            console.log("DeleteFunc");
            console.log("index" + index);
            const q = query(collection(db, "userTickets"), where("index", "==", index));
            
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              const title = doc.id
              //update record 
              deleteDB(doc.id);
            });
        }

        const deleteDB = async (title)  => {
            await deleteDoc(doc(db, "userTickets", title));
            alert("ticket Deleted");
            window.location.reload();
        }

        Delete(DatabaseIndex);    
    }


    return(
        <div> 
            <ul id="UserTicketsOutput"></ul>
        </div>
    );
    
}

export default UserticketsApprove;