export const DEVICE_TYPE = {
  XSMALL: "xsmall",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  XLARGE: "xlarge",
};

export const deviceType = (hook) => {
  // small mobile phones, like iPhone 5s and below
  const isXSmall = hook({
    query: "(max-device-width: 320px)",
  });

  // mobile devices (not sure which one's this big)
  const isSmall = hook({
    query: "(min-device-width: 321px) and (max-device-width: 599px)",
  });

  // ipad, ipad pro, ipad mini, etc
  const isMedium = hook({
    query: "(min-device-width: 600px) and (max-device-width: 768px)",
  });

  // laptops and desktops
  const isLarge = hook({
    query: "(min-device-width: 769px) and (max-device-width: 1999px)",
  });

  // large screens
  const isXLarge = hook({
    query: "(min-device-width: 2000px)",
  });

  const isMobile = isXSmall || isSmall;
  const isTablet = isMedium;

  const isPortrait = hook({ query: "(orientation: portrait)" });

  return {
    type: isXSmall
      ? DEVICE_TYPE.XSMALL
      : isSmall
      ? DEVICE_TYPE.SMALL
      : isMedium
      ? DEVICE_TYPE.MEDIUM
      : isLarge
      ? DEVICE_TYPE.LARGE
      : DEVICE_TYPE.XLARGE,
    isMobile: isMobile,
    isPortrait: isPortrait && (isMobile || isTablet),
  };
};
