import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import QuestionsFrame from "./QuestionsFrame";
import BoldLabel from "../typocomps/BoldLabel";
import * as manager from "../utils/manager";
import lineA from "../images/lines/lineA2pt.png";
import lineF from "../images/lines/lineF2pt.png";
import { version } from "react-dom";
import Image from "../components/Image";
import Sounds from "../components/Sounds";
import ScreenAnalytics from "./ScreenAnalytics";

import { motion } from "framer-motion"

const ScreenTypeA = ({
  mainComp,
  imageUp,
  imageDown,
  textUp,
  textDown,
  pathUp,
  pathDown,
  questionLabel,
  useLineF,
  noLeft,
  sound,
  screenAnalytics,
}) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 80
      : versionToShow === 2
      ? 100
      : versionToShow === 3
      ? 120
      : versionToShow === 4
      ? 120
      : 120;

  const lineW =
    versionToShow === 1
      ? 200
      : versionToShow === 2
      ? 200
      : versionToShow === 3
      ? 380
      : versionToShow === 4
      ? 380
      : 380;

  const FlineW =
    versionToShow === 1
      ? 200
      : versionToShow === 2
      ? 220
      : versionToShow === 3
      ? 480
      : versionToShow === 4
      ? 480
      : 480;

  const imageRatio = 0.54; // 280, 152
  const lineRatio = 0.66; // 400, 265
  const lineFRatio = 0.59; // 4172, 2442

  const imageH = imageW * imageRatio;
  const lineH = lineW * lineRatio;
  const FlineH = FlineW * lineFRatio;

  /************************************* landscape margins *************************/
  const lineLeftMargin =
    versionToShow === 1
      ? 5
      : versionToShow === 2
      ? 5
      : versionToShow === 3
      ? 5
      : versionToShow === 4
      ? 5
      : 5;

  const FlineLeftMargin =
    versionToShow === 1
      ? -60
      : versionToShow === 2
      ? -60
      : versionToShow === 3
      ? -60
      : versionToShow === 4
      ? -60
      : -60;

  const lineTopMargin =
    versionToShow === 1
      ? 30
      : versionToShow === 2
      ? 40
      : versionToShow === 3
      ? 50
      : versionToShow === 4
      ? 50
      : 0;

  const FlineTopMargin =
    versionToShow === 1
      ? 0
      : versionToShow === 2
      ? 0
      : versionToShow === 3
      ? 0
      : versionToShow === 4
      ? 0
      : 0;

  const upimageTopMargin =
    versionToShow === 1
      ? 40
      : versionToShow === 2
      ? 40
      : versionToShow === 3
      ? 70
      : versionToShow === 4
      ? 70
      : 70;

  const FupimageTopMargin =
    versionToShow === 1
      ? 40
      : versionToShow === 2
      ? 40
      : versionToShow === 3
      ? 80
      : versionToShow === 4
      ? 80
      : 80;

  const upimageRightMargin =
    versionToShow === 1
      ? 5
      : versionToShow === 2
      ? 5
      : versionToShow === 3
      ? 10
      : versionToShow === 4
      ? 10
      : 0;

  const FupimageRightMargin =
    versionToShow === 1
      ? 40
      : versionToShow === 2
      ? 60
      : versionToShow === 3
      ? 60
      : versionToShow === 4
      ? 60
      : 60;

  const downimageRightMargin =
    versionToShow === 1
      ? 100
      : versionToShow === 2
      ? 120
      : versionToShow === 3
      ? 120
      : versionToShow === 4
      ? 120
      : 120;

  const FdownimageRightMargin =
    versionToShow === 1
      ? 120
      : versionToShow === 2
      ? 140
      : versionToShow === 3
      ? 280
      : versionToShow === 4
      ? 280
      : 0;

  const downimageTopMargin =
    versionToShow === 1
      ? 0
      : versionToShow === 2
      ? 0
      : versionToShow === 3
      ? -20
      : versionToShow === 4
      ? -20
      : 0;

  const FdownimageTopMargin =
    versionToShow === 1
      ? -20
      : versionToShow === 2
      ? -15
      : versionToShow === 3
      ? -10
      : versionToShow === 4
      ? -10
      : 0;

  const H =
    versionToShow === 1
      ? 200
      : versionToShow === 2
      ? 220
      : versionToShow === 3
      ? 370
      : versionToShow === 4
      ? 370
      : 370;

  const landscapeComp = (
    <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
    <Sounds sounds={sound}/>
    <ScreenAnalytics screen={screenAnalytics}/>
    <div className="row" style={{ height: `${H}px`, transform: "scale(1.15)" }}>
      {mainComp(versionToShow)}
      <img
        alt=""
        src={useLineF ? lineF : lineA}
        style={{
          width: useLineF ? `${FlineW}px` : `${lineW}px`,
          height: useLineF ? `${FlineH}px` : `${lineH}px`,
          objectFit: "contain",
          marginLeft: useLineF ? `${FlineLeftMargin}px` : `${lineLeftMargin}px`,
          marginTop: useLineF ? `${FlineTopMargin}px` : `${lineTopMargin}px`,
          //marginTop: useLineF ? "-90px" : "0px",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          //          justifyContent: "flex-end",
        }}
      >
        <Link
          to={pathUp}
          className="no-decoration"
          style={{
            //marginTop: "0px",
            position: "relative",
            right: useLineF
              ? `${FupimageRightMargin}px`
              : `${upimageRightMargin}px`,
            top: useLineF ? `${FupimageTopMargin}px` : `${upimageTopMargin}px`,
          }}
        >
          <img
            alt=""
            src={imageUp}
            style={{ width: `${imageW}px`, height: `${imageH}px` }}
          />
          <BoldLabel>{textUp}</BoldLabel>
        </Link>
        <Link
          to={pathDown}
          className="no-decoration"
          style={{
            top: useLineF
              ? `${FdownimageTopMargin}px`
              : `${downimageTopMargin}px`,
            position: "relative",
            right: useLineF
              ? `${FdownimageRightMargin}px`
              : `${downimageRightMargin}px`,
          }}
        >
          <img
            alt=""
            src={imageDown}
            style={{ width: `${imageW}px`, height: `${imageH}px` }}
          />
          <BoldLabel>{textDown}</BoldLabel>
        </Link>
      </div>
    </div>
    </motion.div>
  );

  /**************************************************** portrait **************************************************/

  const plineW =
    versionToShow === 1
      ? 200
      : versionToShow === 2
      ? 200
      : versionToShow === 3
      ? 380
      : versionToShow === 4
      ? 380
      : 380;

  const pimageW =
    versionToShow === 1
      ? 80
      : versionToShow === 2
      ? 80
      : versionToShow === 3
      ? 120
      : versionToShow === 4
      ? 120
      : 120;

  const plineH = plineW * lineRatio;
  const pimageH = pimageW * imageRatio;

  const downImageLeft =
    versionToShow === 1 ? 10 : versionToShow === 2 ? 25 : 35;

  const downImageBottom =
    versionToShow === 1 ? 30 : versionToShow === 2 ? 30 : 50;

  const upImageLeft =
    versionToShow === 1
      ? 40
      : versionToShow === 2
      ? 50
      : versionToShow === 3
      ? 110
      : versionToShow === 4
      ? 100
      : 100;

  const portraitComp = (
    <div>
      <Sounds sounds={sound}/>
      <div className="centered-content">{mainComp(versionToShow)}</div>
      <div className="centered-content" style={{ height: `${plineW}px` }}>
        <img
          alt=""
          src={lineA}
          style={{
            width: `${plineW}px`,
            height: `${plineH}px`,
            objectFit: "contain",
            transform: "rotate(90deg)",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Link
          to={pathDown}
          className="no-decoration"
          style={{
            position: "relative",
            left: `${downImageLeft}px`,
            bottom: `${downImageBottom}px`,
          }}
        >
          <img
            alt=""
            src={imageDown}
            style={{ width: `${pimageW}px`, height: `${pimageH}px` }}
          />
          <BoldLabel>{textDown}</BoldLabel>
        </Link>
        <Link
          to={pathUp}
          className="no-decoration"
          style={{
            position: "relative",
            left: `${upImageLeft}px`,
            bottom: "5px",
          }}
        >
          <img
            alt=""
            src={imageUp}
            style={{ width: `${pimageW}px`, height: `${pimageH}px` }}
          />
          <BoldLabel>{textUp}</BoldLabel>
        </Link>
      </div>
    </div>
  );

  return (
    <QuestionsFrame upLabel={questionLabel} noLeft={noLeft}>
      <div className="centered-content">
        {showPortrait ? portraitComp : landscapeComp}
      </div>
    </QuestionsFrame>
  );
};

export default ScreenTypeA;
