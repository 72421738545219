import React from "react";

import ScreenTypeB from "../../components/ScreenTypeB";
import { QUESTION_LABELS } from "../../utils/constants";
import afterlifeU from "../../images/afterlife-u.png";
import afterlifeB from "../../images/afterlife-b.png";
import PATHS from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";

const WisdomScreen = () => {
  return (
    <ScreenTypeB
      text="What do you wish to take with you in the"
      word="afterlife?"
      textUp={<BoldLabel>wisdom</BoldLabel>}
      textDown={<BoldLabel>posessions</BoldLabel>}
      pathUp={PATHS.Tickets + "3"}
      pathDown={PATHS.Tickets + "4"}
      questionLabel={QUESTION_LABELS.LAST}
      imageU={afterlifeU}
      imageB={afterlifeB}
      imageURatio={1.1}
      imageBRatio={1.18}
      sound="WhatDoYouWish"
    />
  );
};

export default WisdomScreen;
