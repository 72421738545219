import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import TicketContent from "./TicketContent";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketType5 = ({
  id,
  avail,
  cost,
  title,
  text,
  textv2,
  image,
  imageRatio,
  titleLevel,
  imageLeft,
  imageTop,
  gap,
  titlev2,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.LANDSCAPE[versionToShow - 1][id];

  const tmpTitleLevel = versionToShow <= 2 ? 1 : titleLevel;
  const tmpImageLeft = imageLeft;
  const tmpImageTop = versionToShow <= 2 ? 1 : imageTop;
  const tmpGap = versionToShow <= 2 ? 0 : gap;
  const tmpTitle = versionToShow <= 2 && titlev2 ? titlev2 : title;
  const tmpText = versionToShow <= 2 && textv2 ? textv2 : text;

  const SECTIONS =
    versionToShow <= 2
      ? ["0%", "40%", "60%", "0%"]
      : ["18%", "25%", "39%", "18%"];

  const tmpImageW = versionToShow <= 2 && tmpImageLeft === 0 ? 300 : imageW;

  const IMAGE_W = tmpImageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      text={text}
      image={image}
      id={id}
      title={title}
    >
      <TicketContent>
        <div
          style={{
            display: tmpImageTop === 3 ? "flex" : null,
            flexDirection: tmpImageTop === 3 ? "column" : null,
            justifyContent: tmpImageTop === 3 ? "flex-end" : null,
            position: "relative",
            left: tmpImageLeft === 0 ? "0px" : SECTIONS[0],
            top: tmpImageTop === 2 ? "50%" : null,
            width: SECTIONS[1],
          }}
        >
          <img
            alt=""
            src={image}
            style={{ height: `${IMAGE_H}px`, width: `${IMAGE_W}px` }}
          />
        </div>
        <div
          style={{
            position: "relative",
            left: SECTIONS[0],
            width: SECTIONS[2],
          }}
        >
          <div
            style={{
              display: tmpTitleLevel === 2 ? "flex" : null,
              flexDirection: tmpTitleLevel === 2 ? "column" : null,
              justifyContent: tmpTitleLevel === 2 ? "flex-end" : null,
              position: "relative",
              top: tmpTitleLevel === 3 ? "50%" : "0px",
              height: tmpTitleLevel === 2 ? "50%" : null,
            }}
          >
            {tmpTitle}
          </div>
          <div
            style={{
              position:
                tmpGap === 1
                  ? "absolute"
                  : tmpImageTop === 2
                  ? "relative"
                  : null,
              top: tmpGap === 1 ? "50%" : tmpImageTop === 2 ? "50%" : null,
              marginTop: tmpGap === 0 ? "5%" : tmpGap === 2 ? "15%" : null,
            }}
          >
            <div>{tmpText}</div>
          </div>
        </div>
      </TicketContent>
    </Ticket>
  );
};

export default TicketType5;
