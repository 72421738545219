import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import QuestionsFrame from "./QuestionsFrame";
import * as manager from "../utils/manager";
import Image from "../components/Image";
import Sounds from "../components/Sounds";
import ScreenAnalytics from "./ScreenAnalytics";

import { motion } from "framer-motion"

const WiredChoices = ({
  labelUp,
  labelDown,
  pathUp,
  pathDown,
  image,
  line,
  questionLabel,
  labelNumlines,
  subScreen,
  sound
}) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);
  //
  const imageW =
    versionToShow === 1
      ? 100
      : versionToShow === 2
      ? 141
      : versionToShow === 3
      ? 171
      : versionToShow === 4
      ? 171
      : 171;

  const lineW =
    versionToShow === 1
      ? 240
      : versionToShow === 2
      ? 340
      : versionToShow === 3
      ? 400
      : versionToShow === 4
      ? 400
      : 400;

  const lineRatio = 0.36; // 2792, 1000
  const imageRatio = 1; // 280, 280

  const imageH = imageW * imageRatio;
  const lineH = lineW * lineRatio;

  const marginTwoLines =
    versionToShow === 1
      ? -15
      : versionToShow === 2
      ? -15
      : versionToShow === 3
      ? -25
      : versionToShow === 4
      ? -25
      : -25;

  const marginThreeLines =
    versionToShow === 1
      ? -35
      : versionToShow === 2
      ? -35
      : versionToShow === 3
      ? -55
      : versionToShow === 4
      ? -55
      : -55;

  //

  const landscapeComp = (
    <div className="centered-content" style={{ position: "relative" }}>
      <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
      <Sounds sounds={sound} />
      <Image
        alt=""
        src={image}
        style={{
          position: "absolute",
          margin: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
          height: `${imageH}px`,
          width: `${imageW}px`,
          zIndex: 2,
        }}
      />

      <div
        className="row"
        style={{
          justifyContent: "center",
          zIndex: 3,
        }}
      >
        <Link
          to={pathUp}
          className="no-decoration"
          style={{
            width: "200px",
            paddingRight: "5px",
            marginTop:
              labelNumlines === 1
                ? "-15px"
                : labelNumlines === 2
                ? "-15px"
                : "-15px",
            textAlign: "right",
          }}
        >
          {labelUp}
        </Link>
        <div>
          <img
            alt=""
            src={line}
            style={{
              height: `${lineH}px`,
              width: `${lineW}px`,
              objectFit: "contain",
              height: "auto",
            }}
          />
        </div>
        <Link
          to={pathDown}
          className="no-decoration"
          style={{
            width: "200px",
            alignSelf: "flex-end",
            paddingLeft: "5px",
            marginBottom:
              labelNumlines === 1
                ? "0px"
                : labelNumlines === 2
                ? `${marginTwoLines}px`
                : `${marginThreeLines}px`,
          }}
        >
          {labelDown}
        </Link>
      </div>
      </motion.div>
    </div>
  );

  /**************************************** Portrait **********************************/

  const offsetMobile =
    versionToShow === 1 ? "15px" : versionToShow === 2 ? "15px" : "15px";

  const pimageW = versionToShow === 1 ? 98 : versionToShow === 2 ? 118 : 205;
  const plineW = versionToShow === 1 ? 210 : versionToShow === 2 ? 250 : 450;
  const pimageH = pimageW * imageRatio;

  const portraitComp = (
    <div className="centered-content" style={{ position: "relative" }}>
      <Sounds sounds={sound} />
      <Image
        alt=""
        src={image}
        style={{
          position: "absolute",
          margin: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
          height: `${pimageH}px`,
          width: `${pimageW}px`,
          zIndex: 2,
        }}
      />
      <div
        className={"column"}
        style={{
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: subScreen ? 1 : 3,
        }}
      >
        <Link
          to={pathUp}
          className="no-decoration"
          style={{
            marginBottom: offsetMobile,
          }}
        >
          <div style={{ textAlign: "center" }}>{labelUp}</div>
        </Link>
        <img
          alt=""
          src={line}
          style={{
            width: `${plineW}px`,
            objectFit: "contain",
            height: `${plineW}px`,
            transform: "rotate(74deg)",
          }}
        />
        <Link
          to={pathDown}
          className="no-decoration"
          style={{
            marginLeft: "0px",
            marginBottom: "0px",
            marginTop: offsetMobile,
          }}
        >
          <div style={{ textAlign: "center" }}>{labelDown}</div>
        </Link>
      </div>
    </div>
  );

  const mainComp = (
    <div className="centered-content" style={{ height: "100%" }}>
      {showPortrait ? portraitComp : landscapeComp}
    </div>
  );

  return (
    <div style={{ height: "100%" }}>
      {subScreen ? (
        <div style={{ height: "100%" }}>{mainComp}</div>
      ) : (
        <QuestionsFrame uplabel={questionLabel}>{mainComp}</QuestionsFrame>
      )}
    </div>
  );
};

export default WiredChoices;
