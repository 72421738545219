import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import glasses from "../../images/glasses.jpg";
import lineD from "../../images/lines/lineD2pt.png";
import PATHS from "../../utils/constants";

const MaterialScreen = () => {
  return (
    <WiredChoices
      labelUp={
        <BoldLabel>
          material <br />
          belongings
        </BoldLabel>
      }
      labelDown={
        <BoldLabel>
          records of <br />
          information
        </BoldLabel>
      }
      pathUp={PATHS.Family}
      pathDown={PATHS.Social}
      image={glasses}
      line={lineD}
      questionLabel={QUESTION_LABELS.ONEMORE}
      labelNumlines={2}
      sound="Records"
    />
  );
};

export default MaterialScreen;
