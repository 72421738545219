import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import * as manager from "../../src/utils/manager";
import PATHS from "../utils/constants";
import HugeTitle from "../typocomps/HugeTitle";
import Text from "../typocomps/Text";
import { motion } from "framer-motion";
import StartButton from "../components/StartButton";

import Carnegie from "../images/FooterImages/Carnegie.png";
import Kirklees from "../images/FooterImages/Kirklees.png";
import Newcastle from "../images/FooterImages/Newcastle.png";
import Northumbria from "../images/FooterImages/Northumbria.png";
import Redbridge from "../images/FooterImages/Redbridge.png";
import Wellcome from "../images/FooterImages/Wellcome.png";
import Wolfson from "../images/FooterImages/Wolfson.png";
import BoldText from "../typocomps/BoldText";
import Sounds from "../components/Sounds";



const Screen1 = ({ history }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 90
      : versionToShow === 2
      ? 100
      : versionToShow === 3
      ? 140
      : versionToShow === 4
      ? 140
      : 120;
  const imageH = 0.5 * imageW;

  const enterW =
    versionToShow === 1
      ? 20
      : versionToShow === 2
      ? 20
      : versionToShow === 3
      ? 20
      : versionToShow === 4
      ? 20
      : 20;
  const enterH = 0.5 * enterW;

  const fsize =
    versionToShow === 1
      ? 20
      : versionToShow === 2
      ? 24
      : versionToShow === 3
      ? 30
      : versionToShow === 4
      ? 30
      : 30;

  const radius =
    versionToShow === 1
      ? 5
      : versionToShow === 2
      ? 5
      : versionToShow === 3
      ? 10
      : versionToShow === 4
      ? 10
      : 10;

  const gaps =
    versionToShow === 1
      ? 7
      : versionToShow === 2
      ? 5
      : versionToShow === 3
      ? 10
      : versionToShow === 4
      ? 10
      : 10;

    const goNext = () => {   
        //history.push(PATHS.Conventional);
        history.push(PATHS.Introduction);
        window.localStorage.removeItem("ticket");
    };

    const ToggleSound = () =>{
        document.getElementById("SoundButton").style.display = "none";
        document.getElementById("BeginButton").style.display = "block";
    }
 
    // Landscape
    const landscapeComp = (
        <div className="centered-content">
            <Sounds sounds={"StartDemo2"} />
            <motion.div  initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center"}}> 
                        <div style={{width: "80%"}}>
                            <HugeTitle style={{  fontSize: 140, marginBottom: "5%", lineHeight: 0.8}}>tickets for the afterlife</HugeTitle>
                            <div style={{width: "80%"}}>
                                <Text style={{ marginBottom: "2%", marginTop: "2%" }}><b>Tickets for the Afterlife</b> is an interactive experience developed in collaboration with Death Positive Libraries. Explore a range of choices for what to do with your body, memories and legacies that span the past, present, and future of death and dying.</Text>
                                <Text style={{ fontSize: ".8rem", marginBottom: "2%", marginTop: "2%" }}><b>Please be aware </b>that Tickets for the Afterlife introduces a wide range of death practices that some people may find uncomfortable. We do not recommend the experience for children unless parents are confident that they can sensitively support this exploration. </Text>
                            </div>
                            <div className="StartButton" style={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                                <div id="SoundButton"onClick={() => ToggleSound()} style={{textAlign: "center", marginTop: "1.5rem", background: "#282828", color: "#dcdad4", padding: "1rem", borderRadius: "7px", zIndex: "5"}} > 
                                    <p style={{color: "#dcdad4"}}>Please Ensure your sound is enabled, Click to Continue</p>
                                </div>
                                <div id="BeginButton" onClick={() => goNext()} style={{display: "none", marginTop: "1.5rem", background: "#282828", color: "#dcdad4", padding: "1rem", borderRadius: "7px", zIndex: "5"}} > 
                                    <b>Tap to Start</b>
                                </div>
                            </div>
                            <div className="introLogoRow" id="IntroLogoRow" >
                                <img src={Wellcome} />
                                <img src={Carnegie} />
                                <img src={Wolfson} />
                                <img src={Newcastle} />
                                <img src={Kirklees} />
                                <img src={Redbridge} />
                                <img src={Northumbria} />
                            </div>
                        </div>
                    </div>
            </motion.div>
        </div>
    );

    // MOBILE
    const portraitComp = (
        <div className="centered-content">
        <Sounds sounds={"StartDemo2"} />
        <motion.div  initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} style={{ marginTop: "-5rem"}}>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", padding: "0 1rem"}}> 
                    <div style={{width: "100%"}}>
                        <HugeTitle style={{ marginBottom: "0%", lineHeight: 0.8}}>tickets for the afterlife</HugeTitle>
                        <div style={{ width: "100%"}}>
                            <Text style={{ marginBottom: "2%", marginTop: "2%"}}><b>Tickets for the Afterlife</b> is an interactive experience developed in collaboration with Death Positive Libraries. Explore a range of choices for what to do with your body, memories and legacies that span the past, present, and future of death and dying.</Text>
                            <details  style={{ background: "#282828", textAlign: "center", marginTop: "1.5rem", background: "#282828", color: "#dcdad4", padding: "0.5rem", borderRadius: "7px", color: "#dcdad4"}}>
                                <summary style={{cursor: "pointer"}}>Please be aware:</summary> 
                                <Text style={{ marginBottom: "2%", marginTop: "2%", color: "#dcdad4"}}>Tickets for the Afterlife introduces a wide range of death practices that some people may find uncomfortable. We do not recommend the experience for children unless parents are confident that they can sensitively support this exploration. </Text>
                            </details>
                        </div>
                        <div id="SoundButton"onClick={() => ToggleSound()} style={{textAlign: "center", marginTop: "1.5rem", background: "#282828", color: "#dcdad4", padding: "0.5rem 1rem", borderRadius: "7px", zIndex: "5"}} > 
                                    <p style={{color: "#dcdad4",  width: "100%", marginLeft: "-0.5rem", }}>Please Enable Sound:<br/> Tap to Continue</p>
                                </div>
                                <div id="BeginButton" onClick={() => goNext()} style={{textAlign: "center", display: "none", marginTop: "1.5rem", background: "#282828", color: "#dcdad4", padding: "1rem", borderRadius: "7px", zIndex: "5"}} > 
                                    <b>Tap to Start</b>
                                </div>
                        <div className="introLogoRow" id="IntroLogoRow" style={{flexWrap: "wrap", maxHeight: "20vh"}}>
                            <img src={Wellcome} style={{maxWidth: "calc(100% / 4)", overflowY: "hidden", maxHeight: "10vh"}}/>
                            <img src={Carnegie} style={{maxWidth: "calc(100% / 4)", overflowY: "hidden", maxHeight: "10vh"}}/>
                            <img src={Wolfson} style={{maxWidth: "calc(100% / 4)", overflowY: "hidden", maxHeight: "10vh"}}/>
                            <img src={Newcastle} style={{maxWidth: "calc(100% / 4)", overflowY: "hidden", maxHeight: "10vh"}}/>
                            <img src={Kirklees} style={{maxWidth: "calc(100% / 4)", overflowY: "hidden", maxHeight: "10vh"}}/>
                            <img src={Redbridge} style={{maxWidth: "calc(100% / 4)", overflowY: "hidden", maxHeight: "10vh"}}/>
                            <img src={Northumbria} style={{maxWidth: "calc(100% / 4)", overflowY: "hidden", maxHeight: "10vh"}}/>
                        </div>
                    </div>
                </div>
        </motion.div>
        </div>
    );

    return showPortrait ? portraitComp : landscapeComp;
};

export default Screen1;
