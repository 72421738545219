import React from "react";
import { useMediaQuery } from "react-responsive";

import Book from "./Book";
import Text from "../typocomps/Text";
import HugeTitle from "../typocomps/HugeTitle";
import { books } from "../utils/constants";
import PATHS from "../utils/constants";
import { Link } from "react-router-dom";
import * as manager from "../../src/utils/manager";
import QuestionsFrame from "../components/QuestionsFrame";
import Sounds from "../components/Sounds";

import { motion } from "framer-motion"

const BooksLandscape = ({ ids, onRightButtonClick, onLeftButtonClick }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 85
      : versionToShow === 2
      ? 90
      : versionToShow === 3
      ? 160
      : versionToShow === 4
      ? 160
      : 200;

  const imageH = 1.5 * imageW;

  const nmargin =
    versionToShow === 1
      ? -10
      : versionToShow === 2
      ? -20
      : versionToShow === 3
      ? -30
      : versionToShow === 4
      ? -40
      : -10;

  const marginToBooks =
    versionToShow === 1
      ? 10
      : versionToShow === 2
      ? 5
      : versionToShow === 3
      ? 40
      : versionToShow === 4
      ? 40
      : 10;

  const titleTop =
    versionToShow === 1
      ? 10
      : versionToShow === 2
      ? 5
      : versionToShow === 3
      ? 40
      : versionToShow === 4
      ? 40
      : 10;

  const marginPerc =
    versionToShow === 1
      ? 20
      : versionToShow === 2
      ? 22
      : versionToShow === 3
      ? 17
      : versionToShow === 4
      ? 17
      : 17;

  return (
    <QuestionsFrame
      hasRight={true}
      leftLabel="all tickets"
      rightLabel="continue"
      onRightButtonClick={onRightButtonClick}
      onLeftButtonClick={onLeftButtonClick}
    >
    <Sounds sounds={"Books"} />
    <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
      <div
        className="content-centered"
        style={{
          marginLeft: `${marginPerc}%`,
          marginRight: `${marginPerc}%`,
          paddingTop: "10%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginRight: 20,
          }}
        >
          <HugeTitle
            style={{
              marginTop: `${titleTop}px`,
            }}
          >
            books
          </HugeTitle>
          <Text
            style={{
              marginBottom: `${marginToBooks}px`,
              marginTop: `${nmargin}px`,
            }}
          >
            recommendations based on your answers
          </Text>
        </div>
        <div className="row" style={{ justifyContent: "space-between" }}>
          {ids.map((id) => (
            <Link
              className="no-decoration"
              key={`${id}`}
              to={`${PATHS.Book}${id}`}
            >
              <Book
                title={books[id - 1].title}
                author={books[id - 1].author}
                image={books[id - 1].image}
                imageW={imageW}
                imageH={imageH}
              ></Book>
            </Link>
          ))}
        </div>
      </div>
      </motion.div>
    </QuestionsFrame>
  );
};

export default BooksLandscape;
