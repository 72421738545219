// components/Image/index.tsx

import React, { useCallback, useEffect, useState } from "react";

import placeholder from "../images/placeholder.png";

const Image = ({ src, ...props }) => {
  const [imgSrc, setSrc] = useState(placeholder);

  const onLoad = useCallback(() => {
    setSrc(src);
  }, [src]);

  const onError = useCallback(() => {
    setSrc(placeholder);
  }, [placeholder]);

  useEffect(() => {
    setSrc(src);
    //    window.addEventListener("load", onLoad);
    //   window.addEventListener("error", onError);
    //  return () => {
    //  window.removeEventListener("load", onLoad);
    // window.removeEventListener("error", onError);
    //};
  }, [src, onLoad, onError]);

  return <img {...props} src={imgSrc} alt={""}/>;
  //  return <img {...props} alt={imgSrc} src={imgSrc} />; pari add alt as prop
};

export default Image;
