import React from "react";
import { Link } from "react-router-dom";

import BoldText from "../typocomps/BoldText";
import PATHS from "../utils/constants";
import facebook from "../images/icons/facebook.png";
import twitter from "../images/icons/twitter.png";
import instagram from "../images/icons/instagram.png";

import Text from "../typocomps/Text";
import BoldLabel from "../typocomps/BoldLabel";
import Image from "../components/Image";



const SocialLinks = () => {

    const librayID  = JSON.parse(window.localStorage.getItem("library"));

    if(librayID <= 3){
        console.log("Library ID: " + librayID);
        console.log("social links ONLINE VERSION");

        return(
            <div style={{display: "flex"}}>
                <a target="_blank" href="https://twitter.com/deathpositivel1" style={{textDecoration: "none"}}>
                    <div  style={{display: "flex", alignItems: "center", marginLeft: "-10px"}} >
                        <Image
                            alt=""
                            src={twitter}
                            style={{
                            height: `40px`,
                            width: `40px`,
                            padding: "10px",
                            }}
                        />
                        <Text>@deathpositivel1</Text>
                    </div>
                </a>
                <a target="_blank" href="https://www.facebook.com/DeathPositiveLibrary/" style={{textDecoration: "none"}}>
                    <div style={{display: "flex", alignItems: "center", marginLeft: "30px"}}>
                        <Image
                        alt="link to death postitive libraries facebook page"
                        src={facebook}
                        style={{
                            height: `40px`,
                            width: `40px`,
                            padding: "10px",
                        }}
                        />
                        <Text>@DeathPositiveLibrary</Text>
                    </div>
                </a>
                <a target="_blank" href="https://www.instagram.com/thedeathpositivelibrary" style={{textDecoration: "none"}}>
                    <div style={{display: "flex", alignItems: "center", marginLeft: "30px"}}>
                        <Image
                        alt="link to death postitive libraries instagram page"
                        src={instagram}
                        style={{
                            height: `40px`,
                            width: `40px`,
                            padding: "10px",
                        }}
                        />
                        <Text>@thedeathpositivelibrary</Text>
                    </div>
                </a>
            </div>
        );
    }else{
        console.log("Library ID: " + librayID);
        console.log("social links library VERSION");

        return(
            <div style={{display: "flex"}}>
                <div style={{display: "flex", alignItems: "center", marginLeft: "-10px"}}>
                    <Image
                        alt="link to death postitive libraries twitter page"
                        src={twitter}
                        style={{
                        height: `40px`,
                        width: `40px`,
                        padding: "10px",
                        }}
                    />
                    <Text>@deathpositivel1</Text>
                </div>
                <div style={{display: "flex", alignItems: "center", marginLeft: "30px"}}>
                    <Image
                    alt=""
                    src={facebook}
                    style={{
                        height: `40px`,
                        width: `40px`,
                        padding: "10px",
                    }}
                    />
                    <Text>@DeathPositiveLibrary</Text>
                </div>
                <div style={{display: "flex", alignItems: "center", marginLeft: "30px"}}>
                    <Image
                    alt=""
                    src={instagram}
                    style={{
                        height: `40px`,
                        width: `40px`,
                        padding: "10px",
                    }}
                    />
                    <Text>@thedeathpositivelibrary</Text>
                </div>
            </div>
        );
    }
}

export default SocialLinks;