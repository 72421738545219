import image01 from "../images/books/books-01.jpg";
import image02 from "../images/books/books-02.jpg";
import image03 from "../images/books/books-03.jpg";
import image04 from "../images/books/books-04.jpg";
import image05 from "../images/books/books-05.jpg";
import image06 from "../images/books/books-06.jpg";
import image07 from "../images/books/books-07.jpg";
import image08 from "../images/books/books-08.jpg";
import image09 from "../images/books/books-09.jpg";
import image10 from "../images/books/books-10.jpg";
import image11 from "../images/books/books-11.jpg";
import image12 from "../images/books/books-12.jpg";
import image13 from "../images/books/books-13.jpg";
import image14 from "../images/books/books-14.jpg";
import image15 from "../images/books/books-15.jpg";
import image16 from "../images/books/books-16.jpg";
import image17 from "../images/books/books-17.jpg";
import image18 from "../images/books/books-18.jpg";
import image19 from "../images/books/books-19.jpg";
import image20 from "../images/books/books-20.jpg";
import image21 from "../images/books/books-21.jpg";
import image22 from "../images/books/books-22.jpg";
import image23 from "../images/books/books-23.jpg";
import image24 from "../images/books/books-24.jpg";
import image25 from "../images/books/books-25.jpg";
import image26 from "../images/books/books-26.jpg";
import image27 from "../images/books/books-27.jpg";
import image28 from "../images/books/books-28.jpg";
import image29 from "../images/books/books-29.jpg";
import image30 from "../images/books/books-30.jpg";
import image31 from "../images/books/books-31.jpg";
import image32 from "../images/books/books-32.jpg";
import image33 from "../images/books/books-33.jpg";
import image34 from "../images/books/books-34.jpg";
import image35 from "../images/books/books-35.jpg";
import image36 from "../images/books/books-36.jpg";
import image37 from "../images/books/books-37.jpg";
import image38 from "../images/books/books-38.jpg";
import image39 from "../images/books/books-39.jpg";
import image40 from "../images/books/books-40.jpg";
import image41 from "../images/books/books-41.jpg";
import image42 from "../images/books/books-42.jpg";
import image43 from "../images/books/books-43.jpg";
import image44 from "../images/books/books-44.jpg";
import image45 from "../images/books/books-45.jpg";
import image46 from "../images/books/books-46.jpg";
import image47 from "../images/books/books-47.jpg";
import image48 from "../images/books/books-48.jpg";
import image49 from "../images/books/books-49.jpg";
import image50 from "../images/books/books-50.jpg";
import image51 from "../images/books/books-51.jpg";
import image52 from "../images/books/books-52.jpg";
import image53 from "../images/books/books-53.jpg";
import image54 from "../images/books/books-54.jpg";
import image55 from "../images/books/books-55.jpg";
import image56 from "../images/books/books-56.jpg";
import image57 from "../images/books/books-57.jpg";
import image58 from "../images/books/books-58.jpg";
import image59 from "../images/books/books-59.jpg";
import image60 from "../images/books/books-60.jpg";
import image61 from "../images/books/books-61.jpg";
import image62 from "../images/books/books-62.jpg";
import image63 from "../images/books/books-63.jpg";
import image64 from "../images/books/books-64.jpg";
import image65 from "../images/books/books-65.jpg";
import image66 from "../images/books/books-66.jpg";
import image67 from "../images/books/books-67.jpg";
import image68 from "../images/books/books-68.jpg";
import image69 from "../images/books/books-69.jpg";

const PATHS = {
  //Root: "/",
  Afterlife: "/afterlife",
  Aging: "/aging",
  Become: "/become",
  Bio: "/bio",
  Body: "/body",
  Burial: "/burial",
  Community: "/community",
  Conventional: "/conventional",
  Creating: "/creating",
  Cremation: "/cremation",
  Elaborate: "/elaborate",
  Energy: "/energy",
  Experience: "/experience",
  Family: "/family",
  Funerary: "/funerary",
  Games: "/games",
  Green: "/green",
  Leave: "/leavepast",
  Manual: "/manual",
  Material: "/material",
  Messages: "/messages",
  Photography: "/photography",
  Preserving: "/preserving",
  Reversing: "/reversing",
  Robots: "/robots",
  Scripted: "/scripted",
  Sea: "/sea",
  Sharing: "/sharing",
  Social: "/social",
  Stone: "/stone",
  Technology: "/technology",
  Tickets: "/ticket-",
  //Tickets: "/tickets",
  //Tickets: "/",
  Traditions: "/traditions",
  Transform: "/transform",
  Void: "/void",
  Wisdom: "/wisdom",
  Wildlife: "/wildlife",
  BooksIntro: "/booksintro-",
  Books: "/books-",
  Book: "/book-",
  Libraries: "/libraries",
  Introduction: "/Introduction",
  Start: "/start",
  Thanks: "/thanks",
  AllTickets: "/alltickets",
  Screen1: "/",
  Analytics: "/analytics",
  CreateTicket: "/CreateTicket",
  ModerateUserTickets: "/ModerateUserTickets",
  UserTickets: "/UserTicket-"
};

export const LIBRARIES = {
  GUEST: { name: "guest", index: 0 },
  NEWCASTLE: { name: "Newcastle", index: 1 },
  KIRKLEES: { name: "Kirklees", index: 2 },
  REDBRIGDE: { name: "Redbridge", index: 3 },
  NEWCASTLELOCAL: { name: "Newcastle", index: 4 },
  KIRKLEESLOCAL: { name: "Kirklees", index: 5 },
  REDBRIGDELOCAL: { name: "Redbridge", index: 6 },
};

export const QUESTION_LABELS = {
  ONEMORE: "one more question",
  LAST: "last question",
};

export const books = [
  {
    id: 1  ,
    title: "A Manual for Heartache",
    author: "Cathy Rentzenbrink ",
    image: image01,
    quote:
      "This is a legacy of trauma: we spend so much time anticipating a new horror that we destroy the present.",
    availability: [1, 1, 1, 0, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=108697&query_desc=kw%2Cwrdl%3A%20A%20manual%20for%20heartache",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:447066/one?qu=9781509824465&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=A+Manual+for+Heartache&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "920 REN",
      "155.937",
      "155.937",
    ],
    category: [
      "",
      "",
      "",
      "Motivation, Memoirs",
      "Motivation, Memoirs",
      "Motivation, Memoirs",
    ]
  },
  {
    id: 2,
    title: "Advice for Future Corpses",
    author: "Sallie Tisdale",
    image: image02,
    quote:
      "and the strange, undeniable fact that the presence of death can be joyful.",
    availability: [0, 1, 0, 0, 1, 0],
    links: [
      "",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:507203/one?qu=advice+for+the+dying&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      "",
    ]
  },
  {
    id: 3,
    title: "All That Remains",
    author: "Sue Black",
    image: image03,
    quote: "The best memorial is a boxful of happy memories inside your head.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=276103&query_desc=kw%2Cwrdl%3A%20All%20That%20Remains",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:462822/one?qu=9781784162818&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=All+That+Remains+sue+black&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "920 BLA",
      "363.25",
      "363.2509",
    ],
    category: [
      "",
      "",
      "",
      "Non-Fiction, Autobiography",
      "Non-Fiction, Autobiography",
      "Autobiography, Health, Science & Technology",
    ]
  },
  {
    id: 4,
    title: "Altered Carbon",
    author: "Richard K. Morgan",
    image: image04,
    quote: "If they asked how I died tell them: Still angry.",
    availability: [0, 1, 1, 0, 1, 1],
    links: [
      "",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/results?qu=Altered+Carbon+%5Belectronic+resource%5D&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fERC_2092_680$002f0$002fERC_2092_680:OVERDRIVE:21f57609-b002-41c0-a0c2-1ad227209dc2/one?qu=Altered+Carbon&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "E-Book",
      "E-Book",
    ],
    category: [
      "",
      "",
      "",
      "",
      "Science Fiction",
      "Science Fiction",
    ]
  },
  {
    id: 5,
    title: "Before the Coffee Gets Cold",
    author: "Toshikazu Kawaguchi",
    image: image05,
    quote: "We must become friends before this coffee cools.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299477",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:495785/one?qu=9781529050868&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Before+the+coffee+gets+cold&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "ADULT FICTION",
      "GEN",
    ],
    category: [
      "",
      "",
      "",
      "Adult Fiction",
      "Adult Fiction",
      "Adult Fiction",
    ]
  },
  {
    id: 6,
    title: "Being Mortal",
    author: "Atul Gawande",
    image: image06,
    quote: "For human beings, life is meaningful because it is a story.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=129770&query_desc=kw%2Cwrdl%3A%20Being%20Mortal",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:401655/one?qu=9781846685828&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Being+Mortal&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "306.9",
      "306.9",
      "306.9",
    ],
    category: [
      "",
      "",
      "",
      "Health, Non-fiction",
      "Health, Non-fiction",
      "Adult non-fiction, Large type books",
    ]
  },
  {
    id: 7,
    title: "Funerals to Die For",
    author: "Kathy Benjamin",
    image: image07,
    quote:
      "Here's a tip: if even maggots think it's a bad idea, it is a really bad idea.",
    availability: [0, 1, 1, 0, 1, 1],
    links: [
      "",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:507202/one?qu=funerals+to+die+for&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fERC_2092_680$002f0$002fERC_2092_680:OVERDRIVE:34d78b5c-3bfc-493a-a29d-ccd66457ee5e/one?qu=Funerals+to+Die+For+Kathy+Benjamin&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Health, Non-fiction",
      "Health, Non-fiction",
      "Humor, Nonfiction"
    ]
  },
  {
    id: 8,
    title: "This Party's Dead ",
    author: "Erica Buist",
    image: image08,
    quote: "What if we responded to death ... by throwing a party?",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=297194",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:507208/one?qu=This+Party%27s+Dead%3A+Grief%2C+Joy+and+Spilled+Rum+at+the+World%27s+Death+Festivals&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:3085626/one?qu=9781783529544&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "306.9",
      "306.9",
      "393",
    ],
    category: [
      "",
      "",
      "",
      "Autobiography, Travel literature",
      "Autobiography, Travel literature",
      "Autobiography, Travel literature"
    ]
  },
  {
    id: 9,
    title: "Virtual Afterlives",
    author: "Candi K. Cann",
    image: image09,
    quote:
      "From the dead body to the virtual body and from material memorials to virtual memorials, one thing is clear: the bodiless nature of memorialization of the dead across cultures.",
    availability: [1, 0, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299882",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:2407762/one?qu=Virtual+Afterlives+Candi+K.+Cann&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "393",
      "",
      "393",
    ],
    category: [
      "",
      "",
      "",
      "Non-Fiction",
      "",
      "Children's Non-Fiction"
    ]
  },
  {
    id: 10,
    title: "Digital Souls",
    author: "Patrick Stokes",
    image: image10,
    quote: "Social media is full of dead people.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299482",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:507210/one?qu=digital+souls+a+philosophy+of+online+death&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:2408908/one?qu=Digital+souls&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "179.7",
      "179.7",
      "179.7",
    ],
    category: [
      "",
      "",
      "",
      "Non-Fiction, Philosophy",
      "Non-Fiction",
      "Children's Non-Fiction"
    ]
  },
  {
    id: 11,
    title: "Do Androids Dream Of Electric Sheep?",
    author: "Philip K Dick",
    image: image11,
    quote:
      "It's the basic condition of life to be required to violate our own identity.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=45203&query_desc=kw%2Cwrdl%3A%20Do%20Androids%20Dream%20Of%20Electric%20Sheep%7B%3F%7D",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:188806/one?qu=9781857988130&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:157843/one?qu=Do+Androids+Dream+Of+Electric+Sheep%3F+%28BladeRunner%29&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "FICTION",
      "FICTION",
      "FICTION",
    ],
    category: [
      "",
      "",
      "",
      "Science Fiction",
      "Science Fiction",
      "Science Fiction"
    ]
  },
  {
    id: 12,
    title: "From Here to Eternity",
    author: "Caitlin Doughty",
    image: image12,
    quote:
      "All that surrounds us comes from death, every part of every city, and every part of every person.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=296227&query_desc=kw%2Cwrdl%3A%20From%20Here%20to%20Eternity",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:447887/one?qu=Doughty+Caitlin%2C+From+Here+to+Eternity&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:351045/one?qu=From+here+to+eternity+%3A+travelling+the+world+to+find+the+good+death&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "393",
      "393",
      "393",
    ],
    category: [
      "",
      "",
      "",
      "Biography & True Stories, Memoirs",
      "Adult Non-Fiction",
      "Adult Non-Fiction"
    ]
  },
  {
    id: 13,
    title: "Smoke Gets in your Eyes",
    author: "Caitlin Doughty",
    image: image13,
    quote:
      "Sifting through an urn of cremated remains you cannot tell if a person had successes, failures, grandchildren, felonies.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=296226&query_desc=kw%2Cwrdl%3A%20Smoke%20Gets%20in%20Your%20Eyes",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:417940/one?qu=9781782111054&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Smoke+Gets+in+your+Eyes+Caitlin+Doughty&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "363.75",
      "363.75",
      "363.7509",
    ],
    category: [
      "",
      "",
      "",
      "Non-Fiction, Autobiography",
      "Non-Fiction, Autobiography",
      "Large Print Fiction"
    ]
  },
  {
    id: 14,
    title: "Entangled Life",
    author: "Merlin Sheldrake",
    image: image14,
    quote: "Fungi make worlds. They also unmake them.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=292187&query_desc=kw%2Cwrdl%3A%20Entangled%20Life",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:494130/one?qu=9781847925190&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Entangled+life+%3A+how+fungi+make+our+worlds%2C+change+our+minds%2C+and+shape+our+futures&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "579.5",
      "579.5",
      "579.5",
    ],
    category: [
      "",
      "",
      "",
      "Non-Fiction",
      "Non-Fiction",
      "Adult non-fiction"
    ]
  },
  {
    id: 15,
    title: "Extremely loud and incredibly close",
    author: "Jonathan Foer",
    image: image15,
    quote: "I regret that it takes a life to learn how to live.",
    availability: [1, 1, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=20227&query_desc=kw%2Cwrdl%3A%20Extremely%20loud%20and%20incredibly%20close%20Jonathan%20Foer",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:506999/one?qu=extremely+loud+and+incredibly+close&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Extremely+loud+and+incredibly+close+Jonathan+Foer&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "AF",
      "AF",
      "AF",
    ],
    category: [
      "",
      "",
      "",
      "Modern Fiction",
      "Modern Fiction",
      "Large Print Fiction"
    ]
  },
  {
    id: 16,
    title: "Galatea 2.2",
    author: "Richard Powers",
    image: image16,
    quote: "Love is the feedback cycle of longing, belonging, loss.",
    availability: [1, 1, 0, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299479",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:506983/one?qu=Galatea+2.2&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Science Fiction",
      "Science Fiction",
      ""
    ]
  },
  {
    id: 17,
    title: "User Not Found",
    author: "Chris Goode",
    image: image17,
    quote:
      "It's the moment of your death. There's a magic button. Do you delete your entire online legacy?",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 18,
    title: "Crafting Meaningful Funeral Rituals",
    author: "Jeltje Gordon-Lennox",
    image: image18,
    quote:
      "When all is said and done, funerals reflect first of all our relationship with the dead.",
    availability: [1, 1, 0, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299485",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:507206/one?qu=crafting+meaningful+funeral+rituals&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "393.93",
      "393.93",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      ""
    ]
  },
  {
    id: 19,
    title: "Grief is the thing with feathers",
    author: "Max Porter",
    image: image19,
    quote:
      "Moving on, as a concept, is for stupid people, because any sensible person knows grief is a long-term project.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=43968",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:413101/one?qu=9780571323760&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Grief+is+the+thing+with+feathers+Max+Porter&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "ADULT FICTION",
      "ADULT FICTION",
      "ADULT FICTION",
    ],
    category: [
      "",
      "",
      "",
      "Adult Fiction",
      "Adult Fiction",
      "Adult Fiction"
    ]
  },
  {
    id: 20,
    title: "Death, Memory and Material Culture",
    author: "Elizabeth Hallam and Jenny Hockey",
    image: image20,
    quote:
      "How do the living maintain ongoing relationships with the dead in Western societies?",
    availability: [1, 0, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299883",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:2407764/one?qu=Death%2C+Memory+and+Material+Culture&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "306.9",
      "",
      "306.9",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      "Children's non-fiction"
    ]
  },
  {
    id: 21,
    title: "The Giant O'Brien",
    author: "Hilary Mantel",
    image: image21,
    quote: "Readers crave bodies. We're the ressurection men.",
    availability: [1, 1, 1, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=272306&query_desc=kw%2Cwrdl%3A%20The%20Giant%20O%27Brien",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/results?qu=The+giant%2C+O%27Brien.+Mantel%2C+Hilary&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=The+Giant+O%27Brien&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "N/A",
      "N/A",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Adult Fiction",
      "Adult Fiction",
      ""
    ]
  },
  {
    id: 22,
    title: "How Death Becomes Life",
    author: "Joshua Mezrich",
    image: image22,
    quote:
      "Let me join you in the recovery, your suffering, your fear of the unknown, your desire to become healthy, to get your life back.",
    availability: [1, 1, 1, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=287217&query_desc=kw%2Cwrdl%3A%20How%20Death%20Becomes%20Life",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:478708/one?qu=9781786498892&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=How+Death+Becomes+Life+Joshua+Mezrich&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "920 MEZ",
      "617.954",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "Non-fiction",
      "Non-fiction"
    ]
  },
  {
    id: 23,
    title: "How to Read a Graveyard",
    author: "Peter Stanford",
    image: image23,
    quote: "We remember the dead, but we 'forget their fate'.",
    availability: [1, 1, 0, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299879&query_desc=kw%2Cwrdl%3A%20How%20to%20Read%20a%20Graveyard%20Peter%20Stanford",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:358943/one?qu=how+to+read+a+graveyard&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "393",
      "393",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "Non-fiction",
      ""
    ]
  },
  {
    id: 24,
    title: "Underground",
    author: "Will Hunt",
    image: image24,
    quote: "There is another world, but it is in this one.",
    availability: [1, 1, 1, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299484&query_desc=kw%2Cwrdl%3A%20Underground%20Will%20Hunt",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:171142/one?qu=Underground+Kat+Richardson&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Underground+Will+Hunt&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "307.76",
      "307.76",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "Non-fiction",
      ""
    ]
  },
  {
    id: 25,
    title: "Memento Mori",
    author: "Peter Jones",
    image: image25,
    quote:
      "Romans never imagined they could 'fight' death. They dealt with it by facing the facts of the real world.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299488&query_desc=kw%2Cwrdl%3A%20memento%20mori",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:507204/one?qu=Memento+Mori%3A+What+the+Romans+Can+Tell+Us+About+Old+Age+and+Death&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:1748022/one?qu=Memento+Mori+jones&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "937",
      "937",
      "937",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "Non-fiction",
      "Adult non-fiction"
    ]
  },
  {
    id: 26,
    title: "Jump into the Abyss!",
    author: "Louise Blackwick",
    image: image26,
    quote:
      "And our love goes beyond flesh; it transcends Death's reminder. In the Underworld Library, two books sharing a binder.",
    availability: [0, 1, 0, 0, 1, 0],
    links: [
      "",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:507207/one?qu=Jump+into+the+Abyss%21&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "507207.1",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 27,
    title: "Klara and the Sun",
    author: "Kazuo Ishiguro",
    image: image27,
    quote:
      "Of course, a human heart is bound to be complex. But it must be limited.",
    availability: [1, 1, 0, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=295583&query_desc=kw%2Cwrdl%3A%20Klara%20and%20the%20Sun",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:499058/one?qu=9780571364879&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "XX(499058.3)",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Adult Fiction",
      "Adult Fiction",
      ""
    ]
  },
  {
    id: 28,
    title: "Languages of Loss",
    author: "Sasha Bates",
    image: image28,
    quote: "We die. This may be the meaning of life.",
    availability: [1, 0, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299481&query_desc=kw%2Cwrdl%3A%20Languages%20of%20Loss",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:1885952/one?qu=Languages+of+Loss+Sasha+Bates&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "155.937",
      "",
      "155.937",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      "Adult non-fiction",
    ]
  },
  {
    id: 29,
    title: "The gentle Art of Swedish Death Cleaning",
    author: "Margareta Magnusson",
    image: image29,
    quote:
      "Life will become more pleasant and comfortable if we get rid of some of the abundance.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=120364&query_desc=kw%2Cwrdl%3A%20The%20gentle%20Art%20of%20Swedish%20Death%20Cleaning%20Margareta%20Magnusson",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:466978/one?qu=9781785415876&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=The+gentle+Art+of+Swedish+Death+Cleaning&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "648.09",
      "648.80",
      "648.0948",
    ],
    category: [
      "",
      "",
      "",
      "non-fiction",
      "non-fiction",
      "Adult non-fiction",
    ]
  },
  {
    id: 30,
    title: "Celebrating the Life of a Loved One",
    author: "Richard Martin",
    image: image30,
    quote:
      "Whatever you finally decide to do, I hope your decision will bring you joy and peace.",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 31,
    title: "All the Ghosts in the Machine",
    author: "Elaine Kasket",
    image: image31,
    quote: "Does the Internet change how we grieve? It sure does.",
    availability: [1, 0, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=296214",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:1230957/one?qu=All+the+Ghosts+in+the+Machine+Elaine+Kasket&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "306.9",
      "",
      "306.9",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      "Adult non-fiction"
    ]
  },
  {
    id: 32,
    title: "Mors Britannica",
    author: "Douglas J.Davies",
    image: image32,
    quote: "This anthropology aligns lifestyle and what it calls death-style.",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",    
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 33,
    title: "Mourning Art & Jewelry",
    author: "Maureen Delorme",
    image: image33,
    quote:
      "Decorative art created to memorialize and commemorate death has been a part of Western culture for centuries.",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 34,
    title: "Mr. Nobody",
    author: "Catherine Steadman",
    image: image34,
    quote: "Sometimes the most terrifying thing is our own imagination.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=296668&query_desc=kw%2Cwrdl%3A%20Mr.%20Nobody%20Catherine%20Steadman",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:495558/one?qu=9781471167256&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Mr.+Nobody+Catherine+Steadman&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "THR",
      "ADULT FICTION",
      "THR (Woodford Green)",
    ],
    category: [
      "",
      "",
      "",
      "Adult Fiction",
      "Adult Fiction",
      "Adult Fiction"
    ]
  },
  {
    id: 35,
    title: "Mrs Death Misses Death",
    author: "Salena Godden",
    image: image35,
    quote: "Because once you have known Mrs Death there is no unknowing her.",
    availability: [0, 1, 0, 0, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=298257&query_desc=kw%2Cwrdl%3A%20Mrs%20Death%20Misses%20Death%20Salena%20Godden",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:496044/one?qu=9781838851194&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Mrs+Death+Misses+Death+Salena+Godden&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "ADULT FICTION",
      "XX(1878985.11)",
    ],
    category: [
      "",
      "",
      "",
      "Adult Fiction",
      "Adult Fiction",
      "Adult Fiction"
    ]
  },
  {
    id: 36,
    title: "Neuromancer",
    author: "William Gibson",
    image: image36,
    quote: "Case fell into the prison of his own flesh.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299476",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:428822/one?qu=9781473217386&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Neuromancer+William+Gibson&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "ADULT FICTION",
      "ADULT FICTION",
      "ADULT FICTION",
    ],
    category: [
      "",
      "",
      "",
      "Science Fiction",
      "Science Fiction",
      "Science Fiction"
    ]
  },
  {
    id: 37,
    title: "Never Let Me Go",
    author: "Kazuo Ishiguro ",
    image: image37,
    quote:
      "We took away your art because we thought it would reveal your souls.",
    availability: [0, 1, 1, 0, 1, 1],
    links: [
      "",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:419310/one?qu=9780571258093&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Never+Let+Me+Go+Kazuo+Ishiguro&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "Readers Group Material",
      "Readers Group Material",
    ],
    category: [
      "",
      "",
      "",
      "",
      "Adult fiction",
      "Adult fiction"
    ]
  },
  {
    id: 38,
    title: "Ready Player One",
    author: "Ernest Cline",
    image: image38,
    quote:
      "The same thing is going to happen to you that has happened to every other human being who has ever lived.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=296570&query_desc=kw%2Cwrdl%3A%20Ready%20Player%20One%20Ernest%20Cline",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/results?qu=Ready+player+one+%5Belectronic+resource%5D+Ready+player+one+%5Belectronic+resource%5D&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Ready+Player+One+Ernest+Cline&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "AF",
      "E-BOOK",
      "AF",
    ],
    category: [
      "",
      "",
      "",
      "Adult Fiction",
      "Adult Fiction",
      "Adult Fiction"
    ]
  },
  {
    id: 39,
    title: "Dark Archives",
    author: "Megan Rosenbloom",
    image: image39,
    quote:
      "Rare book libraries have long know that each old book is like a mystery awating its detective.",
    availability: [1, 0, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299480",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:2407759/one?qu=Dark+Archives+Megan+Rosenbloom&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "002",
      "",
      "002",
    ],
    category: [
      "",
      "",
      "",
      "Children's non-fiction",
      "",
      "Children's non-fiction"
    ]
  },
  {
    id: 40,
    title: "Tales of Ancient Egypt",
    author: "Roger Lancelyn Green",
    image: image40,
    quote:
      "Heavy was the heart of the evil-doer and it dragged down the Scale ...",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299486&query_desc=kw%2Cwrdl%3A%20Tales%20of%20Ancient%20Egypt%20Roger%20Lancelyn%20Green",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:483421/one?qu=tales+of+ancient+egypt+roger+lancelyn+green&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:397106/one?qu=Tales+of+Ancient+Egypt+Roger+Lancelyn+Green&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "J398.209",
      "E-Book",
      "JF",
    ],
    category: [
      "",
      "",
      "",
      "Junior Non-fiction",
      "General Fiction Junior",
      "General Fiction Junior"
    ]
  },
  {
    id: 41,
    title: "The Memory Tree",
    author: "Britta Teckentrup",
    image: image41,
    quote:
      "And so, as they share their memories, a tree begins to grow, becoming bigger and stronger with each memory.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=296194&query_desc=kw%2Cwrdl%3A%20The%20Memory%20Tree%20Britta%20Teckentrup",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:371165/one?qu=The+Memory+Tree+Britta&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:242661/one?qu=The+Memory+Tree+Britta+Teckentrup&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "N/A",
      "N/A",
      "XX(242661.38)",
    ],
    category: [
      "",
      "",
      "",
      "Picture Books",
      "Picture Books",
      "Children's fiction"
    ]
  },
  {
    id: 42,
    title: "The Archived",
    author: "Victoria Schwab",
    image: image42,
    quote:
      "Everything is valuable, in its own way. Everything is full of history.",
    availability: [1, 1, 0, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299895",
      "https://kirklees.overdrive.com/media/4378711",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "E-BOOK",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      ""
    ]
  },
  {
    id: 43,
    title: "The Darkened Room",
    author: "Alex Owen",
    image: image43,
    quote: "Spiritualism validated the female aurthoratative voice.",
    availability: [1, 1, 0, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299877",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:276165/one?qu=The+Darkened+Room+Alex+Owen&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "133.9",
      "133.9082",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      ""
    ]
  },
  {
    id: 44,
    title: "The Memory Shop",
    author: "Ella Griffin",
    image: image44,
    quote: "Memories are a powerful thing ... they stay with you forever.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=94126&query_desc=kw%2Cwrdl%3A%20The%20Memory%20Shop%20Ella%20Griffin",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:506982/one?qu=The+Memory+Shop+Ella+Griffin&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=The+Memory+Shop+Ella+Griffin&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "AF",
    ],
    category: [
      "",
      "",
      "",
      "Adult Fiction",
      "Adult Fiction",
      "Adult Fiction"
    ]
  },
  {
    id: 45,
    title: "The Midnight Library",
    author: "Matt Haig",
    image: image45,
    quote:
      "While we are alive we always contain a future of multifarious possibility.",
    availability: [0, 1, 1, 0, 1, 1],
    links: [
      "",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:499351/one?qu=9781786892737&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=The+Midnight+Library+Matt+Haig&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "GEN (Woodford Green)",
      "ADULT FICTION",
      "unlisted",
    ],
    category: [
      "",
      "",
      "",
      "",
      "Adult Fiction",
      "Adult Fiction"
    ]

  },
  {
    id: 46,
    title: "My Father's Wake",
    author: "Kevin Toolis",
    image: image46,
    quote:
      "if you never know sorrow then you will never know love and if you never know death then you never know life.",
    availability: [1, 0, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=271306&query_desc=kw%2Cwrdl%3A%20my%20fathers%20wake",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:1001037/one?qu=my+fathers+wake+kevin+toolis&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "393.094",
      "",
      "393.0941",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      "Society"
    ]
  },
  {
    id: 47,
    title: "Traditions of Death and Burial",
    author: "Helen Frisby",
    image: image47,
    quote: "We're all going to die, but it's become the great unmentionable",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299483",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:507205/one?qu=Traditions+of+Death+and+Burial&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:2407763/one?qu=Traditions+of+Death+and+Burial+Helen+Frisby&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "306.9",
      "306.9",
      "393.094",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "Non-fiction",
      "General Non-Fiction"
    ]
  },
  {
    id: 48,
    title: "They Both Die at the End",
    author: "Adam Silvera",
    image: image48,
    quote:
      "...stories can make someone immortal as long as someone else is willing to listen.",
    availability: [1, 1, 0, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=103678&query_desc=kw%2Cwrdl%3A%20They%20Both%20Die%20at%20the%20End%20Adam%20Silvera",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:445482/one?qu=9781471166204&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "TEEN",
      "TEEN",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Junior Fiction",
      "Junior Fiction",
      ""
    ]
  },
  {
    id: 49,
    title: "The Natural Death Handbook",
    author: "Stephanie Wienrich",
    image: image49,
    quote:
      "The completely revised and expanded third edition, an essential manual for dealing with death.",
    availability: [1, 0, 0, 1, 0, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299880",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "393.1",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      ""
    ]
  },
  {
    id: 50,
    title: "The Mingqi Pottery Buildings of Han Dynasty China",
    author: "Qinghua Guo",
    image: image50,
    quote:
      "An enormous number of burial objects have been unearthed from ancient tombs in archaeological excavations in China.",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 51,
    title: "Ubik",
    author: "Philip K. Dick",
    image: image51,
    quote: "I am Ubik. Before the universe was, I am.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=107234&query_desc=kw%2Cwrdl%3A%20Ubik",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:465747/one?qu=9781444840698&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Ubik+Philip+K.+Dick&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "Large Print",
      "Large Print",
      "XX(1235008.2)",
    ],
    category: [
      "",
      "",
      "",
      "Large Print Fiction",
      "Large Print Fiction",
      "Large Print Fiction"
    ]
  },
  {
    id: 52,
    title: "Technologies of the Human Corpse",
    author: "John Troyer",
    image: image52,
    quote:
      "The relationship of the dead body with technology through history, from nineteenth-century embalming machines to the death-prevention technologies of today",
    availability: [0, 1, 1, 0, 1, 1],
    links: [
      "",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:493607/one?qu=technologies+of+the+human+corpse&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:2407758/one?qu=Technologies+of+the+Human+Corpse+John+Troyer&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "363.75",
      "XX(493607.1)",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Children's non-fiction",
      "",
      ""
    ]
  },
  {
    id: 53,
    title: "With the end in mind",
    author: "Kathryn Mannix",
    image: image53,
    quote:
      "I was discovering that I was not afraid of death; rather, I was in awe of it, and of its impact on our lives.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=274320&query_desc=kw%2Cwrdl%3A%20With%20the%20end%20in%20mind%20Kathryn%20Mannix",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:460577/one?qu=9780008210915&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fERC_2092_680$002f0$002fERC_2092_680:OVERDRIVE:5957fea4-0747-45cf-b5aa-bba58ffda859/one?qu=With+the+end+in+mind+Kathryn+Mannix&qf=-ERC_FORMAT%09Electronic+Format%09MP3%09MP3&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "306.9",
      "306.9",
      "306.9",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      "Biography & Autobiography"
    ]
  },
  {
    id: 54,
    title: "London Curiosities",
    author: "John Wade",
    image: image54,
    quote: "London's streets are full of strangeness.",
    availability: [1, 0, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299489",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fERC_2092_680$002f0$002fERC_2092_680:OVERDRIVE:49f860a9-d830-4537-8c59-fb389e74d505/one?qu=London+Curiosities+John+Wade&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "942.12",
      "",
      "942.12",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      "Sociology, Nonfiction"
    ]
  },
  {
    id: 55,
    title: "Delete",
    author: "Viktor Mayer-Schönberger",
    image: image55,
    quote:
      "The digital realm remembers what is sometimes better forgotten, and this has profound implications for us all.",
    availability: [1, 1, 0, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299478",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:507209/one?qu=delete%3A+the+virtue+of+forgetting+in+the+digital+age&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "153.125",
      "153",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-Fiction",
      "",
      ""
    ]
  },
  {
    id: 56,
    title: "Badger's Parting Gifts",
    author: "Susan Varley",
    image: image56,
    quote: "His only worry was how his friends would feel when he was gone.",
    availability: [1, 1, 1, 1, 1, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=950",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:21339/one?qu=9780006643173&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Badger%27s+Parting+Gifts+Susan+Varley&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "Picture Books",
      "Picture Books",
      "XX(661062.17)",
    ],
    category: [
      "",
      "",
      "",
      "Children's fiction",
      "Children's fiction",
      "Children's fiction"
    ]
  },
  {
    id: 57,
    title: "Spiritualism and British Society Between the Wars",
    author: "Jenny Hazelgrove",
    image: image57,
    quote:
      "I do not remember a time during my childhood when I was unaware of legends of the supernatural.",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 58,
    title: "When Death Takes Something From You Give It Back",
    author: "Naja Marie Aidt",
    image: image58,
    quote:
      "I think about you all the time and there are moments when I don't think about you.",
    availability: [1, 1, 0, 1, 1, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=287245",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:478715/one?qu=When+Death+Takes+Something+From+You+Give+It+Back&te=ILS",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "839.813",
      "839.813",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "Non-fiction",
      ""
    ]
  },
  {
    id: 59,
    title: "The Other World",
    author: "Janet Oppenheim",
    image: image59,
    quote:
      "It is, after all, far more complelling to see one's own dining table in motion than to read about the antics of someone else's furniture.",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 60,
    title: "Time to Go",
    author: "Jean Francis",
    image: image60,
    quote:
      "There will never be a book that entirely removes the pain surrounding death. In spire of this, a spark of imagination can tranform an otherwise sombre occastion into a joyous thanksgiving of life.",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 61,
    title: "The Copper Tree",
    author: "Hilary Robinson and Mandy Stanley",
    image: image61,
    quote:
      "'The Copper Tree' is about love and legacy and will help children understand that while sadness is an inevitable part of grief, death is not necessarily the end.",
    availability: [1, 0, 0, 1, 0, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=296192",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Picture Book",
      "",
      ""
    ]
  },
  {
    id: 62,
    title: "Talking to the Dead",
    author: "Sheeran, Pat, and Nina Witoszek",
    image: image62,
    quote: "The making and unmaking of myth is nothing new.",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 63,
    title: "The Afterlives",
    author: "Thomas Pierce ",
    image: image63,
    quote:
      "Scale back enough, time-wise, and all we are is a quick parade of exploding hearts.",
    availability: [1, 0, 0, 1, 0, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=33639",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Literary Fiction",
      "",
      ""
    ]
  },
  {
    id: 64,
    title: "In Death Lamented",
    author: "Sarah Nehama",
    image: image64,
    quote:
      "The jewelry included illustrates some of the most exemplary types, from early gold bands with death's head iconography to intricately woven hairwork pieces",
    availability: [1, 0, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=299487",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:2407765/one?qu=In+Death+Lamented+Sarah+Nehama&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "739.27",
      "",
      "739.27",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      "Children's non-fiction"
    ]
  },
  {
    id: 65,
    title: "Finding Joy",
    author: "Gary Andrews",
    image: image65,
    quote:
      "Finding Joy is the story of how one family learned to live again after tragedy.",
    availability: [0, 0, 1, 0, 0, 1],
    links: [
      "",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:1886658/one?qu=Finding+Joy+Gary+Andrews&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "741.5",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      "Adult non-fiction"
    ]
  },
  {
    id: 66,
    title: "Outside the Box",
    author: "Liz Rothschild",
    image: image66,
    quote: "I had never seen my mother cry. The world became less stable.",
    availability: [1, 0, 0, 1, 0, 0],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=295612",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "155.937",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "Non-Fiction",
      "",
      ""
    ]
  },
  {
    id: 67,
    title: "Will My Cat Eat My Eyeballs?",
    author: "Caitlin Doughty",
    image: image67,
    quote: "We can't make death fun, but we can make learning about death fun.",
    availability: [0, 0, 0, 0, 0, 0],
    links: [
      "",
      "",
      "",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    category: [
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  {
    id: 68,
    title: "Let's Talk about Death",
    author: "Michael Hebb",
    image: image68,
    quote:
      "I invite you to help me change how we talk about death, one conversation at a time.",
    availability: [1, 0, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=296212",
      "",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:1239726/one?qu=Let%27s+Talk+about+Death+Michael+Hebb&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "306.9",
      "",
      "306.9",
    ],
    category: [
      "",
      "",
      "",
      "Non-fiction",
      "",
      "Childrens General Non-Fiction"
    ]
  },
  {
    id: 69,
    title: "Stiff",
    author: "Mary Roach",
    image: image69,
    quote:
      "You are a person and then you cease to be a person, and a cadaver takes your place.",
    availability: [1, 1, 1, 1, 0, 1],
    links: [
      "https://libraries.newcastle.gov.uk/cgi-bin/koha/opac-detail.pl?biblionumber=296191&query_desc=kw%2Cwrdl%3A%20Stiff%20Mary%20Roach",
      "https://kir.ent.sirsidynix.net.uk/client/en_GB/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:467696/one?qu=Stiff+mary+roach&te=ILS",
      "https://llc.ent.sirsidynix.net.uk/client/en_GB/redbridge/search/results?qu=Stiff+Mary+Roach&te=&lm=REDBRIDGE",
      "LOCAL",
      "LOCAL",
      "LOCAL",
    ],
    dewey: [
      "",
      "",
      "",
      "614.6",
      "E-Book",
      "611.21",
    ],
    category: [
      "",
      "",
      "",
      "Non-Fiction",
      "Non-Fiction",
      "Adult non-fiction"
    ]
  },
];

export default PATHS;
