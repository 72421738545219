import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import * as manager from "../../src/utils/manager";
import PATHS from "../utils/constants";
import Image from "../components/Image";
import HugeTitle from "../typocomps/HugeTitle";
import Title from "../typocomps/Title";
import Text from "../typocomps/Text";
import next from "../images/icons/next.png";

import Tree from "../images/tickets/ticket37.jpg";
import Sockets from "../images/sockets.jpg";
import Curtain from "../images/curtain.jpg";
import Back from "../images/tickets/ticket38.jpg"
import CursorVideo from "../images/landscape-cursor.gif"
import Sounds from "../components/Sounds";
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";


//tickets
const Analytics = () => {

  const data = [];
  const labels = [];

  let state = {
    labels: labels,
    datasets: [
      {
        label: 'views',
        backgroundColor: '#282828',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: data,
      }
    ]
  }

  function getTotalVeiws(){

    const db = getFirestore();
    
    const unsub = onSnapshot(doc(db, "users", "views"), (doc) => {
      const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
      document.getElementById("totalViews").innerHTML = ("Total Quiz Views: " + (doc.data().views));
    });
  }
  getTotalVeiws();

  async function getTicketData(){
    
    const db = getFirestore();
    const docRef = doc(db, "views", "Tickets");
    const docSnap = await getDoc(docRef);
    
    const querySnapshot = await getDocs(collection(db, "views"));

    querySnapshot.forEach((doc) => {
      labels.push((doc.id));
      data.push(doc.data().ticket);    
    });

    state.datasets.data = data;
    console.log(data);
    var sum = 0;
    for(let i=0; i < data.length; i++){
      sum = (sum + data[i]);
    }
    document.getElementById("totalTicketViews").innerHTML = ("Total Ticket Views: " + sum);
    state.labels = labels;
  }


  //chart atempt 2
  useEffect(() => {
    getTicketData();
  }, []);

  let landscapeComp = (
      <div id="chartOuter" style={{ overflow: "auto"}}>
      <Bar
        data={state}
        redraw={true}
        options={{
          title:{
            display:true,
            text:'Tickets',
            fontSize:20
          },
          legend:{
            display:true,
            position:'right'
          }
        }}
      />
      <p id="totalViews">Total quiz views: </p>
      <p id="totalTicketViews">Total Ticket Views: </p>
    </div>
  );


  return landscapeComp;
};

export default Analytics;
