import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import QuestionsFrame from "../components/QuestionsFrame";
import { QUESTION_LABELS } from "../utils/constants";
import nohead from "../images/nohead.jpg";
import PATHS from "../utils/constants";
import * as manager from "../utils/manager";
import SmallTitle from "../typocomps/SmallTitle";
import Image from "../components/Image";
import Sounds from "../components/Sounds";

const FamilyScreen = () => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  /******************************************************************/

  const containerW =
    versionToShow === 1
      ? 250
      : versionToShow === 2
      ? 290
      : versionToShow === 3
      ? 330
      : versionToShow === 4
      ? 330
      : 330;

  const imageW =
    versionToShow === 1
      ? 200
      : versionToShow === 2
      ? 240
      : versionToShow === 3
      ? 380
      : versionToShow === 4
      ? 380
      : 280;

  const imageRatio = 280 / 187; //280, 187
  const imageH = imageW / imageRatio;

  const uptextW =
    versionToShow === 1
      ? 115
      : versionToShow === 2
      ? 115
      : versionToShow === 3
      ? 150
      : versionToShow === 4
      ? 150
      : 150;

  const downtextW =
    versionToShow === 1
      ? 70
      : versionToShow === 2
      ? 75
      : versionToShow === 3
      ? 90
      : versionToShow === 4
      ? 90
      : 90;

  return (
    <QuestionsFrame uplabel={QUESTION_LABELS.LAST}>
    <Sounds sounds={"MaterialBelongings"}/>
      <div className="centered-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: `${containerW}px`,
            position: "relative",
          }}
        >
          <div
            style={{
              marginBottom: "0px",
              width: `${uptextW}px`,
              paddingLeft: "5px",
              position: "relative",
              top: "10px",
              left: "15px",
              zIndex: 2,
              backgroundColor: "#dcdad4",
            }}
          >
            <Link to={PATHS.Tickets + "17"} className="no-decoration">
              <SmallTitle>keep in the family</SmallTitle>
            </Link>
          </div>
          <div
            style={{
              position: "relative",
              alignSelf: "center",
              border: "1px solid black",
              zIndex: 1,
            }}
          >
            <Image
              alt=""
              src={nohead}
              style={{
                height: `${imageH}px`,
                width: `${imageW}px`,
                padding: "20px",
              }}
            />
            <Link
              to={PATHS.Tickets + "18"}
              className="no-decoration"
              style={{
                position: "absolute",
                top: "50%",
                left: "30%",
              }}
            >
              <SmallTitle style={{ color: "white" }}>
                save for the future
              </SmallTitle>
            </Link>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                textAlign: "right",
                marginTop: "0px",
                width: `${downtextW}px`,
                paddingRight: "5px",
                position: "relative",
                bottom: "10px",
                right: "15px",
                zIndex: 2,
                backgroundColor: "#dcdad4",
              }}
            >
              <Link to={PATHS.Tickets + "19"} className="no-decoration">
                <SmallTitle>give away</SmallTitle>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </QuestionsFrame>
  );
};

export default FamilyScreen;
