import React from "react";

import CoupledChoices from "../../components/CoupledChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import body1 from "../../images/body1.jpg";
import body2 from "../../images/body2.jpg";
import PATHS from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";

const BodyScreen = () => {
  return (
    <CoupledChoices
      labelUp={<BoldLabel>reconstruct body</BoldLabel>}
      labelDown={<BoldLabel>reconstruct mind</BoldLabel>}
      imageUp={body1}
      imageDown={body2}
      pathUp={PATHS.Tickets + "47"}
      pathDown={PATHS.Tickets + "48"}
      questionLabel={QUESTION_LABELS.LAST}
      sound="ReversingDeath"
    />
  );
};

export default BodyScreen;
