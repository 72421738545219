import React from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import ListUsertickets from "./ListUsertickets";


const Usertickets = () => {

    let ticketObjs = [];
    let itemList = [];



    const firebaseConfig = {
        apiKey: "AIzaSyCdReuu98P1G3H6QBlEaBAEmHPfBwS0ZM8",
        authDomain: "loveafterdeath-5ba5c.firebaseapp.com",
        databaseURL: "https://loveafterdeath-5ba5c-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "loveafterdeath-5ba5c",
        storageBucket: "loveafterdeath-5ba5c.appspot.com",
        messagingSenderId: "828316428743",
        appId: "1:828316428743:web:5fa03e265b70be981511e2",
        measurementId: "G-WLL87W5PYQ"
    };

    const firebaseApp = initializeApp(firebaseConfig);

    const db = getFirestore();

    const FetchData = async () => {
        const docRef = doc(db, "userTickets", "test");
        const docSnap = await getDoc(docRef);

        const q = query(collection(db, "userTickets"), where("reveiwed", "==", true));
        
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          ticketObjs.push(doc.data());
        });
        //console.log(ticketObjs); //array of objects for all records in userTickets database
        await mapData();
    }

    FetchData();

    const mapData = async () => {
        let items = ticketObjs;
        items.forEach((item, index)=>{
            itemList.push( <li key={index}>{item.title}</li>);
            document.getElementById("UserTicketsOutput").innerHTML += (
                "<div class='userticketCard' id=userticketCard" + index + ">" + 
                    "<div id=userticketCardTitle> " + item.title + "</div>" +
                    "<div id=userticketCardImgOuter>" +
                        "<div id=userticketCardAspectDummy></div>" + 
                        "<img id=userticketCardImg src='" + item.imgURL + "'></img>" +
                    "</div>" +
                    "<div class=cardTags id=cardTags" + index + "><div class=carTag1 id=carTag1" + index + ">" + item.tag1 +"</div><div class=carTag2  id=carTag2" + index + ">" + item.tag2 +"</div></div>" +
                    "<button onclick='window.location.href = `/UserTicket-" + item.index + "`'> See Full Ticket </button>" +
                    "<div id=cardnumbering>"+ (index + 1) + "/<span class=cardsTotal></span></div>" + 
                "</div>"
            );
            if(item.tag1 == null ){document.getElementById("carTag1" + index).style.display = "none";}
            if(item.tag2 == null ){document.getElementById("carTag2" + index).style.display = "none";}
            if(item.tag2 == null && item.tag1 == null ){document.getElementById("cardTags" + index).style.display = "none";}
        })
        let totals = (document.getElementsByClassName("cardsTotal"));
        console.log("totals");
        console.log(totals);
        for(let i=0; i < totals.length; i++){
            totals[i].innerHTML = totals.length;
        }
  
    }

    return(
        <div>
            <div className="CreateTicektPageHeader">
                <div className="HorzontalLine"></div>
                <p className="lowerTitle">submitted tickets</p>
                <p> Discover even more ways to navigate the afterlife in this section of personal submissions.</p>
            </div>
            <ul id="UserTicketsOutput">
                {itemList} 
            </ul>
        </div>
    );
    
}

export default Usertickets;