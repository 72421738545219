import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";


import UserTicketsApprove from "../components/userTicketsApprove";

    // Set the configuration for your app
    const firebaseConfig = {
        apiKey: "AIzaSyCdReuu98P1G3H6QBlEaBAEmHPfBwS0ZM8",
        authDomain: "loveafterdeath-5ba5c.firebaseapp.com",
        databaseURL: "https://loveafterdeath-5ba5c-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "loveafterdeath-5ba5c",
        storageBucket: "loveafterdeath-5ba5c.appspot.com",
        messagingSenderId: "828316428743",
        appId: "1:828316428743:web:5fa03e265b70be981511e2",
        measurementId: "G-WLL87W5PYQ"
    };

    const firebaseApp = initializeApp(firebaseConfig);


    
    // Get a reference to the storage service, which is used to create references in your storage bucket
    const storage = getStorage(firebaseApp);


    function ModerateUserTicket() {

        return (
            <div>
                <div className="CreateTicektPageHeader">
                    <p className="title">moderate user tickets</p>
                    <p>below tickets are yet to be reveiwed </p>
                </div>          
                <UserTicketsApprove />
            </div>
        );
    };

export default ModerateUserTicket;