import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import * as manager from "../../src/utils/manager";
import PATHS, { LIBRARIES } from "../utils/constants";
import next from "../images/icons/next.png";
import HugeTitle from "../typocomps/HugeTitle";
import Title from "../typocomps/Title";
import Text from "../typocomps/Text";
import Image from "../components/Image";

import Tree from "../images/tickets/ticket37.jpg";
import Sockets from "../images/sockets.jpg";
import Curtain from "../images/curtain.jpg";
import Back from "../images/tickets/ticket38.jpg"
import Sounds from "../components/Sounds";

import { motion } from "framer-motion"

const IntroductionScreen = ({ history }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 80
      : versionToShow === 2
      ? 80
      : versionToShow === 3
      ? 120
      : versionToShow === 4
      ? 180
      : 120;
  const imageH = 1.0 * imageW;

  const goNext = () => {
    history.push(PATHS.Start);
  };

  const landscapeComp = (
    <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
    <Sounds sounds={"Explore"}/>
    <div className="centered-content" style={{ transform: "scale(0.8)"}}>
      <div style={{ display: "flex", flexWrap: "wrap", marginRight: "50px"}}> 
        <div style={{  }}>
          <HugeTitle style={{ fontSize: 180, marginBottom: "5%",  marginTop: "15%"}}>explore.</HugeTitle>
          <Text style={{ fontSize: 20, marginBottom: "2%"}}>In recent times we have all been prompted to think a bit more about death and what it means for us. But it’s important we don’t feel alone with these thoughts. As a trusted space in the community, libraries can help support people to have conversations around loss, grief, and legacy.</Text>
          <Text style={{ fontSize: 20, marginBottom: "2%"}}>This site will help you explore a range of choices. And discover what you can do with your body, memories and legacies. With rituals from the past, present and future of death and dying. </Text>
          <Text style={{ fontSize: 20, marginBottom: "2%"}}>You will be asked to decide what matters most to you, to find your own ticket for the afterlife.</Text>
        </div>
      </div>
      <div>
        <div>
          <Image
            className="skeletonLoader"
            alt=""
            src={Tree}
            style={{
              //1319 x 413
              height: `133px`,
              width: `422px`,
              //paddingLeft: "20px",
              marginTop: "70px"
            }}
          />
        </div>
        <div>
          <Image
            className="skeletonLoader"
            alt=""
            src={Sockets}
            style={{
              //541 x 817
              height: `204px`,
              width: `135px`,
              //padding: "10px",
              marginLeft: "50%",
              marginTop: "10%"
            }}
          />
        </div>
        <div>
          <Image
            className="skeletonLoader"
            alt=""
            src={Curtain}
            style={{
              //594 x 594
              height: `110px`,
              width: `110px`,
              //padding: "10px",
              marginLeft: "10%",
              marginTop: "-10%"
            }}
          />
        </div>
        <div style={{width: "100%", display: "flex", justifyItems: "end"}}>
          <Image
            className="skeletonLoader"
            alt=""
            src={Back}
            style={{
              //359 x 673
              height: `112px`,
              width: `60px`,
              marginLeft: "calc(100% - 60px )"
            }}
          />
        </div>
        <div
          onClick={() => goNext()}
          style={{
            cursor: "pointer",
            textAlign: "right",
            marginTop: "5%",
            display: "flex",
            flexWrap: "wrap", 
            alignItems: "center",
            justifyContent: "right",
            marginLeft: "calc(100% - 180px)"
          }}
          >
          <Text style={{fontSize: 20}}>continue</Text>
          <img alt="" src={next} style={{ width: `${70}px`, marginLeft: "20px", marginTop: "5px" }} />
        </div>
      </div>
    </div>
    </motion.div>
  );

  // MOBILE
  const portraitComp = (
    <div className="centered-content" >
    <Sounds sounds={"Explore"}/>
      <div>
      <div>
        <div class="sideBloack" style={{ position: "absolute", width: "13px", height: "75px", marginTop: "45px", background: "#282828" }}>
        </div>
        <Title style={{ fontSize: 60, width: "85%", margin: "auto", marginBottom: "0%"}}>explore.</Title>
        <div style={{ width: "85%", margin: "auto", marginBottom: "5%",}}>
          <Text style={{ fontSize: 12, marginBottom: "2%"}}>In recent times we have all been prompted to think a bit more about death and what it means for us. But it’s important we don’t feel alone with these thoughts. As a trusted space in the community, libraries can help support people to have conversations around loss, grief, and legacy.</Text>
          <Text style={{ fontSize: 12, marginBottom: "2%"}}>This site will help you explore a range of choices. And discover what you can do with your body, memories and legacies. With rituals from the past, present and future of death and dying. </Text>
          <Text style={{ fontSize: 12, marginBottom: "2%"}}>You will be asked to decide what matters most to you, to find your own ticket for the afterlife.</Text>
        </div>
        <div
          onClick={() => goNext()}
          style={{
            cursor: "pointer",
            textAlign: "center",
            marginTop: "0%",
            marginRight: "30px",
            display: "flex",
            justifyContent: "flex-end",
            height: "3rem"
          }}
        >
          <p style={{display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1rem", transform: "translateY(-0.1rem)"}}>Continue</p>
          <img alt="" src={next} style={{ width: `${50}px` }} />
          
        </div>
        </div>
      </div>
    </div>
  );

  return showPortrait ? portraitComp : landscapeComp;
};

export default IntroductionScreen;
