import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import QuestionsFrame from "./QuestionsFrame";
import * as manager from "../utils/manager";
import HugeTitle from "../typocomps/HugeTitle";
import BoldLabel from "../typocomps/BoldLabel";
import Image from "../components/Image";
import Sounds from "../components/Sounds";

import { motion } from "framer-motion"

const ChooseScreen = ({
  optionsData,
  questionLabel,
  rotateImagesForPortrait,
  rotateImagesForMobileLandscape,
  imageTypeSmall,
  sound
}) => {
  const { label: label1, image: image1, path: path1 } = optionsData.option1;
  const { label: label2, image: image2, path: path2 } = optionsData.option2;
  const { label: label3, image: image3, path: path3 } = optionsData.option3;

  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 75
      : versionToShow === 2
      ? 100
      : versionToShow === 3
      ? 130
      : versionToShow === 4
      ? 130
      : 130;

  const imageRatio = imageTypeSmall ? 0.58 : 1.5; // 541, 817 // 549, 318
  const imageH = imageW * imageRatio;

  const rotateForLandscape =
    rotateImagesForMobileLandscape && versionToShow <= 2;

  const containerW = rotateForLandscape
    ? 3 * imageH + imageH / 8
    : 3 * imageW + 1.5 * imageW;

  const landscapeComp = (

    <div className="centered-content">
      <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
      <Sounds sounds={sound}/>
      <div className="column" style={{ width: `${containerW}px` }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            position: rotateForLandscape ? "relative" : null,
            left: rotateForLandscape ? `${(imageH - imageW) / 2}px` : null,
          }}
        >
          <HugeTitle
            style={{
              alignSelf: "flex-end",
            }}
          >
            Choose
          </HugeTitle>
        </div>

        <div className={"row"} style={{ justifyContent: "space-between" }}>
          <div className="column-centered" style={{ width: `${imageW}px` }}>
            <div>
              <Link to={path1} className="no-decoration">
                <Image
                  alt=""
                  src={image1}
                  style={{
                    width: `${imageW}px`,
                    height: `${imageH}px`,
                    marginBottom: "5px",
                    transform: rotateForLandscape ? "rotate(90deg)" : null,
                    position: rotateForLandscape ? "relative" : null,
                    bottom: rotateForLandscape
                      ? `${(imageH - imageW) / 2}px`
                      : null,
                  }}
                />
                <BoldLabel
                  style={{
                    position: rotateForLandscape ? "relative" : null,
                    bottom: rotateForLandscape ? `${imageH - imageW}px` : null,
                  }}
                >
                  {label1}
                </BoldLabel>
              </Link>
            </div>
          </div>

          <div className="column-centered" style={{ width: `${imageW}px` }}>
            <div>
              <Link to={path2} className="no-decoration">
                <Image
                  alt=""
                  src={image2}
                  style={{
                    width: `${imageW}px`,
                    height: `${imageH}px`,
                    marginBottom: "5px",
                    transform: rotateForLandscape ? "rotate(90deg)" : null,
                    position: rotateForLandscape ? "relative" : null,
                    bottom: rotateForLandscape
                      ? `${(imageH - imageW) / 2}px`
                      : null,
                  }}
                />
                <BoldLabel
                  style={{
                    position: rotateForLandscape ? "relative" : null,
                    bottom: rotateForLandscape ? `${imageH - imageW}px` : null,
                  }}
                >
                  {label2}
                </BoldLabel>
              </Link>
            </div>
          </div>

          <div className="column-centered" style={{ width: `${imageW}px` }}>
            <div>
              <Link to={path3} className="no-decoration">
                <Image
                  alt=""
                  src={image3}
                  style={{
                    width: `${imageW}px`,
                    height: `${imageH}px`,
                    marginBottom: "5px",
                    transform: rotateForLandscape ? "rotate(90deg)" : null,
                    position: rotateForLandscape ? "relative" : null,
                    bottom: rotateForLandscape
                      ? `${(imageH - imageW) / 2}px`
                      : null,
                  }}
                />
                <BoldLabel
                  style={{
                    position: rotateForLandscape ? "relative" : null,
                    bottom: rotateForLandscape ? `${imageH - imageW}px` : null,
                  }}
                >
                  {label3}
                </BoldLabel>
              </Link>
            </div>
          </div>
        </div>
      </div>
      </motion.div>
    </div>
    
  );

  /************************************** Portrait *******************************************/
  const pimageW =
    versionToShow === 1 && rotateImagesForPortrait
      ? 60
      : versionToShow === 1 && !rotateImagesForPortrait
      ? 100
      : versionToShow === 2 && rotateImagesForPortrait
      ? 90
      : versionToShow === 2 && !rotateImagesForPortrait
      ? 140
      : versionToShow === 3 && rotateImagesForPortrait
      ? 140
      : versionToShow === 3 && !rotateImagesForPortrait
      ? 240
      : 100;
  const pimageH = pimageW * imageRatio;

  const itemMarginBottom = rotateImagesForPortrait ? "10px" : "40px";
  const portraitComp = (
    <div className="centered-content">
      <Sounds sounds={sound}/>
      <div className="column" style={{ width: "70%", height: "85%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "7%",
          }}
        >
          <HugeTitle
            style={{
              alignSelf: "flex-end",
            }}
          >
            Choose
          </HugeTitle>
        </div>

        <div className="column" style={{ justifyContent: "space-between" }}>
          <div
            className="column-centered"
            style={{
              marginBottom: itemMarginBottom,
            }}
          >
            <Link to={path1} className="no-decoration">
              <div>
                <Image
                  alt=""
                  src={image1}
                  style={{
                    width: `${pimageW}px`,
                    height: `${pimageH}px`,
                    marginBottom: "0px",
                    transform: rotateImagesForPortrait ? "rotate(90deg)" : null,
                    position: rotateImagesForPortrait ? "relative" : null,
                    bottom: rotateImagesForPortrait
                      ? `${(pimageH - pimageW) / 2}px`
                      : null,
                  }}
                />

                <BoldLabel
                  style={{
                    position: rotateImagesForPortrait ? "relative" : null,
                    bottom: rotateImagesForPortrait
                      ? `${pimageH - pimageW}px`
                      : null,
                  }}
                >
                  {label1}
                </BoldLabel>
              </div>
            </Link>
          </div>
          <div
            className="column-centered"
            style={{ marginBottom: itemMarginBottom }}
          >
            <Link to={path2} className="no-decoration">
              <div
                style={{
                  position: rotateImagesForPortrait ? "relative" : null,
                  bottom: rotateImagesForPortrait
                    ? `${(pimageH - pimageW) / 2}px`
                    : null,
                }}
              >
                <Image
                  alt=""
                  src={image2}
                  style={{
                    width: `${pimageW}px`,
                    height: `${pimageH}px`,
                    marginBottom: "0px",
                    transform: rotateImagesForPortrait ? "rotate(90deg)" : null,
                    position: rotateImagesForPortrait ? "relative" : null,
                    bottom: rotateImagesForPortrait
                      ? `${(pimageH - pimageW) / 2}px`
                      : null,
                  }}
                />

                <BoldLabel
                  style={{
                    position: rotateImagesForPortrait ? "relative" : null,
                    bottom: rotateImagesForPortrait
                      ? `${pimageH - pimageW}px`
                      : null,
                  }}
                >
                  {label2}
                </BoldLabel>
              </div>
            </Link>
          </div>

          <div className="column-centered">
            <Link to={path3} className="no-decoration">
              <div
                style={{
                  position: rotateImagesForPortrait ? "relative" : null,
                  bottom: rotateImagesForPortrait
                    ? `${pimageH - pimageW}px`
                    : null,
                }}
              >
                <Image
                  alt=""
                  src={image3}
                  style={{
                    width: `${pimageW}px`,
                    height: `${pimageH}px`,
                    marginBottom: "0px",
                    transform: rotateImagesForPortrait ? "rotate(90deg)" : null,
                    position: rotateImagesForPortrait ? "relative" : null,
                    bottom: rotateImagesForPortrait
                      ? `${(pimageH - pimageW) / 2}px`
                      : null,
                  }}
                />

                <BoldLabel
                  style={{
                    position: rotateImagesForPortrait ? "relative" : null,
                    bottom: rotateImagesForPortrait
                      ? `${pimageH - pimageW}px`
                      : null,
                  }}
                >
                  {label3}
                </BoldLabel>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <QuestionsFrame uplabel={questionLabel}>
      {showPortrait ? portraitComp : landscapeComp}
    </QuestionsFrame>
  );
};

export default ChooseScreen;
