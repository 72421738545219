import React from "react";
import { useMediaQuery } from "react-responsive";

import * as manager from "../utils/manager";

const UserTicketContent = ({ children }) => {
  const { WIDTH: W, W2H, TOP } = manager.SIZES(useMediaQuery);

  return (
    <div
      className="row"
      style={{
        position: "absolute" /*Can also be `fixed`*/,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        /* Solves a problem in which the content is being cut when the div 
              is smaller than its' wrapper: */
        maxWidth: "100%",
        maxHeight: "100%",
      }}
    >
      {children}
    </div>
  );
};

export default UserTicketContent;
