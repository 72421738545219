import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import BoldLabel from "../typocomps/BoldLabel";
import * as manager from "../utils/manager";
import Sounds from "../components/Sounds";
import ScreenAnalytics from "./ScreenAnalytics";

const TwoVerticalOptions = ({ textUp, textDown, pathUp, pathDown }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const gap = versionToShow === 1 ? 10 : 20;
  return (
    <div className="column-centered">
      <Link to={pathUp} className="no-decoration">
        <BoldLabel style={{ marginBottom: `${gap}px` }}>{textUp}</BoldLabel>
      </Link>
      <div style={{ width: "100%", height: 1, backgroundColor: "black" }}></div>
      <Link to={pathDown} className="no-decoration">
        <BoldLabel style={{ marginTop: `${gap}px` }}>{textDown}</BoldLabel>
      </Link>
    </div>
  );
};

export default TwoVerticalOptions;
