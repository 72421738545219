import React from "react";

import ChooseScreen from "../../components/ChooseScreen";
import { QUESTION_LABELS } from "../../utils/constants";
import social from "../../images/social.jpg";
import online from "../../images/online.jpg";
import access from "../../images/access.jpg";
import PATHS from "../../utils/constants";

const SocialChoose = () => {
  return (
    <ChooseScreen
      optionsData={{
        option1: {
          label: "social media profiles",
          image: social,
          path: PATHS.Tickets + "20",
        },

        option2: {
          label: "online collection",
          image: online,
          path: PATHS.Tickets + "21",
        },
        option3: {
          label: "access to information",
          image: access,
          path: PATHS.Tickets + "22",
        },
      }}
      questionLabel={QUESTION_LABELS.LAST}
      imageTypeSmall={true}
      sound="RecordsInfo"
    />
  );
};

export default SocialChoose;
