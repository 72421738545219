import React from "react";

import ScreenTypeB from "../../components/ScreenTypeB";
import { QUESTION_LABELS } from "../../utils/constants";
import become from "../../images/become.png";
import PATHS from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";

const BecomeScreen = () => {
  return (
    <ScreenTypeB
      text="What do you want your ashes to"
      word="become?"
      textUp={<BoldLabel>one with nature</BoldLabel>}
      textDown={
        <BoldLabel>
          memory for
          <br />a loved one
        </BoldLabel>
      }
      pathUp={PATHS.Sea}
      pathDown={PATHS.Stone}
      questionLabel={QUESTION_LABELS.LAST}
      imageU={become}
      imageB={become}
      imageURatio={0.97}
      imageBRatio={0.97}
      sound="Cremation"
    />
  );
};

export default BecomeScreen;
