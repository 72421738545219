import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import PATHS from "../utils/constants";
import * as manager from "../utils/manager";
import restart from "../images/icons/restartInvert.png";
import allTickets from "../images/icons/allticketsInvert2.png";
import share from "../images/icons/shareInvert.png";
import next from "../images/icons/nextInvert.png";
import Text from "../typocomps/Text";
import Title from "../typocomps/Title";
import BoldText from "../typocomps/BoldText";
import ConventionalScreen from "../screens/typeA/ConventionalScreen";
import MyTicketBottomButton from "./myTicketBottomButton.js";

import facebook from "../images/icons/facebook.png";
import twitter from "../images/icons/twitter.png";
import download from "../images/icons/download.png";
import ExitIcon from "../images/icons/ExitIcon.png";
import SocialDropDown from "./SoicalDropDown";
import Sounds from "../components/Sounds";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc } from "firebase/firestore";



//google anlytics ticket type
//import { getAnalytics, logEvent } from "firebase/analytics";
//const analytics = getAnalytics();

const tagsStyle = {
  backgroundColor: "white",
  borderRadius: 1,
  opacity: 0.7,
  paddingTop: "2px",
  paddingBottom: "2px",
  paddingLeft: "5px",
  paddingRight: "5px",
};

const Ticket = ({ children, avail, cost, onRestart, onContinue, onAllTickets, title, text, image, id }) => {
  const { showPortrait } = manager.getVersionToShow(useMediaQuery);
  const size = manager.getVersionToShow(useMediaQuery).versionToShow;

  const bottomIconsSize = [35, 35, 45, 45, 45];
  const topIconSize = [35, 35, 55, 55, 55];
  const tagsGap = [5, 5, 5, 5, 5];
  const iconMarginBottom = [70, 70 , 0 , 0 , 0];
  const bottomButtonLabelsLeft = ["", "" , "" , "Restart" , "Restart"];
  const bottomButtonLabelsRight = ["", "" , "" , "Recommended Books" , "Recommended Books"];

  const cornerMargin = manager.SIZES(useMediaQuery).TICKETS_CONTENT_MARGIN;

  var dropdownWidth = "";
  if((manager.getVersionToShow(useMediaQuery).showPortrait)){
    dropdownWidth = "90%";
  }else{
    dropdownWidth = "30%";
  }

  //to tidy
  function dropDownToggle(){  
    if(document.getElementById("socialDropDown").style.height == "0px"){
      document.getElementById("socialDropDown").style.height = "auto"
      document.getElementById("socialDropDown").style.borderWidth = "2px"
    }else{
      document.getElementById("socialDropDown").style.height = "0px"
      document.getElementById("socialDropDown").style.borderWidth = "0px"
    }
  }


  //check if thsi is the first ticket the user arives at
  if((window.localStorage.getItem("isFirstTicket")) == 1){
    window.localStorage.setItem("isFirstTicket", 0);
    window.localStorage.setItem("firstTicketID", id);
  }

  //some title are array, some are single strings, this checks to see which, and converts arrays to strings
  // additonally, some title strings begin with an "&", which twitter and facebook dont allow, so if they are detected, the are repalced with "and"
  function CheckTitle(TitleObject){
    let title = TitleObject;
    let concatTitle = [];
    const regexAmpersand = /^\&.*$/ 

    if(Array.isArray(title)){
      title.forEach(element => {
        if(typeof element === "string"){
          if (regexAmpersand.test(element)){
            concatTitle.push(element.replace("&", "and"));
          }else{
            concatTitle.push(element);
          }
        }
      });
      return concatTitle.join(' ');
    }else{
      return TitleObject;
    }
  }
  
  const TwitterShare = () => {
    const postTitle = CheckTitle(title.props.children); 
    window.open("https://twitter.com/intent/tweet?text=My ticket for the afterlife is " + postTitle + ". You can too explore a range of choices for what to do with your body, memories, and legacies with the DeathPositiveLibraries&hashtags=DeathPositiveLibraries,TicketForTheAfterlife" );
  }

  const FacebookShare = () => {
    let quote = ("My ticket to after life is " + CheckTitle(title.props.children) + " - " + "You can too explore a range of choices for what to do with your body, memories, and legacies with the DeathPositiveLibraries");
    window.open("https://www.facebook.com/dialog/share?app_id=851492678841826&display=popup&href=https://loveafterdeath-5ba5c.web.app/&hashtag=%23DeathPositiveLibraries&redirect_uri=https://loveafterdeath-5ba5c.web.app/&quote=" + quote , ",_blank")
  }

  const DownloadPath = "./downloadImages/ticketdownload" + id + ".jpg";

  //setting ticket vlaues in db
  async function setAnalytics(id){

    let ticket = ("ticket" + id);

    const db = getFirestore();
 
    const docRef = doc(db, "views", ticket);
    const docSnap = await getDoc(docRef);
    
    // Add a new document in collection "cities"
    await setDoc(doc(db, "views", ticket), {
      ticket: 1
    });

    //console.log("id: " + ticket);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
          // Add a new document in collection "cities"
          await setDoc(doc(db, "views", ticket), {
            ticket: (docSnap.data().ticket + 1)
          });
    } else {
      // doc.data() will be undefined in this case
      //console.log("No such document!");
    }

  }
  setAnalytics(id);

  return (
    
    <div className="ticketScreen"
      style={{
        height: "100%",
        position: "relative",
        background: "#282828",
      }}
    >
      <Sounds sounds={"Ticket"}/>
      <div
        id="TicketTag"
        className={showPortrait ? "column" : "row-centered"}
        style={{
          position: "absolute",
          top: `${cornerMargin - 8}px`,
          left: cornerMargin,
        }}
      >
        <div 
          style={{
            ...tagsStyle,
            marginRight: showPortrait ? "0px" : `${tagsGap[size - 1]}px`,
            marginBottom: showPortrait ? `${tagsGap[size - 1]}px` : "0px",
          }}
        >
          <Text>
            {avail === 0
              ? "#CurrentlyUnavailable"
              : avail === 1
              ? "#CurrentlyAvailable"
              : avail === 2
                  ? "#AvailableInJapan"
                  : avail === 3 ? "#AvailableInUS"
              : ""}
          </Text>
        </div>
        {cost !== undefined && (
          <div style={{ ...tagsStyle }}>
            <Text>
              {cost === 1
                ? "#LessThanAverageCost"
                : cost === 2
                ? "#MoreThanAverageCost"
                : cost === 3
                ? "#CostVaries"
                : ""}
            </Text>
          </div>
        )}
      </div>

      <div id="ShareButton"
        className="row-centered"
        style={{
          position: "absolute",
          right: cornerMargin,
          top: `${cornerMargin - 8}px`,
          cursor: "pointer",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
          width: dropdownWidth
        }}
        onClick={dropDownToggle} //open drop down drawer
      >
        <BoldText>share</BoldText>
        <img alt="" src={share} style={{ width: `${topIconSize[size - 1]}px`, marginLeft: "7px" }} />
        <div id="socialDropDown" style={{height: "0px"}}> 
          <div className="socialDropDownHead" >
            <Title style={{fontSize: 25, color:"#282828"}}>Share your Ticket:</Title>
            <img src={ExitIcon}></img>
            <SocialDropDown twitterShare={TwitterShare} facebookShare={FacebookShare} DownloadPath={DownloadPath} title={title}></SocialDropDown>
          </div>
        </div>


      </div>
      <div style={{ height: "100%" }}>{children}</div>
      <div
        className="row-centered"
        style={{
          position: "absolute",
          zIndex: "2",
          bottom: `${cornerMargin - 8}px`,
          left: `${cornerMargin - 5}px`,
          height: `${bottomIconsSize[size - 1]}px`,
          cursor: "pointer",
        }}
        onClick={() => onRestart()}
      >
        <img
          alt=""
          src={restart}
          style={{
            width: `${bottomIconsSize[size - 1]}px`,
            marginRight: "7px",
          }}
        />
        <BoldText>{bottomButtonLabelsLeft[size - 1]}</BoldText>
      </div>
      <div
        className="row-centered"
        id="iconRow"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          zIndex: "1",
          bottom: `${cornerMargin}px`,
          height: `${bottomIconsSize}px`,
          cursor: "pointer",
        }}
      >
        <span className="userTicketBottomButtons" onClick={() => onAllTickets()}>
          <Text style={{textAlign: "center"}}>Browse All Tickets</Text>
          <img
            alt=""
            src={allTickets}
            style={{
              width: `${bottomIconsSize[size - 1]}px`,
              marginLeft: "7px",
            }}
          />
        </span>
        <MyTicketBottomButton currentTicketID={id}/>
      </div>
      <div
        className="row-centered"
        style={{
          position: "absolute",
          zIndex: "2",
          right: `${cornerMargin - 5}px`,
          bottom: `${cornerMargin - 8}px`,
          height: `${bottomIconsSize}px`,
          cursor: "pointer",
        }}
        onClick={() => onContinue()}
      >
        <BoldText>{bottomButtonLabelsRight[size - 1]}</BoldText>
        <img
          alt=""
          src={next}
          style={{
            width: `${bottomIconsSize[size - 1]}px`,
            marginLeft: "7px",
          }}
        />
      </div>
    </div>
  );
};

export default Ticket;
