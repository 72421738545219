import React from "react";

import ScreenTypeC from "../../components/ScreenTypeC";
import { QUESTION_LABELS } from "../../utils/constants";
import Title from "../../typocomps/Title";
import flower from "../../images/flower.jpg";
import PATHS from "../../utils/constants";

const GreenScreen = () => {
  return (
    <ScreenTypeC
      text={
        <Title>
          Do you prefer to have
          <br />
          an eco-friendly burial
          <br />
          or explore an entirely
          <br />
          different green option?
        </Title>
      }
      optionUp={"burial"}
      optionDown={"other"}
      pathUp={PATHS.Burial}
      pathDown={PATHS.Energy}
      image={flower}
      questionLabel={QUESTION_LABELS.LAST}
      sound="Natural"
    />
  );
};

export default GreenScreen;
