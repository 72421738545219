
import React, {useState, useEffect} from "react";
import useSound from 'use-sound';
import {Howl, Howler} from 'howler';

import Explore from "../sounds/Explore.mp3";
import Manual from "../sounds/Manual.mp3";
import Library from "../sounds/Library.mp3";
import AreYouInterested from "../sounds/AreYouInterested.mp3";
import Traditional from "../sounds/Traditional.mp3";
import PastTraditions from "../sounds/PastTraditions.mp3";
import Preserving from "../sounds/Preserving.mp3";
import Objects from "../sounds/Objects.mp3";
import Other from "../sounds/Other.mp3";
import WhatDoYouWish from "../sounds/WhatDoYouWish.mp3";
import LeaveAPart from "../sounds/LeaveAPart.mp3";
import Burial1 from "../sounds/Burial1.mp3";
import Burial2 from "../sounds/Burial2.mp3";
import ModernPractices from "../sounds/ModernPractices.mp3";
import Community from "../sounds/Community.mp3";
import FuneralActivities from "../sounds/FuneralActivities.mp3";
import VirtualSpace from "../sounds/VirtualSpace.mp3";
import Records from "../sounds/Records.mp3";
import MaterialBelongings from "../sounds/MaterialBelongings.mp3";
import RecordsInfo from "../sounds/RecordsInfo.mp3";
import Unusual from "../sounds/Unusual.mp3";
import Transform from "../sounds/Transform.mp3";
import Cremation from "../sounds/Cremation.mp3";
import Nature from "../sounds/Nature.mp3";
import Memory from "../sounds/Memory.mp3";
import Natural from "../sounds/Natural.mp3";
import BurialEnclosure from "../sounds/BurialEnclosure.mp3";
import Technology from "../sounds/Technology.mp3";
import ReversingDeath from "../sounds/ReversingDeath.mp3";
import EmbracingDeath from "../sounds/EmbracingDeath.mp3";
import Preserve from "../sounds/Preserve.mp3";
import Hand from "../sounds/Hand.mp3"; 
import Robots from "../sounds/Robots.mp3";
import WeDont from "../sounds/weDont.mp3";
import Body from "../sounds/Body.mp3";
import Memories from "../sounds/Memories.mp3";
import Yes from "../sounds/yes.mp3";
import No from "../sounds/no.mp3";
import Ticket from "../sounds/Ticket.mp3";
import Books from "../sounds/Books.mp3";
import EndPage from "../sounds/EndPage.mp3";
import AllTickets from "../sounds/AllTickets.mp3";
import StartDemo from "../sounds/StartDemo.mp3";
import StartDemo2 from "../sounds/startDemo2.mp3";
import Wildlife from "../sounds/Wildlife.mp3";
import Transform2 from "../sounds/Transform3.mp3";
import ModernPractices2 from "../sounds/ModernPractices3.mp3";
import EndPage2 from "../sounds/EndPage2.mp3";
import Burial1new from "../sounds/Burial1New.mp3";

const Sounds = (screen) => {

    console.log("sound " + screen.sounds);

    let index = screen.sounds;

    let sounds = {
        "Explore": Explore,
        "Manual": Manual,
        "Library": Library,
        "AreYouInterested": AreYouInterested,
        "Traditional": Traditional,
        "PastTraditions": PastTraditions,
        "Preserving": Preserving,
        "Objects": Objects,
        "Other": Other,
        "WhatDoYouWish": WhatDoYouWish,
        "LeaveAPart": LeaveAPart,
        "Burial1": Burial1,
        "Burial2": Burial2,
        "ModernPractices": ModernPractices,
        "Community": Community,
        "FuneralActivities": FuneralActivities,
        "VirtualSpace": VirtualSpace,
        "Records" : Records,
        "MaterialBelongings": MaterialBelongings,
        "RecordsInfo": RecordsInfo,
        "Unusual": Unusual,
        "Transform": Transform,
        "Cremation": Cremation,
        "Nature": Nature,
        "Memory": Memory,
        "Natural": Natural,
        "BurialEnclosure": BurialEnclosure,
        "Technology": Technology,
        "ReversingDeath": ReversingDeath,
        "EmbracingDeath": EmbracingDeath,
        "Preserve": Preserve,
        "Hand": Hand,
        "Robots": Robots,
        "WeDont": WeDont,
        "Body": Body,
        "Memories": Memories,
        "Yes": Yes,
        "No": No,
        "Ticket": Ticket,
        "Books" : Books,
        "EndPage" : EndPage,
        "AllTickets" : AllTickets,
        "StartDemo" : StartDemo,
        "StartDemo2" : StartDemo2,
        "Wildlife" : Wildlife,
        "Transform2" : Transform2,
        "ModernPractices2" : ModernPractices2,
        "EndPage2" : EndPage2,
        "Burial1new" : Burial1new,
    }



    const [playSound, {stop}] = useSound(sounds[index]);
    //playSound(); 

    const sound = new Howl({
        src: [sounds[index]]
    });

    if(screen.sounds == "StartDemo"){
        console.log('playing INTRO');
        sound.play();
    }else{
        sound.play();
    }

    //sound.play();
    console.log('playing');

    sound.on('end', function(){
        console.log('Finished!');
    });
    
    useEffect(() => {
        if(screen.sounds == "StartDemo"){
            return function cleanup() {
                console.log('START PAGE SOUND FADING');
                sound.fade(1, 0, 1000);
            };
        }else{
            return function cleanup() {
                console.log('SOUND.STOP');
                sound.fade(0.5, 0, 1000);
                //sound.stop();
            };
        }
    });
 
    return null;
}

  export default Sounds;