import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import TicketContent from "./TicketContent";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketType6 = ({ 
    id, 
    avail, 
    cost, 
    title, 
    text, 
    image, 
    /*imageW,*/ 
    imageRatio, 
    imageSide, 
    onRestart, 
    onContinue, 
    onAllTickets 
  }) => {
  const SECTIONS = ["20%", "25%", "35%", "20%"];

  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.LANDSCAPE[versionToShow - 1][id];

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      title={title}
      text={text}
      image={image}
      id={id}
      title={title}
    >
      <TicketContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            height: "100%",
            width: SECTIONS[1],
            left: SECTIONS[0],
          }}
        >
          {title}
        </div>
        <div
          style={{
            position: "relative",
            width: SECTIONS[2],
            height: "100%",
            left: SECTIONS[0],
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: imageSide === 2 ? "flex-end" : "flex-start",
            }}
          >
            <Image
              alt=""
              src={image}
              style={{
                height: `${IMAGE_H}px`,
                width: `${IMAGE_W}px`,
                marginBottom: "10%",
              }}
            />
          </div>
          <div style={{ width: "100%" }}>{text}</div>
        </div>
      </TicketContent>
    </Ticket>
  );
};

export default TicketType6;
