import React from "react";
import superagent from "superagent";
import ReactDOM from 'react-dom'

const clientID = "8e31e45f4a0e8959d456ba2914723451b8262337f75bcea2e04ae535491df16d";

const { useState, useEffect, useRef } = React;

const simpleGet = options => {
    superagent.get(options.url).then(function(res) {
        if (options.onSuccess) options.onSuccess(res);
    });
};

const UnsplashSearch = () => {
    let [photos, setPhotos] = useState([]);
    let [query, setQuery] = useState("");
    const queryInput = useRef(null);

    const numberOfPhotos = 10;
    const url = "https://api.unsplash.com/photos/random/?count=" +
    numberOfPhotos + "&client_id=" + clientID;

    useEffect(() => {
        const photosUrl = query ? `${url}&query=${query}` : url;

        simpleGet({
            url: photosUrl,
            onSuccess: res => {
                setPhotos(res.body);
            }
            });
    }, [query, url]);

    const searchPhotos = e => {
        e.preventDefault();
        setQuery(queryInput.current.value);
    };

    const logImageUrl = (e) => {
        console.log(e.nativeEvent.srcElement.currentSrc);
        document.getElementById("FileUpload").value = null; //clear file upload
        document.getElementById("TicketImagePreview").src = e.nativeEvent.srcElement.currentSrc;
        
    }

    return (
        <div className="box">
        <form
            id="unsplash-search"
            className="unsplash-search form"
            //onSubmit={searchPhotos}
        >
            <label>
            Search Photos
            <input
                ref={queryInput}
                placeholder=""
                type="search"
                className="input"
                defaultValue=""
            />
            <button onClick={searchPhotos} >Search</button>
            </label>

        </form>

        <ul className="photo-grid">
            {photos.map(photo => {
            return (
                <li key={photo.id} onClick={logImageUrl}>
                <img
                    src={photo.urls.regular}
                    onSuccessfulClipboardCopy={() => {
                    // showUserMessage();
                    // pingUnsplash(photo.links.download_location);
                    }}
                />
                </li>
            );
            })}
        </ul>
        </div>
    );
};

ReactDOM.render(<UnsplashSearch />, document.getElementById("root"));

export default UnsplashSearch;
