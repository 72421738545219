import React from "react";

import ScreenTypeC from "../../components/ScreenTypeC";
import { QUESTION_LABELS } from "../../utils/constants";
import Title from "../../typocomps/Title";
import hand from "../../images/hand.jpg";
import PATHS from "../../utils/constants";

const RobotsScreen = () => {
  return (
    <ScreenTypeC
      text={
        <Title>
          Do you have an
          <br />
          interest in robots
          <br />
          and machinery?
        </Title>
      }
      optionUp={"yes"}
      optionDown={"no"}
      pathUp={PATHS.Manual}
      pathDown={PATHS.Void}
      image={hand}
      questionLabel={QUESTION_LABELS.ONEMORE}
      sound="Hand"
    />
  );
};

export default RobotsScreen;
