import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import * as manager from "../../src/utils/manager";
import PATHS, { LIBRARIES } from "../utils/constants";
import Title from "../typocomps/Title";
import Text from "../typocomps/Text";
import BoldLabel from "../typocomps/BoldLabel";
import Image from "../components/Image";

import facebook from "../images/icons/facebook.png";
import twitter from "../images/icons/twitter.png";
import instagram from "../images/icons/instagram.png";
import SocialLinks from "../components/SocialLinks";
import SocialLinksPortrait from "../components/SocialLinksPortrait";
import SurveyLink from "../components/surveyLink";
import Sounds from "../components/Sounds";

const ThankYouScreen = ({ history }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 80
      : versionToShow === 2
      ? 80
      : versionToShow === 3
      ? 120
      : versionToShow === 4
      ? 180
      : 120;
  const imageH = 1.0 * imageW;

  const goNext = () => {
    history.push(PATHS.Screen1);
  };

  function RestartQuiz() {
    console.log("restart");
    const library = JSON.parse(window.localStorage.getItem("library"));
    console.log(library);
    if(library < 3){
      console.log("on online");
      console.log(library);
      window.localStorage.setItem("library", 0);
      history.push(PATHS.Libraries);
    }else{
      console.log("on local");
      console.log(library);
      if(document.getElementById("passcode").value == "1234"){
        window.localStorage.setItem("library", 0);
        history.push(PATHS.Libraries);
      }else if(document.getElementById("passcode").value == ""){
        alert("Please Enter Passcode")
      }else{
        alert("Incorrect Passcode")
      }
    }
  }

  const getLibraryName = (id) => {

    if(id == 0){
      return("Guest user"); 
    }else if(id == 1){
      return("Newcastle Online");
    }else if(id == 2){
      return("Kirklees Online");
    }else if(id == 3){
      return("Redbridge Online");
    }else if(id == 4){
      return("Newcastle Onsite");
    }else if(id == 5){
      return("Kirklees Onsite");
    }else if(id == 6){
      return("Redbridge Onsite");
    }else{
      return("No Library chosen, please reset");
    }

  }


  const landscapeComp = (
    <div className="centered-content">
      <Sounds sounds={"EndPage2"} />
      <div>
        <div className="center-in-a-row" style={{ marginBottom: "2%", width: "40%"}}>
          <Title style={{ fontSize: 42}}>Thank you for taking part in Tickets for the Afterlife!</Title>
        </div>
        <SocialLinks  />
        <div style={{ width: "65%"}}>
          <Text style={{ marginBottom: "2%"}}>Tickets for the Afterlife aims to introduce people to planning end of life wishes. Through visual communication, interaction and personal decision making. It uses artistic research methods that position diverse rituals and potential futures as options to be chosen within the digital experience/ website. It also uses the library's collections of books as a way of giving people personalised recommendations for further inquiry.</Text>
          <Text style={{ marginBottom: "2%"}}>The Tickets for the Afterlife website was creatively directed by Dr Stacey Pitsillides with graphic designer Elena Demireva, web developer’s Parvin Asadzadeh Birjandi & Tom Hegarty and sound designer Emma Margetson. Content research and co-design with Dr Claire Nally and the Death Positive Library team. Together this team have investigated the role of Death Positive Libraries during the pandemic through author/Filmmaker Q&A’s, death cafes and A Grief Spoon Room with the Loss Project. This project has been funded by the Wellcome Trust, Carnegie UK, and The Wolfson Foundation and has recently been awarded the Health and Wellbeing Award from Libraries Connected, an organisation which promotes and represents libraries as important resources at the heart of communities. </Text>
        </div>
        <div style={{display: "flex", marginBottom: "4%",marginTop: "2%"}}>
          <Link to={PATHS.AllTickets} className="no-decoration">
            <BoldLabel>
              View All Tickets |{'\u00A0'}
            </BoldLabel>
          </Link>
          <Link to={PATHS.CreateTicket} className="no-decoration">
            <BoldLabel>
              Create A Ticket |{'\u00A0'}
            </BoldLabel>
          </Link>
          <Link to={PATHS.Screen1} className="no-decoration">
            <BoldLabel>
              Restart{'\u00A0'}
            </BoldLabel>
          </Link>
        </div>
        <div id="thanksButtonRow" style={{ width: "65%"}}>
          <div style={{width: "30%"}}>
            <details className="ThanksButton">
              <summary style={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center"}}>Death Positive Library</summary> 
              <Text style={{ marginBottom: "2%", marginTop: "4%"}}><b>The Death Positive Library</b> grew out of a series of collaborations. It initially began in Redbridge Library, with the ‘The Final Party’, which included collaborations with funeral directors, creatives, and academics. One of the installations commissioned was ‘Love After Death’ (www.loveafterdeath.co.uk) which brought forth key questions about the role of design and speculation within libraries. This developed into a cross-community collaboration between Redbridge Library, Kirklees Library and Newcastle City Library with academics from Northumbria University. The Death Positive Library approaches death and dying as a health and societal issue but seeks to engage the wider public in the concept of libraries as death positive spaces and their collections of books as creative resources that push us to consider our own mortality. </Text>
            </details>
          </div>
          <div style={{width: "30%"}}>
            <details className="ThanksButton">
              <summary style={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center"}}>Full Credits</summary> 
              <Text style={{ marginBottom: "2%", marginTop: "4%"}}><b>Concept &#38; Direction:</b> Dr Stacey Pitsillides <br /> <b>Content Research:</b> Dr Claire Nally  <br /> <b>Graphic &#38; UX Design:</b> Elena Demireva <br />  <b>Full Stack Developers:</b> Parvin Asadzadeh Birjandi &#38; Tom Hegarty <br /> <b>Sound Designer:</b> Dr Emma Margetson <br /> <b>Library Direction and Co-Design Team:</b> Anita Luby &#38; Rhonda Brooks: Vision RCL, Redbridge Central Library/ Fiona Hill &#38; Joanne Ghee: City Library, Newcastle / Katherine Hornby &#38; Judith Robsinson: Kirklees Libraries </Text>
            </details>
          </div>
          <div style={{width: "30%"}}>
            <SurveyLink />
          </div>
        </div>

        <details style={{position: "absolute",marginTop: "2%"}}>
              <summary>Library Admin</summary>
              <label for="passcode">passcode:</label>
              <input type="password" id="passcode" name="passcode"></input><br />
              <p>current location: {getLibraryName(JSON.parse(window.localStorage.getItem("library")))}</p>
              <Link onClick={RestartQuiz} className="no-decoration"><button> Change Location {'\u00A0'} </button></Link>
              <Link onClick={RestartQuiz} className="no-decoration"><button> Restart {'\u00A0'} </button></Link>
        </details>

      </div>
    </div>
  );

  const portraitComp = (
    <div className="centered-content">
    <Sounds sounds={"EndPage2"} />
      <div>
        <div class="sideBloack" style={{ position: "absolute", width: "13px", height: "75px", marginTop: "45px", background: "#282828" }}>
        </div>
        <Title style={{ fontSize: 50, width: "85%", margin: "auto", marginBottom: "0%"}}>thank you</Title>
        <div style={{ width: "85%", margin: "auto", marginBottom: "5%",}}>
          <Text style={{ marginBottom: "2%"}}>Tickets for the Afterlife aims to introduce people to planning end of life wishes. Through visual communication, interaction and personal decision making. It uses artistic research methods that position diverse rituals and potential futures as options to be chosen within the digital experience.</Text>
        </div>

        <div style={{width: "85%", margin: "auto", display: "flex", marginBottom: "5%",}}>
          <Link to={PATHS.AllTickets} className="no-decoration">
            <BoldLabel style={{fontSize: 16}}>
              View All Tickets |{'\u00A0'}
            </BoldLabel>
          </Link>
          <Link to={PATHS.CreateTicket} className="no-decoration">
            <BoldLabel style={{fontSize: 16}}>
              Create A Ticket |{'\u00A0'}
            </BoldLabel>
          </Link>
          <Link to={PATHS.Screen1} className="no-decoration">
            <BoldLabel style={{fontSize: 16}}>
              Restart {'\u00A0'}
            </BoldLabel>
          </Link>
        </div>
        <SocialLinksPortrait />
        <div style={{ fontSize: 13, width: "85%", margin: "auto", marginBottom: "5%",}}>
          <div style={{width: "100%", marginTop: "2%"}}>
            <SurveyLink />
          </div>
          <div style={{width: "100%", marginTop: "2%"}}>
            <details className="ThanksButton" style={{listStyle: "none"}}>
              <summary style={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center"}}>Death Positive Library</summary> 
              <Text style={{ marginBottom: "2%", marginTop: "4%"}}><b>The Death Positive Library</b> grew out of a series of collaborations. It initially began in Redbridge Library, with the ‘The Final Party’, which included collaborations with funeral directors, creatives, and academics. One of the installations commissioned was ‘Love After Death’ (www.loveafterdeath.co.uk) which brought forth key questions about the role of design and speculation within libraries. This developed into a cross-community collaboration between Redbridge Library, Kirklees Library and Newcastle City Library with academics from Northumbria University. The Death Positive Library approaches death and dying as a health and societal issue but seeks to engage the wider public in the concept of libraries as death positive spaces and their collections of books as creative resources that push us to consider our own mortality. </Text>
               </details>
          </div>
          <div style={{width: "100%", marginTop: "2%"}}>
            <details className="ThanksButton" style={{listStyle: "none"}}>
              <summary style={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center"}}>Tickets for the Afterlife</summary> 
              <Text style={{ marginBottom: "2%"}}>This experience was creatively directed by Dr Stacey Pitsillides with graphic designer Elena Demireva, web developer’s Parvin Asadzadeh Birjandi & Tom Hegarty and sound designer Emma Margetson. Content research and co-design with Dr Claire Nally and the Death Positive Library team. Together this team have investigated the role of Death Positive Libraries during the pandemic through author/Filmmaker Q&A’s, death cafes and A Grief Spoon Room with the Loss Project. This project has been funded by the Wellcome Trust, Carnegie UK, and The Wolfson Foundation and has recently been awarded the Health and Wellbeing Award from Libraries Connected, an organisation which promotes and represents libraries as important resources at the heart of communities </Text>
            </details>
          </div>
          <div style={{width: "100%", marginTop: "2%"}}>
            <details className="ThanksButton" style={{listStyle: "none"}}>
              <summary style={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", listStyle: "none"}}>Full Credits</summary> 
              <Text style={{ marginBottom: "2%", marginTop: "4%"}}><b>Concept &#38; Direction:</b> Dr Stacey Pitsillides <br /> <b>Content Research:</b> Dr Claire Nally  <br /> <b>Graphic &#38; UX Design:</b> Elena Demireva <br />  <b>Full Stack Developers:</b> Parvin Asadzadeh Birjandi &#38; Tom Hegarty <br /> <b>Sound Designer:</b> Dr Emma Margetson <br /> <b>Library Direction and Co-Design Team:</b> Anita Luby &#38; Rhonda Brooks: Vision RCL, Redbridge Central Library/ Fiona Hill &#38; Joanne Ghee: City Library, Newcastle / Katherine Hornby &#38; Judith Robsinson: Kirklees Libraries </Text>
            </details>
          </div>
        </div>


      </div>
    </div>
  );

  return showPortrait ? portraitComp : landscapeComp;
};

export default ThankYouScreen;
