

const ticketImages =  {
    1:  require("../images/photography.jpg").default,
    2:  require("../images/tickets/ticket2.jpg").default,
    3:  require("../images/tickets/ticket3.jpg").default,
    4:  require("../images/tickets/ticket4.jpg").default,
    5:  require("../images/tickets/ticket5.jpg").default,
    6:  require("../images/tickets/ticket6.jpg").default,
    7:  require("../images/community.jpg").default,
    8:  require("../images/tickets/ticket8.jpg").default,
    9:  require("../images/tickets/ticket9.jpg").default,
    10:  require("../images/tickets/ticket10.jpg").default,

    11:  require("../images/tickets/ticket11.jpg").default,
    12:  require("../images/tickets/ticket12.jpg").default,
    13:  require("../images/tickets/ticket13.jpg").default,
    14:  require("../images/tickets/ticket14.jpg").default,
    15:  require("../images/tickets/ticket15.jpg").default,
    16:  require("../images/tickets/ticket16.jpg").default,
    17:  require("../images/tickets/ticket17.jpg").default,
    18:  require("../images/tickets/ticket18.jpg").default,
    19:  require("../images/tickets/ticket19.jpg").default,
    20:  require("../images/tickets/ticket20.jpg").default,

    21:  require("../images/online.jpg").default,
    22:  require("../images/tickets/ticket22.jpg").default,
    23:  require("../images/tickets/ticket23.jpg").default,
    24:  require("../images/body.jpg").default,
    25:  require("../images/tickets/ticket25.jpg").default,
    26:  require("../images/tickets/ticket26.jpg").default,
    27:  require("../images/tickets/ticket27.jpg").default,
    28:  require("../images/tickets/ticket28.jpg").default,
    29:  require("../images/scripted.jpg").default,
    30:  require("../images/tickets/ticket30.jpg").default,

    31:  require("../images/tickets/ticket31.jpg").default,
    32:  require("../images/tickets/ticket32.jpg").default,
    33:  require("../images/tickets/ticket33.jpg").default,
    34:  require("../images/tickets/ticket34.jpg").default,
    35:  require("../images/tickets/ticket35.jpg").default,
    36:  require("../images/tickets/ticket36.jpg").default,
    37:  require("../images/tickets/ticket37.jpg").default,
    38:  require("../images/tickets/ticket38.jpg").default,
    39:  require("../images/tickets/ticket39.jpg").default,
    40:  require("../images/tickets/ticket40.jpg").default,

    41:  require("../images/tickets/ticket41.jpg").default,
    42:  require("../images/tickets/ticket42.jpg").default,
    43:  require("../images/tickets/ticket43.jpg").default,
    44:  require("../images/tickets/ticket44.jpg").default,
    45:  require("../images/tickets/ticket45.jpg").default,
    46:  require("../images/tickets/ticket46.jpg").default,
    47:  require("../images/tickets/ticket47.jpg").default,
    48:  require("../images/tickets/ticket48.jpg").default,
    49:  require("../images/tickets/ticket49.jpg").default,
    50:  require("../images/tickets/ticket50.jpg").default,

}

export default ticketImages;