import React from "react";

import { QUESTION_LABELS } from "../../utils/constants";
import ChooseScreen from "../../components/ChooseScreen";
import community from "../../images/community.jpg";
import individual from "../../images/individual.jpg";
import nature from "../../images/nature.jpg";
import PATHS from "../../utils/constants";

const CommunityScreen = () => {
  return (
    <ChooseScreen
      optionsData={{
        option1: {
          label: "community",
          image: community,
          path: PATHS.Tickets + "7",
        },

        option2: {
          label: "individual",
          image: individual,
          path: PATHS.Tickets + "8",
        },
        option3: {
          label: "nature",
          image: nature,
          path: PATHS.Tickets + "9",
        },
      }}
      questionLabel={QUESTION_LABELS.LAST}
      rotateImagesForPortrait={false}
      imageTypeSmall={true}
      sound="Burial1New"
    />
  );
};

export default CommunityScreen;
