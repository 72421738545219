import React from "react";

import CoupledChoices from "../../components/CoupledChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import wildlife from "../../images/wildlife.jpg";
import woodland from "../../images/woodland.jpg";
import PATHS from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";

const WildlifeScreen = () => {
  return (
    <CoupledChoices
      labelUp={
        <BoldLabel>
          preserving
          <br />
          natural wildlife
          <br />
          habitat
        </BoldLabel>
      }
      labelDown={
        <BoldLabel>
          designated
          <br />
          woodland
          <br />
          burial site
        </BoldLabel>
      }
      imageUp={wildlife}
      imageDown={woodland}
      pathUp={PATHS.Tickets + "41"}
      pathDown={PATHS.Tickets + "40"}
      questionLabel={QUESTION_LABELS.LAST}
      sound="Wildlife"
    />
  );
};

export default WildlifeScreen;
