import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import TicketContent from "./TicketContent";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketType7 = ({
  id,
  avail,
  cost,
  title,
  text,
  textv2,
  image,
  //imageW,
  imageRatio,
  titleLevel,
  imageLeft,
  imageTop,
  gap,
  titlev2,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const SECTIONS = ["0%", "28%", "72%", "0%"];

  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.LANDSCAPE[versionToShow - 1][id];

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  const tmpText = versionToShow <= 2 && textv2 ? textv2 : text;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      text={text}
      image={image}
      id={id}
      title={title}
    >
      <TicketContent>
        <div
          style={{
            position: "relative",
            left: SECTIONS[0],
            width: SECTIONS[1],
          }}
        >
          <img
            alt=""
            src={image}
            style={{ height: `${IMAGE_H}px`, width: `${IMAGE_W}px` }}
          />
          <div
            style={{
              marginTop: "5%",
            }}
          >
            {titlev2 ? titlev2 : title}
          </div>
        </div>
        <div
          style={{
            position: "relative",
            left: SECTIONS[0],
            width: SECTIONS[2],
          }}
        >
          <div>{tmpText}</div>
        </div>
      </TicketContent>
    </Ticket>
  );
};

export default TicketType7;
