import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import glasses from "../../images/glasses.jpg";
import lineD from "../../images/lines/lineD2pt.png";
import PATHS from "../../utils/constants";

const AfterlifeScreen = () => {
  return (
    <WiredChoices
      labelUp={<BoldLabel>for the afterlife</BoldLabel>}
      labelDown={<BoldLabel>for the mourning</BoldLabel>}
      pathUp={PATHS.Wisdom}
      pathDown={PATHS.Leave}
      image={glasses}
      line={lineD}
      questionLabel={QUESTION_LABELS.ONEMORE}
      labelNumlines={1}
      sound="Objects"
    />
  );
};

export default AfterlifeScreen;
