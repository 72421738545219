import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketTypePortrait1 = ({
  id,
  avail,
  cost,
  title,
  text,
  image,
  imageRatio,
  psTitle,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.PORTRAIT[versionToShow - 1][id];

  const {
    WIDHT: W,
    HEIGHT: H,
    TICKETS_CONTENT_MARGIN: margin,
  } = manager.SIZES(useMediaQuery);

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      title={title}
      id={id}
    >
      <div
        style={{
          position: "relative",
          top: "20%",
          paddingLeft: `${margin}px`,
          paddingRight: `${margin}px`,
        }}
      >
        <div className="row">
          <Image
            alt=""
            src={image}
            style={{
              width: `${IMAGE_W}px`,
              height: `${IMAGE_H}px`,
              objectFit: "contain",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginLeft: "3%",
            }}
          >
            {psTitle ? psTitle : title}
          </div>
        </div>
        <div
          style={{
            paddingTop: "5%",
          }}
        >
          {text}
        </div>
      </div>
    </Ticket>
  );
};

export default TicketTypePortrait1;
