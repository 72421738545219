import React, { useState } from "react";
import "./App.css";
import { Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PATHS from "./utils/constants";
import ConventionalScreen from "./screens/typeA/ConventionalScreen";
import TraditionsScreen from "./screens/TraditionsScreen";
import PhotographyScreen from "./screens/choose/PhotographyScreen";
import PreservingScreen from "./screens/wired/PreservingScreen";
import AfterlifeScreen from "./screens/wired/AfterlifeScreen";
import WisdomScreen from "./screens/typeB/WisdomScreen";
import LeaveScreen from "./screens/wired/LeaveScreen";
import FuneraryScreen from "./screens/choose/FuneraryScreen";
import CommunityScreen from "./screens/choose/CommunityScreen";
import ElaborateScreen from "./screens/typeD/ElaborateScreen";
import TicketScreen from "./screens/TicketScreen";
import SharingScreen from "./screens/typeB/SharingScreen";
import GamesScreen from "./screens/typeC/GamesScreen";
import MaterialScreen from "./screens/wired/MaterialScreen";
import FamilyScreen from "./screens/FamilyScreen";
import SocialScreen from "./screens/choose/SocialScreen";
import TransformScreen from "./screens/typeA/TransformScreen";
import TechnologyScreen from "./screens/choose/TechnologyScreen";
import RobotsScreen from "./screens/typeC/RobotsScreen";
import ManualScreen from "./screens/wired/ManualScreen";
import VoidScreen from "./screens/VoidScreen";
import AgingScreen from "./screens/wired/AgingScreen";
import MessagesScreen from "./screens/typeC/MessagesScreen";
import ScriptedScreen from "./screens/wired/ScriptedScreen";
import ExperienceScreen from "./screens/typeD/ExperienceScreen";
import CremationScreen from "./screens/choose/CremationScreen";
import BecomeScreen from "./screens/typeB/BecomeScreen";
import SeaScreen from "./screens/wired/SeaScreen";
import StoneScreen from "./screens/wired/StoneScreen";
import GreenScreen from "./screens/typeC/GreenScreen";
import BurialScreen from "./screens/wired/BurialScreen";
import WildlifeScreen from "./screens/coupled/WildlifeScreen";
import BioScreen from "./screens/coupled/BioScreen";
import EnergyScreen from "./screens/choose/EnergyScreen";
import ReversingScreen from "./screens/wired/ReversingScreen";
import BodyScreen from "./screens/coupled/BodyScreen";
import CreatingScreen from "./screens/coupled/CreatingScreen";
import BooksScreen from "./screens/BooksScreen";
import BookSingle from "./screens/BookSingle";
import LibrarySelectionScreen from "./screens/LibrarySelectionScreen";
import IntroductionScreen from "./screens/IntroductionScreen";
import StartScreen from "./screens/StartScreen";
import BooksIntroScreen from "./screens/BooksIntroScreen";
import ThankYouScreen from "./screens/ThankYouScreen";
import AllTicketsScreen from "./screens/AllTicketsScreen";
import BooksTest from "./screens/BooksLandscape";
import AppFrame from "./components/AppFrame";
import LibraryContext from "./screens/context";
import Screen1 from "./screens/Screen1";
import Analytics from "./screens/analytics";
import CreateTicket from "./screens/CreateTicket";
import UserTicketScreen from "./screens/UserTicketScreen";
import ModerateUserTickets from "./screens/ModerateUserTickets";

import { useIdleTimer } from "react-idle-timer";


const routes = [
  { path: PATHS.Analytics, Component: Analytics },
  { path: PATHS.CreateTicket, Component: CreateTicket },
  { path: PATHS.ModerateUserTickets, Component: ModerateUserTickets},
  { path: PATHS.Books, Component: BooksTest },
  { path: PATHS.Traditions, Component: TraditionsScreen },
  { path: PATHS.Photography, Component: PhotographyScreen },
  { path: PATHS.Preserving, Component: PreservingScreen },
  { path: PATHS.Afterlife, Component: AfterlifeScreen },
  { path: PATHS.Wisdom, Component: WisdomScreen },
  { path: PATHS.Leave, Component: LeaveScreen },
  { path: PATHS.Funerary, Component: FuneraryScreen },
  { path: PATHS.Community, Component: CommunityScreen },
  { path: PATHS.Conventional, Component: ConventionalScreen },
  { path: PATHS.Elaborate, Component: ElaborateScreen },
  { path: PATHS.Sharing, Component: SharingScreen },
  { path: PATHS.Games, Component: GamesScreen },
  { path: PATHS.Material, Component: MaterialScreen },
  { path: PATHS.Family, Component: FamilyScreen },
  { path: PATHS.Social, Component: SocialScreen },
  { path: PATHS.Transform, Component: TransformScreen },
  { path: PATHS.Technology, Component: TechnologyScreen },
  { path: PATHS.Robots, Component: RobotsScreen },
  { path: PATHS.Manual, Component: ManualScreen },
  { path: PATHS.Void, Component: VoidScreen },
  { path: PATHS.Aging, Component: AgingScreen },
  { path: PATHS.Messages, Component: MessagesScreen },
  { path: PATHS.Scripted, Component: ScriptedScreen },
  { path: PATHS.Experience, Component: ExperienceScreen },
  { path: PATHS.Cremation, Component: CremationScreen },
  { path: PATHS.Become, Component: BecomeScreen },
  { path: PATHS.Sea, Component: SeaScreen },
  { path: PATHS.Stone, Component: StoneScreen },
  { path: PATHS.Green, Component: GreenScreen },
  { path: PATHS.Burial, Component: BurialScreen },
  { path: PATHS.Wildlife, Component: WildlifeScreen },
  { path: PATHS.Bio, Component: BioScreen },
  { path: PATHS.Energy, Component: EnergyScreen },
  { path: PATHS.Reversing, Component: ReversingScreen },
  { path: PATHS.Body, Component: BodyScreen },
  { path: PATHS.Creating, Component: CreatingScreen },
  { path: PATHS.Tickets + ":id", Component: TicketScreen }, // id: ticket id
  { path: PATHS.UserTickets + ":id", Component: UserTicketScreen }, // id: ticket id
  { path: PATHS.BooksIntro + ":id", Component: BooksIntroScreen },
  { path: PATHS.Books + ":id", Component: BooksScreen },
  { path: PATHS.Book + ":bid", Component: BookSingle }, // bid: book id
  //{ path: "/", Component: Start },
  { path: PATHS.Libraries, Component: LibrarySelectionScreen },
  { path: PATHS.Introduction, Component: IntroductionScreen },
  { path: PATHS.Thanks, Component: ThankYouScreen },
  { path: PATHS.Start, Component: StartScreen },
  { path: PATHS.AllTickets, Component: AllTicketsScreen },
  { path: "/", Component: Screen1 },
];

function App({ history }) {
  const [library, setLibrary] = useState();

  let currentPathname = "";
  let currentSearch = "";

  history.listen((newLocation, action) => {
    if (action === "PUSH") {
      if (
        newLocation.pathname !== currentPathname ||
        newLocation.search !== currentSearch
      ) {
        // Save new location
        currentPathname = newLocation.pathname;
        currentSearch = newLocation.search;

        // Clone location object and push it to history
        history.push({
          pathname: newLocation.pathname,
          search: newLocation.search,
        });
      }
    } else {
      history.go(1); 
    }

    //set background colors if screen is a ticket
    if(currentPathname.includes("ticket-")){
      document.body.style.background = "#282828";
      document.documentElement.style.background = "#282828";
    }else{
      document.body.style.background = "#DCDAD4";
      document.documentElement.style.background = "#DCDAD4";
    }
  });

  //idle timer
  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime())
    if(window.location.pathname !== "/"){
      window.location.assign('https://afterlifetickets.co.uk/');
    }
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    console.log('user did something', event)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })
  

  return (
    <LibraryContext.Provider value={{ library, setLibrary }}>
      <AnimatePresence >
        <AppFrame>
            <div style={{ position: "relative", height: "100%" }}>
            {routes.map(({ path, Component }) => (
                <Route  key={path} exact path={path} component={Component} />  
              ))}
            </div>
        </AppFrame>
      </AnimatePresence>
    </LibraryContext.Provider> 
  );
}

export default App;
