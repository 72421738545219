import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import * as manager from "../../src/utils/manager";
import PATHS from "../utils/constants";
import Image from "../components/Image";
import HugeTitle from "../typocomps/HugeTitle";
import Title from "../typocomps/Title";
import Text from "../typocomps/Text";
import next from "../images/icons/next.png";

import Tree from "../images/tickets/ticket37.jpg";
import Sockets from "../images/sockets.jpg";
import Curtain from "../images/curtain.jpg";
import Back from "../images/tickets/ticket38.jpg"
import CursorVideo from "../images/landscape-cursor.gif"
import Sounds from "../components/Sounds";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc } from "firebase/firestore";


import { motion } from "framer-motion"

const StartScreen = ({ history }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 90
      : versionToShow === 2
      ? 100
      : versionToShow === 3
      ? 140
      : versionToShow === 4
      ? 140
      : 120;
  const imageH = 0.5 * imageW;

  const enterW =
    versionToShow === 1
      ? 20
      : versionToShow === 2
      ? 20
      : versionToShow === 3
      ? 20
      : versionToShow === 4
      ? 20
      : 20;
  const enterH = 0.5 * enterW;

  const fsize =
    versionToShow === 1
      ? 20
      : versionToShow === 2
      ? 24
      : versionToShow === 3
      ? 30
      : versionToShow === 4
      ? 30
      : 30;

  const radius =
    versionToShow === 1
      ? 5
      : versionToShow === 2
      ? 5
      : versionToShow === 3
      ? 10
      : versionToShow === 4
      ? 10
      : 10;

  const gaps =
    versionToShow === 1
      ? 7
      : versionToShow === 2
      ? 5
      : versionToShow === 3
      ? 10
      : versionToShow === 4
      ? 10
      : 10;

  const goNext = () => {
    //history.push(PATHS.Conventional);
    history.push(PATHS.Libraries);
    window.localStorage.removeItem("ticket");
  };
 
  const displayDisclaimer = () => {
    let element = document.getElementById("disclaimerMessage");
    let button = document.getElementById("disclaimerButton")
    let MessageDisplay = document.getElementById("disclaimerMessage").style.display;
    
    if(MessageDisplay == "block"){
      element.style.display = "none";
      button.innerHTML = "Show Disclaimer";
    }else{
      element.style.display = "block";
      button.innerHTML = "Hide Disclaimer";
    }
  }

  //increment total views by 1
  async function setAnalytics(){


    const db = getFirestore();
 
    const docRef = doc(db, "users", "views");
    const docSnap = await getDoc(docRef);
    

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
          // Add a new document in collection "cities"
          await setDoc(doc(db, "users", "views"), {
            views: (docSnap.data().views + 1)
          });
          console.log("counted view - StartScreen");
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document! - StartScreen");
    }
    

  }
  setAnalytics();


  

  const landscapeComp = (
    <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
    <Sounds sounds={"Manual"}/>
    <div className="centered-content"  style={{ transform: "scale(0.8)"}}>
      <div style={{ display: "flex", flexWrap: "wrap", marginRight: "50px", width: "60%"}}> 
        <div style={{width: "100%"}}>
          <HugeTitle style={{ fontSize: 180, marginBottom: "0%"}}>manual.</HugeTitle>
          <div className="videoContainer" style={{}}>
            <img src={CursorVideo} />
          </div>
          <Text style={{ marginBottom: "2%", marginTop: "2%" }}>On each page there will be a question that leads you to your ticket for the afterlife, your ticket then links to a series of book recommendations for further discovery.</Text>
          <Text style={{ marginBottom: "2%", marginTop: "2%" }}><b>Sound</b> is used throughout this experience, please ensure that your devices sound is turned on.</Text>
          <div style={{ width: "100%", backgroundColor: "#28282d", height: "2px", marginBottom: "2%"  }}></div>
          <div className="disclaimerOuter">
            <button id="disclaimerButton" onClick={displayDisclaimer}>Show Disclaimer</button>
            <div id="disclaimerMessage" style={{display: "none"}}>
              <p style={{ marginBottom: "1%"}}><b>Disclaimer:</b> While the Death Positive Library services strives to make the information on this site is as timely and accurate as possible, they make no claims, promises, or guarantees about accuracy, completeness, or adequacy of the contents of this site, and expressly disclaims liability foe errors and commissions in the contents of this site. </p>
              <p style={{ marginBottom: "1%"}}>The Death Positive library do not make any claim about the practical use of this resources but rather see it as a creative tool to encourage viewers to consider their options and seek information from other sources for example funeral directors, lawyers, hospice staff et al. Where future options are included in the collection or recommended as an outcome of taking the quiz, they will be clearly labelled and are included to elicit prompt critical thinking and awareness of services and products that may be available in future.  </p>           </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <Image
            alt=""
            src={Tree}
            style={{
              //1319 x 413
              height: `133px`,
              width: `422px`,
              paddingLeft: "20px",
              marginTop: "70px"
            }}
          />
        </div>
        <div>
          <Image
            alt=""
            src={Sockets}
            style={{
              //541 x 817
              height: `204px`,
              width: `135px`,
              padding: "10px",
              marginLeft: "50%",
              marginTop: "10%"
            }}
          />
        </div>
        <div>
          <Image
            alt=""
            src={Curtain}
            style={{
              //594 x 594
              height: `110px`,
              width: `110px`,
              padding: "10px",
              marginLeft: "10%",
              marginTop: "-10%"
            }}
          />
        </div>
        <div style={{width: "100%", display: "flex", justifyItems: "end"}}>
          <Image
            alt=""
            src={Back}
            style={{
              //359 x 673
              height: `112px`,
              width: `60px`,
              marginLeft: "calc(100% - 60px )"
            }}
          />
        </div>

        <div style={{width: "100%", border: "blue", display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "10%"}}>
          <div
            autoFocus
            className="row centered-content"
            style={{
              backgroundColor: "#3A3B3C",
              borderRadius: `${radius}px`,
              display: "flex",
              width: `${imageW}px`,
              height: `${imageH}px`,
              marginRight: `${gaps}px`,
              cursor: "pointer"
            }}
            onClick={() => goNext()}
          >
            <p
              style={{
                color: "white",
                fontSize: fsize,
              }}
            >
              Start
            </p>
          </div>
          </div>
        <input
          type="button"
          autoFocus
          style={{
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
            width: "0px",
            height: "0px"
          }}
          onKeyPress={() => goNext()}
        />
        
      </div>
    </div>
    </motion.div>
  );

    // MOBILE
    const portraitComp = (
      <div className="centered-content">
      <Sounds sounds={"Manual"}/>
          <div>
            <div className="sideBlock" style={{ position: "absolute", width: "13px", height: "75px", marginTop: "45px", background: "#282828" }}></div>
    
            <Title style={{ fontSize: 60, width: "85%", margin: "auto", marginBottom: "0%"}}>manual.</Title>
    
            <div style={{ width: "85%", margin: "auto", marginBottom: "5%",}}>
              <div className="videoContainer" style={{height: "200%"}}>
                <img src={CursorVideo} />
              </div>
              <Text style={{ marginBottom: "2%", marginTop: "2%" }}>On each page there will be a question that leads you to your ticket for the afterlife, your ticket then links to a series of book recommendations for further discovery.</Text>
              <Text style={{ marginBottom: "2%", marginTop: "2%" }}>Sound is used throughout this experience, please ensure that your devices sound is turned on.</Text>
              <p id="disclaimerMessage" style={{ fontSize: 12, marginBottom: "2%", display: "none"}}><b>Disclaimer:</b> While the Death Positive Library services strives to make the information on this site is as timely and accurate as possible, they make no claims, promises, or guarantees about accuracy, completeness, or adequacy of the contents of this site, and expressly disclaims liability for errors and commissions in the contents of this site. </p>
            </div>
            <div style={{width: "100%", display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", maxHeight: "3rem"}}>
              <button id="disclaimerButton" onClick={displayDisclaimer} style={{marginLeft: "10px", padding: " 0.5rem 1rem", textAlign: "center"}}>Show Disclaimer</button>
              <div
                onClick={() => goNext()}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  marginTop: "0%",
                  marginRight: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "40%",
                  height: "3rem"
                }}
              >
                <p style={{display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1rem", transform: "translateY(-0.1rem)"}}>Continue</p>
                <img alt="" src={next} style={{ width: `${50}px` }} />
              </div>    
            </div>
              

          </div>
      </div>
    );

  return showPortrait ? portraitComp : landscapeComp;
};

export default StartScreen;
