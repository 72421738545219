import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketType2 = ({
  id,
  avail,
  cost,
  title,
  text,
  image,
  //imageW,
  imageRatio,
  titleLevel,
  section,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.LANDSCAPE[versionToShow - 1][id];

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      text={text}
      image={image}
      id={id}
      title={title}
    >
      <div
        style={{
          paddingLeft: section === 1 ? "25%" : "50%",
          width: "25%",
          height: "100%",
        }}
      >
        {titleLevel === 2 && (
          <div
            style={{
              height: "25%",
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: titleLevel === 1 ? "flex-end" : "flex-start",
            height: titleLevel === 1 ? "25%" : null,
          }}
        >
          {title}
        </div>
        <img
          alt=""
          src={image}
          style={{
            marginBottom: "5px",
            marginTop: "5px",
            height: `${IMAGE_H}px`,
            width: `${IMAGE_W}px`,
          }}
        />
        <div>{text}</div>
      </div>
    </Ticket>
  );
};

export default TicketType2;
