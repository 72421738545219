import photography from "../images/photography.jpg";
import ticket2 from "../images/tickets/ticket2.jpg";
import ticket3 from "../images/tickets/ticket3.jpg";
import ticket4 from "../images/tickets/ticket4.jpg";
import ticket5 from "../images/tickets/ticket5.jpg";
import ticket6 from "../images/tickets/ticket6.jpg";
import community from "../images/community.jpg";
import ticket8 from "../images/tickets/ticket8.jpg";
import ticket9 from "../images/tickets/ticket9.jpg";
import ticket10 from "../images/tickets/ticket10.jpg";
import ticket11 from "../images/tickets/ticket11.jpg";
import ticket12 from "../images/tickets/ticket12.jpg";
import ticket13 from "../images/tickets/ticket13.jpg";
import ticket14 from "../images/tickets/ticket14.jpg";
import ticket15 from "../images/tickets/ticket15.jpg";
import ticket16 from "../images/tickets/ticket16.jpg";
import ticket17 from "../images/tickets/ticket17.jpg";
import ticket18 from "../images/tickets/ticket18.jpg";
import ticket19 from "../images/tickets/ticket19.jpg";
import ticket20 from "../images/tickets/ticket20.jpg";
import online from "../images/online.jpg";
import ticket22 from "../images/tickets/ticket22.jpg";
import ticket23 from "../images/tickets/ticket23.jpg";
import body from "../images/body.jpg";
import ticket25 from "../images/tickets/ticket25.jpg";
import ticket26 from "../images/tickets/ticket26.jpg";
import ticket27 from "../images/tickets/ticket27.jpg";
import ticket28 from "../images/tickets/ticket28.jpg";
import scripted from "../images/scripted.jpg";
import ticket30 from "../images/tickets/ticket30.jpg";
import ticket31 from "../images/tickets/ticket31.jpg";
import ticket32 from "../images/tickets/ticket32.jpg";
import ticket33 from "../images/tickets/ticket33.jpg";
import ticket34 from "../images/tickets/ticket34.jpg";
import ticket35 from "../images/tickets/ticket35.jpg";
import ticket36 from "../images/tickets/ticket36.jpg";
import ticket37 from "../images/tickets/ticket37.jpg";
import ticket38 from "../images/tickets/ticket38.jpg";
import ticket39 from "../images/tickets/ticket39.jpg";
import ticket40 from "../images/tickets/ticket40.jpg";
import ticket41 from "../images/tickets/ticket41.jpg";
import ticket42 from "../images/tickets/ticket42.jpg";
import ticket43 from "../images/tickets/ticket43.jpg";
import ticket44 from "../images/tickets/ticket44.jpg";
import ticket45 from "../images/tickets/ticket45.jpg";
import ticket46 from "../images/tickets/ticket46.jpg";
import ticket47 from "../images/tickets/ticket47.jpg";
import ticket48 from "../images/tickets/ticket48.jpg";
import ticket49 from "../images/tickets/ticket49.jpg";
import ticket50 from "../images/tickets/ticket50.jpg";

import TicketText from "../components/TicketText";
import TicketTitle from "../components/TicketTitle";
import SmallText from "../typocomps/SmallText";

//Note: book ids start form 1 :) shoub be -1 to get the index in the books array


const TicketsData = [
  {
    id: 1,
    name: "Memento Mori",
    type: 1,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Memento Mori <br />
        Photography
      </TicketTitle>
    ),
    titlev2: <TicketTitle>Memento Mori Photography</TicketTitle>,
    text: (
      <TicketText>
        Memento Mori Photography is the Victorian practice of taking pictures of
        dead family members to preserve their memory. The deceased were often
        posed to look alive. <br />
        <br />
        Nowadays, a similar practice is photoshopping a deceased family member
        into a photo.
      </TicketText>
    ),
    image: photography,

    ratio: 1.5,
    imageTitle: (
      <div className="row" style={{ marginTop: "2px" }}>
        <div style={{ marginRight: "10px" }}>
          <SmallText>
            memento
            <br />
            mori
          </SmallText>
        </div>
        <div>
          <SmallText>
            'to remember'
            <br />
            'to die'
          </SmallText>
        </div>
      </div>
    ),
    //
    titleLevel: 1,
    gapFraction: 10,
    //
    pmtype: 4,
    //
    pstype: 1,
    //
    books: [52, 25, 39],
  },
  {
    id: 2,
    name: "Spirit Photography",
    type: 5,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Spirit
        <br />
        Photography
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Spirit Photography is a type of photography that tries to capture images
        of ghosts and other spiritual entities, especially in ghost hunting. It
        has a strong history dating back to the late 19th century.
      </TicketText>
    ),
    image: ticket2,

    ratio: 0.95,
    //
    titlev2: <TicketTitle>Spirit Photography</TicketTitle>,
    //
    titleLevel: 2,
    imageLeft: 1,
    imageTop: 1,
    gap: 0,
    //
    pmtype: 3,
    pmtop: 1,
    //
    pstype: 1,
    //
    books: [59, 43, 57],
  },
  {
    id: 3,
    name: "Totenpass",
    type: 2,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Totenpass</TicketTitle>,
    text: (
      <TicketText>
        The word totenpass is German for 'passport for the dead'. It refers to
        written instructions for passing through the afterlife. They were
        contained in amulets worn in ancient Greece and Egypt.
        <br />
        <br />
        Nowadays, you can create your own Totenpass instructions for a loved
        one's burial.
      </TicketText>
    ),
    textv2: (
      <TicketText>
        The word totenpass is German for 'passport for the dead'. It refers to
        written instructions for passing through the afterlife. They were
        contained in amulets worn in ancient Greece and Egypt. Nowadays, you can
        create your own Totenpass instructions for a loved ones burial.
      </TicketText>
    ),
    image: ticket3,

    ratio: 0.66,
    titleLevel: 1,
    section: 2,
    //
    typev2: 5,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //
    pstype: 2,
    psParPosition: 2,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [40, 28, 42],
  },
  {
    id: 4,
    name: "Grave goods",
    type: 3,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Grave goods</TicketTitle>,
    text: (
      <TicketText>
        Grave goods are items that are buried with the deceased that may benefit
        them in the afterlife. In East Asia this practice strongly resembles
        that of the Hell Bank Note, which are still burnt as offerings to
        ancestor spirits.
        <br />
        <br />
        Nowadays, this could mean burying a loved one with a sentimental item.
      </TicketText>
    ),
    image: ticket4,

    titleLevel: 3,
    left: 1,
    //
    typev2: 7,
    //
    pmtype: 5,

    pmImagePosition: 1,
    pmTitlePosition: 1,
    //
    pstype: 2,
    ratio: 0.71,
    psParPosition: 2,
    psTitlePosition: 1,
    psImagePosition: 3,
    //
    books: [23, 50, 47],
  },
  {
    id: 5,
    name: "Mourning Jewellery",
    type: 5,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Mourning
        <br />
        Jewellery
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Mourning jewellery is of two general types, either a commemorative piece
        which marks the death of an individual, or one which denotes that the
        wearer is observing a period of formal bereavement. Some of these items
        contained the person's hair intricately braided or even occasionally a
        small tooth.
        <br />
        <br />
        Nowadays a variety of mourning jewellery exists - some including
        lockets, pendant urns or engraved rings.
      </TicketText>
    ),
    textv2: (
      <TicketText>
        Mourning jewellery is of two general types, either a commemorative piece
        which marks the death of an individual, or one which denotes that the
        wearer is observing a period of formal bereavement. Some of these items
        contained the person's hair intricately braided or even occasionally a
        small tooth. Nowadays a variety of mourning jewellery exists - some
        including lockets, pendant urns or engraved rings.
      </TicketText>
    ),
    image: ticket5,

    ratio: 0.99,
    //
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 0,
    //
    typev2: 7,
    titlev2: <TicketTitle>Mourning Jewellery</TicketTitle>,
    //
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    // /
    pstype: 1,
    //
    books: [20, 33, 64],
  },
  {
    id: 6,
    name: "Death Beads",
    type: 5,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Ashes to
        <br />
        Death Beads
      </TicketTitle>
    ),
    titlev2: <TicketTitle>Ashes to Death Beads</TicketTitle>,
    text: (
      <TicketText>
        As an alternative to traditional burial methods, South Koreans are
        taking relatives' ashes and transforming them into shiny blue-green,
        pink or black beads. South Koreans typically keep the beads on dishes or
        inside glass containers. It's meant as a decorative way to keep the
        deceased nearby.
      </TicketText>
    ),
    image: ticket6,

    ratio: 1.54,
    //
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 1,
    //
    pmtype: 1,
    //
    pstype: 1,
    //
    books: [8, 12, 30],
  },
  {
    id: 7,
    name: "Catacombs",
    type: 1,
    avail: 0,
    title: <TicketTitle>Catacombs</TicketTitle>,
    text: (
      <TicketText>
        Catacombs are huge highly decorated underground cemeteries that contain
        many bodies. Some are like whole cities of tombs underneath the ground
        where the living dwelled. Similar practice nowadays is using a community
        funeral service.
      </TicketText>
    ),
    image: community,

    ratio: 0.58,
    titleLevel: 1,
    gapFraction: 10,
    //
    imagePosition: 2,
    titlePosition: 1,
    //
    pmtype: 5,
    pmImagePosition: 2,
    pmTitlePosition: 1,
    //
    pstype: 2,

    psParPosition: 2,
    psTitlePosition: 1,
    psImagePosition: 2,
    //
    books: [12, 24, 54],
  },
  {
    id: 8,
    name: "Safety Coffin",
    type: 5,
    typev2: 7,
    avail: 0,
    cost: 2,
    title: <TicketTitle>Safety Coffin</TicketTitle>,
    text: (
      <TicketText>
        A safety coffin or security coffin contains a mechanism to prevent
        premature burial, allowing the person a way to call out if they have
        been buried alive. A large number of designs for safety coffins were
        patented during the 18th and 19th centuries and variations on the idea
        are still available today.
      </TicketText>
    ),
    image: ticket8,

    ratio: 1.18,
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 2,
    //
    pmtype: 3,
    pmtop: 1,
    //
    pstype: 1,
    //
    books: [7, 42, 26],
  },
  {
    id: 9,
    name: "Burial at Sea",
    type: 5,
    typev2: 7,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Burial at Sea</TicketTitle>,
    text: (
      <TicketText>
        Burial at sea is the disposal of human remains in the ocean, normally
        from a ship or boat. The ceremony may include burial in a casket, burial
        sewn in sailcloth, burial in an urn, or scattering of the cremated
        remains from a ship.
        <br />
        <br />
        Burial at sea is an option available to everyone in the UK in the
        permitted designated areas.
      </TicketText>
    ),
    image: ticket9,

    ratio: 2.2,
    //
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 2,
    //
    pmtype: 3,
    pmtop: 1,
    //
    pstype: 1,
    //
    books: [46, 49, 21],
  },
  {
    id: 10,
    name: "Memorial Bench",
    type: 5,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Memorial
        <br />
        Bench
      </TicketTitle>
    ),
    text: (
      <TicketText>
        A memorial bench or memorial seat is a piece of furniture which
        commemorates a person who has died. Typically memorial benches are
        placed in public places but are often also placed in domestic gardens.
      </TicketText>
    ),
    image: ticket10,

    ratio: 0.73,
    //
    titleLevel: 1,
    imageLeft: 0,
    imageTop: 1,
    gap: 0,
    //

    pmtype: 3,
    pmtop: 2,
    //
    pstype: 1,
    //
    books: [41, 61, 56],
  },
  {
    id: 11,
    name: "Funerary Art",
    type: 1,
    avail: 1,
    cost: 3,
    title: <TicketTitle>Funerary Art</TicketTitle>,
    text: (
      <TicketText>
        Funerary art is any work or art forming or placed in a repository for
        the remains of the dead. There is an enormous diversity of funeral art
        from traditional societies across the world, much of it in perishable
        materials.
      </TicketText>
    ),
    image: ticket11,

    ratio: 0.73,
    titleLevel: 2,
    gapFraction: 10,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //
    pstype: 2,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [18, 62, 2],
  },
  {
    id: 12,
    name: "Butterfly Release",
    type: 1,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Butterfly Release</TicketTitle>,
    text: (
      <TicketText>
        Releasing live butterflies for a loved one is a unique way to honour and
        remember them. The butterfly has long been a symbol of freedom, love and
        new life or the beginning of a new journey.
      </TicketText>
    ),
    image: ticket12,

    ratio: 2.2,
    titleLevel: 2,
    gapFraction: 15,
    //
    pmtype: 1,
    //

    //
    pstype: 1,
    //
    books: [53, 6, 19],
  },
  {
    id: 13,
    name: "Memorial Fireworks",
    type: 1,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Memorial
        <br />
        Fireworks
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Memorial fireworks are a way of scattering the ashes of your loved one
        or friend by incorporating their cremation ash into fireworks.
      </TicketText>
    ),
    image: ticket13,

    ratio: 0.66,
    titleLevel: 2,
    gapFraction: 10,
    //
    pmtype: 3,
    pmtop: 2,
    //
    pstype: 2,

    psParPosition: 2,
    psTitlePosition: 3,
    psImagePosition: 3,
    //
    books: [30, 65, 66],
  },
  {
    id: 14,
    name: "Funerals in Gaming",
    type: 1,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Funerals in Gaming
        <br />
        Environments
      </TicketTitle>
    ),
    titlev2: <TicketTitle>Funerals in Gaming Environments</TicketTitle>,
    text: (
      <TicketText>
        When active members of gaming communities die, fellow players or clan
        members can use the specific environments of a gaming universe to create
        bespoke memorials or funerary activities e.g. putting their avatar into
        player position or constructing visual signifiers like RIP in the
        digital space.
      </TicketText>
    ),
    image: ticket14,

    ratio: 0.58,
    titleLevel: 2,
    //
    gapFraction: 10,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //
    pstype: 2,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [38, 48, 36],
  },
  {
    id: 15,
    name: "Streaming Funerals",
    type: 1,
    typev2: 7,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Online vigils, <br />
        Streaming funerals <br />& Virtual wakes
      </TicketTitle>
    ),
    text: (
      <TicketText>
        When someone dies, a watch is kept over the body until the burial
        service. The vigil is time for prayer and certain rituals, which can
        also be conducted online (e.g. lighting a virtual candle).
        <br />
        <br />
        Funerals and other memorial services such as Brazilian wakes can be
        streamed or livecast in real-time via webcam for relatives in other
        countries who have been unable to attend.
      </TicketText>
    ),
    image: ticket15,

    ratio: 1.22,
    titleLevel: 1,
    gapFraction: 10,
    titlev2: (
      <TicketTitle>
        Online vigils, Streaming funerals <br />& Virtual wakes
      </TicketTitle>
    ),
    textv2: (
      <TicketText>
        When someone dies, a watch is kept over the body until the burial
        service. The vigil is time for prayer and certain rituals, which can
        also be conducted online (e.g. lighting a virtual candle). Funerals and
        other memorial services such as Brazilian wakes can be streamed or
        livecast in real-time via webcame for relatives in other countries who
        have been unable to attend.
      </TicketText>
    ),

    //
    pmtype: 1,
    //
    pstype: 1,
    //
    books: [8, 48, 10],
  },
  {
    id: 16,
    name: "Community Funeral Services",
    type: 1,
    avail: 1,
    cost: 3,
    title: (
      <TicketTitle>
        Using Community
        <br />
        Funeral Services
      </TicketTitle>
    ),
    text: (
      <TicketText>
        A Community Funeral Service (CFS) is a community-owned enterprise which
        has a common purpose. It allows volunteers and professionals to be
        members, supporting the creation of funerals that share three common
        objectives: commercial, social and environmental.
      </TicketText>
    ),
    image: ticket16,

    ratio: 0.71,
    titleLevel: 1,
    gapFraction: 20,
    //
    //
    pmtype: 5,
    pmImagePosition: 1,
    pmTitlePosition: 0,
    //
    //
    pstype: 2,

    psParPosition: 2,
    psTitlePosition: 1,
    psImagePosition: 3,
    //
    books: [18, 62, 2],
  },
  {
    id: 17,
    name: "Keepsake box",
    type: 5,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Keepsake
        <br />
        box
      </TicketTitle>
    ),
    titlev2: <TicketTitle>Keepsake box</TicketTitle>,
    text: (
      <TicketText>
        A keepsake box or memory box is used for storing mementos of a special
        time, event or person. They may also be given for sad occasions of
        bereavement, such as the stillbirth of a child, when a keepsake or
        memory box helps with the grieving process.
      </TicketText>
    ),
    image: ticket17,

    ratio: 0.73,
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 0,
    //
    pmtype: 3,
    pmtop: 1,
    //
    pstype: 2,
    psTitle: <TicketTitle>Keepsake box</TicketTitle>,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [29, 44, 15],
  },
  {
    id: 18,
    name: "Time capsule",
    type: 5,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Time Capsule</TicketTitle>,
    text: (
      <TicketText>
        A time capsule is a historic cache of goods or information, usually
        intended as a deliberate method of communication with future people.
        Memorabilia boxes and time capsules are an American invention. You can
        too create a time capsule with a message to the future.
      </TicketText>
    ),
    image: ticket18,

    ratio: 0.93,
    //
    titleLevel: 2,
    imageLeft: 1,
    imageTop: 1,
    gap: 0,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [45, 35, 58],
  },
  {
    id: 19,
    name: "Giveaway",
    type: 1,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Giveaway</TicketTitle>,
    text: (
      <TicketText>
        Funeral ceremonies for the Native American Lakota people are generally
        centered around the community. They can feature a 'giveaway' - people
        are encouraged to take one of the deceased's possessions because among
        traditional Lakota, generosity is more important than possession.
      </TicketText>
    ),
    image: ticket19,

    ratio: 0.96,
    titleLevel: 2,
    gapFraction: 6,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [8, 29, 44],
  },
  {
    id: 20,
    name: "Profile Memorialisation",
    type: 1,
    typev2: 1,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Profile Memorialisation
        <br />& Legacy Contact
      </TicketTitle>
    ),
    titlev2: (
      <TicketTitle>Profile Memorialisation & Legacy Contact</TicketTitle>
    ),
    text: (
      <TicketText>
        Some social media services (including Facebook and Instagram) have
        services that allow you to delete, deactivate or memorialise a person's
        profile, where the profile remains active as a memorial. Some services
        also give options to set up a Legacy Contact. A legacy contact is a
        person who will take responsibility for your memorialised social media
        profile after you die.
      </TicketText>
    ),
    image: ticket20,

    ratio: 1.51,
    titleLevel: 1,
    gapFraction: 20,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 1,

    psParPosition: 2,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [17, 9, 3],
  },
  {
    id: 21,
    name: "Digital Auditor",
    type: 1,
    typev2: 7,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Employ a Digital
        <br />
        Auditor or Curator
      </TicketTitle>
    ),
    titlev2: <TicketTitle>Employ a Digital Auditor or Curator</TicketTitle>,
    text: (
      <TicketText>
        A digital auditor is a person who is paid to go through the online
        collection of a person after their death and sort them out for their
        next of kin. A digital curator may additionally develop collections that
        identify favourite content and/or cleaning and removing private content.
        They may also create artefacts such as photo books or films for the
        family. Very few services of this kind currently exist.
      </TicketText>
    ),
    image: online,

    ratio: 0.58,
    titleLevel: 1,
    gapFraction: 10,
    //
    pmtype: 5,
    pmImagePosition: 0,
    pmTitlePosition: 1,
    //
    pstype: 2,

    psParPosition: 2,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [17, 31, 29],
  },
  {
    id: 22,
    name: "QR Codes on gravestones",
    type: 5,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        QR Codes on <br />
        Gravestones
      </TicketTitle>
    ),
    text: (
      <TicketText>
        By placing a QR code onto the grave either carved into the headstone or
        using an adhesive sticker you can leave and access digital content like
        photos, video or text, scanning the barcode and viewing it via mobile
        phone.
      </TicketText>
    ),
    image: ticket22,

    ratio: 2.5,
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 1,
    //
    pmtype: 5,
    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 1,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [31, 9, 48],
  },
  {
    id: 23,
    name: "Robot Funerals",
    type: 5,
    avail: 2,
    cost: 1,
    title: <TicketTitle>Robot Funerals</TicketTitle>,
    text: (
      <TicketText>
        Robot Funerals are either conducted by 'robot-priests' like Pepper the
        Robot or for robots like AIBO dogs when they have finished their
        service. Most robot funerals have been conducted in Japan.
      </TicketText>
    ),
    image: ticket23,

    ratio: 0.98,
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 0,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [7, 27, 16],
  },
  {
    id: 24,
    name: "Robotic body",
    type: 1,
    avail: 0,
    title: <TicketTitle>Robotic Body</TicketTitle>,
    text: (
      <TicketText>
        Instead of saving your body or growing <br /> a new body, you may want
        to download <br /> your consciousness into a robotic body <br /> living
        on as an artificial being.
      </TicketText>
    ),
    image: body,

    ratio: 1.51,
    titleLevel: 1,
    gapFraction: 30,
    //
    pmtype: 3,
    pmtop: 2,
    //
    pstype: 1,
    //
    books: [52, 27, 11],
  },
  {
    id: 25,
    name: "Space Burial",
    type: 4,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Space Burial</TicketTitle>,
    text: (
      <TicketText>
        A space burial is where some element of the body, most often ashes or
        hair in a small container, is flown into space. Space Burials may orbit
        around the Earth or be sent to extra-terrestrial bodies such as the
        Moon, or further into space.
      </TicketText>
    ),
    image: ticket25,

    ratio: 0.475,
    //
    typev2: 1,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,
    psParPosition: 2,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [30, 4, 51],
  },
  {
    id: 26,
    name: "Virtual Transcendence",
    type: 1,
    avail: 0,
    title: (
      <TicketTitle>
        Virtual
        <br />
        Transcendence
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Virtual Transcendence is the passing of a person's consciousness into a
        computer to leave behind a dying physical body.
      </TicketText>
    ),
    image: ticket26,

    ratio: 1.46,
    titleLevel: 3,
    gapFraction: 10,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [52, 27, 9],
  },
  {
    id: 27,
    name: "Cryopreservation",
    type: 1,
    avail: 1,
    cost: 2,
    title: <TicketTitle>Cryopreservation</TicketTitle>,
    text: (
      <TicketText>
        Cryonics is the act of freezing a body (below 130°C) in an attempt to
        prevent death by preserving enough brain information to revive them in
        the future.
      </TicketText>
    ),
    image: ticket27,

    ratio: 2.25,
    titleLevel: 2,
    gapFraction: 20,
    //
    pmtype: 3,
    pmtop: 3,
    //
    pstype: 2,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [52, 4, 51],
  },
  {
    id: 28,
    name: "Rejuvenation Technologies",
    type: 2,
    avail: 1,
    cost: 2,
    title: (
      <TicketTitle>
        Rejuvenation
        <br />
        Technologies
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Rejuvenation Technologies use transfusions of blood plasma or stem cell
        therapy to reverse aging in the body and radically extend life.
      </TicketText>
    ),
    image: ticket28,

    ratio: 0.5,
    //
    titleLevel: 2,
    section: 1,
    //
    typev2: 5,
    //
    pmtype: 5,
    pmImagePosition: 2,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,
    psTitle: <TicketTitle>Rejuvenation Technologies</TicketTitle>,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [52, 4, 37],
  },
  {
    id: 29,
    name: "Pre-recorded Messages",
    type: 5,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Pre-recorded <br />
        Messages online
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Various online companies offer services that give people the opportunity
        to pre-record messages for loved ones after death, including a date or a
        significant event (for example before a wedding) in which to play the
        message.
      </TicketText>
    ),
    image: scripted,

    ratio: 0.94,
    //
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 1,
    //
    //
    pmtype: 3,
    pmtop: 2,
    //

    pstype: 2,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [8, 48, 10],
  },
  {
    id: 30,
    name: "Lifelogging",
    type: 5,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Lifelogging</TicketTitle>,
    text: (
      <TicketText>
        Lifelogging is when someone wears a camera or other recording device
        that takes pictures of film constantly to be kept as a record of their
        life.
      </TicketText>
    ),
    image: ticket30,

    ratio: 3.39,
    //
    titleLevel: 2,
    imageLeft: 1,
    imageTop: 1,
    gap: 2,
    //
    pmtype: 3,
    pmtop: 3,
    //

    //
    pstype: 1,
    //
    books: [9, 3, 42],
  },
  {
    id: 31,
    name: "Immersive Memory Experiences",
    type: 3,
    avail: 0,
    title: (
      <TicketTitle>
        Immersive
        <br />
        Memory Experiences
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Future remembrance events may include immersive experiences of a
        person's memories. These memories could be shown to audiences through
        virtual and/or augmented reality.
      </TicketText>
    ),
    image: ticket31,

    ratio: 0.74,
    titleLevel: 1,
    left: 1,
    //
    typev2: 1,
    //
    pmtype: 5,
    pmImagePosition: 1,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,
    psParPosition: 2,
    psTitlePosition: 1,
    psImagePosition: 3,
    //
    books: [45, 34, 5],
  },
  {
    id: 32,
    name: "Anniversary Hologram",
    type: 1,
    avail: 1,
    cost: 2,
    title: (
      <TicketTitle>
        Anniversary
        <br />
        Hologram
      </TicketTitle>
    ),
    text: (
      <TicketText>
        On anniversaries or special occasions a pre-recorded or AI generated
        hologram would be activated to share your stories and interact with
        family and friends.
      </TicketText>
    ),
    image: ticket32,

    ratio: 0.65,
    titleLevel: 1,
    gapFraction: 20,
    //
    pmtype: 3,
    pmtop: 2,
    //

    pstype: 2,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [63, 1, 10],
  },
  {
    id: 33,
    name: "Rememory",
    type: 5,
    avail: 1,
    cost: 3,
    title: <TicketTitle>Rememory</TicketTitle>,
    text: (
      <TicketText>
        A rememory may be a product of lifelogging, in which the complete record
        of their life is edited and used as a cinematic experience from the
        point of view of the deceased.
      </TicketText>
    ),
    image: ticket33,

    ratio: 0.75,
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 1,
    //
    pmtype: 3,
    pmtop: 2,
    //
    pstype: 2,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [34, 9, 58],
  },
  {
    id: 34,
    name: "Digital memory Object",
    type: 6,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Digital
        <br />
        Memory
        <br />
        Object
      </TicketTitle>
    ),
    text: (
      <TicketText>
        A digital memory object is a person's digital life contained within a
        physical object for future generations. This could be a digital
        interface cased in a box on a shelf, a shared shrine in the garden or a
        piece of digital jewellery.
      </TicketText>
    ),
    image: ticket34,

    ratio: 0.82,
    //
    imageSide: 1,
    //
    typev2: 1,
    titlev2: <TicketTitle>Digital Memory Object</TicketTitle>,
    //
    //
    pmtype: 5,
    pmImagePosition: 1,
    pmTitlePosition: 0,
    //
    //

    pstype: 2,
    psParPosition: 2,
    psTitlePosition: 3,
    psImagePosition: 3,
    //
    books: [31, 36, 10],
  },
  {
    id: 35,
    name: "Digital memorial Tattoo",
    type: 6,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Digital
        <br />
        Memorial
        <br />
        Tattoo
      </TicketTitle>
    ),
    text: (
      <TicketText>
        A Digital Memorial Tattoo uses the merger of electronics and biology to
        create werable electronics under the skin, which can be directly printed
        onto or used as a screen. This may include sensors in the environment
        and geolocation that activate the tattoo in important places for the
        bereaved.
      </TicketText>
    ),
    image: ticket35,
    ratio: 0.74,
    //
    imageSide: 2,
    //
    typev2: 1,
    titlev2: <TicketTitle>Digital Memorial Tattoo</TicketTitle>,
    //
    //
    pmtype: 4,
    //
    pstype: 2,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [3, 1, 32],
  },
  {
    id: 36,
    name: "Eternam Reef",
    type: 1,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Eternal Reef</TicketTitle>,
    text: (
      <TicketText>
        Reef balls combine a cremation urn, ash scattering, and burial at sea.
        Ashes are mixed with concrete and molded into a reef ball - a hollow,
        holey structure that sits on the seafloor, ideally creating marine
        habitat and preserving erosion that can be visited by scuba diving.
      </TicketText>
    ),
    image: ticket36,

    ratio: 0.76,
    titleLevel: 2,
    gapFraction: 10,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [49, 2, 46],
  },
  {
    id: 37,
    name: "DNA splice with a tree",
    type: 5,
    avail: 1,
    title: (
      <TicketTitle>
        DNA spliced <br />
        with a tree
      </TicketTitle>
    ),
    text: (
      <TicketText>
        This is a ritual where a scientist would replace unused, or "junk" DNA
        in a tree's chromosomes with parts of a human donor's to create a hybrid
        where the human genes are present in every cell of the plant.
      </TicketText>
    ),
    image: ticket37,

    ratio: 0.31,
    titleLevel: 1,
    imageLeft: 0,
    imageTop: 2,
    gap: 1,
    //
    //
    pmtype: 3,
    pmtop: 1,
    //ps
    pstype: 2,
    titlev2: <TicketTitle>DNA spliced with a tree</TicketTitle>,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [52, 49, 22],
  },
  {
    id: 38,
    name: "Ashes into Diamonds",
    type: 5,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Transform Hair or
        <br />
        Ashes into Diamonds
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Using your ashes or hair the carbon is extracted to create laboratory
        grown diamonds that can be made into jewellery.
      </TicketText>
    ),
    image: ticket38,

    ratio: 1.88,
    //
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 1,
    //
    //
    pmtype: 3,
    pmtop: 3,
    //
    pstype: 2,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [30, 1, 35],
  },
  {
    id: 39,
    name: "Tattoo with Ashes",
    type: 3,
    avail: 1,
    cost: 1,
    title: (
      <TicketTitle>
        Memorial Tattoo <br />
        with Cremation Ashes
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Combines a small portion of cremated remains with traditional tattoo ink
        and allows you to then use the ink for tattooing purposes.
      </TicketText>
    ),
    image: ticket39,

    ratio: 0.85,
    titleLevel: 1,
    left: 2,
    //
    typev2: 1,
    //
    pmtype: 5,
    pmImagePosition: 1,
    pmTitlePosition: 1,
    //
    pstype: 2,
    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [30, 1, 32],
  },
  {
    id: 40,
    name: "Woodland Burial",
    type: 5,
    avail: 1,
    cost: 3,
    title: (
      <TicketTitle>
        Woodland <br />
        Burial
      </TicketTitle>
    ),
    text: (
      <TicketText>
        A woodland burial is an eco-friendly funeral option. Woodland burial are
        an environmentally-friendly alternative to traditional burials that take
        place in natural burial grounds, or designated woodland burial sites in
        larger cemeteries.
      </TicketText>
    ),
    image: ticket40,

    ratio: 2.03,
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 1,
    //
    pmtype: 3,
    pmtop: 1,
    //

    pstype: 1,
    //
    books: [60, 49, 32],
  },
  {
    id: 41,
    name: "Natural Burial",
    type: 1,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Natural Burial</TicketTitle>,
    text: (
      <TicketText>
        A natural burial describes a burial area that creates habitat for
        wildlife or preserves existing natural spaces. It includes funeral
        processes that have a low environmental impact, prohibiting embalming
        and, where a coffin is used, choosing natural degradable materials e.g.
        willow coffins or leaf shrouds.
      </TicketText>
    ),
    image: ticket41,

    ratio: 0.74,
    titleLevel: 2,
    gapFraction: 15,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [60, 49, 2],
  },
  {
    id: 42,
    name: "Cardboard Coffins",
    type: 3,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Cardboard Coffins</TicketTitle>,
    text: (
      <TicketText>
        Cardboard coffins are an eco-friendly option that can be drawn on or
        decorated with photographs and memories.
      </TicketText>
    ),
    image: ticket42,

    ratio: 0.85,
    titleLevel: 2,
    left: 1,
    //
    typev2: 1,
    //
    pmtype: 5,
    pmImagePosition: 1,
    pmTitlePosition: 0,
    //

    //
    pstype: 2,
    psParPosition: 2,
    psTitlePosition: 1,
    psImagePosition: 3,
    //
    books: [49, 60, 67],
  },
  {
    id: 43,
    name: "Mushroom Suit",
    type: 5,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Mushroom Suit</TicketTitle>,
    text: (
      <TicketText>
        Mushroom suit is a burial method that reduces toxins in the body by
        being buried in a cotton bodysuit covered in mushroom spores that
        facilitate decomposition.
      </TicketText>
    ),
    image: ticket43,
    ratio: 0.99,
    titleLevel: 3,
    imageLeft: 1,
    imageTop: 2,
    gap: 0,
    //
    pmtype: 5,
    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    pstype: 2,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [60, 49, 14],
  },
  {
    id: 44,
    name: "Cremation Energy",
    type: 5,
    avail: 0,
    title: (
      <TicketTitle>
        Cremation <br />
        Energy
      </TicketTitle>
    ),
    titlev2: <TicketTitle>Cremation Energy</TicketTitle>,
    text: (
      <TicketText>
        When bodies are cremated they produce energy. By installing turbines in
        crematoriums this energy can be used to generate electricity. Some
        crematoriums and artistic projects have explored what could be done with
        this energy such as producing a set of batteries for the family donating
        this energy to a social cause.
      </TicketText>
    ),
    image: ticket44,

    ratio: 1.28,
    titleLevel: 1,
    imageLeft: 1,
    imageTop: 1,
    gap: 0,
    //
    pmtype: 3,
    pmtop: 1,
    //

    pstype: 1,
    psTitle: <TicketTitle>Cremation Energy</TicketTitle>,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [13, 49, 22],
  },
  {
    id: 45,
    name: "Green Cremation",
    type: 1,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Green Cremation</TicketTitle>,
    text: (
      <TicketText>
        Green cremation involves the placement of the body inside a tank with
        water and lye, the process is more eco-friendly then traditional
        cremation. Eventually, bone is the only solid left and the ashes
        received at the end of this process are white and powdery.
      </TicketText>
    ),
    image: ticket45,

    ratio: 0.63,
    titleLevel: 2,
    gapFraction: 8,
    //
    pmtype: 5,

    pmImagePosition: 0,
    pmTitlePosition: 1,
    //

    //
    pstype: 2,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [49, 68, 3],
  },
  {
    id: 46,
    name: "Human Composting",
    type: 3,
    avail: 3,
    title: <TicketTitle>Human Composting</TicketTitle>,
    text: (
      <TicketText>
        Human Composting is a natural organic reduction that creates an
        accelerated decomposition method, transforming human remains into soil
        over a period of four to seven weeks. Some US states have begun to legalise this practice.
      </TicketText>
    ),
    image: ticket46,

    ratio: 0.84,
    //
    titleLevel: 3,
    left: 1,
    //
    typev2: 1,
    //
    //
    pmtype: 5,
    pmImagePosition: 1,
    pmTitlePosition: 0,
    //

    pstype: 2,
    psTitle: (
      <TicketTitle>
        Human
        <br />
        Composting
      </TicketTitle>
    ),

    psParPosition: 2,
    psTitlePosition: 3,
    psImagePosition: 3,
    //
    books: [49, 3, 69],
  },
  {
    id: 47,
    name: "Clone a New Body",
    type: 5,
    avail: 0,
    title: (
      <TicketTitle>
        Clone a <br />
        new body
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Store your cells alive, with their biological clocks stopped. Future
        technology may be able to grow you a new body via ectogenesis and your
        digital data downloaded into it, enabling you to live on indefinitely.
      </TicketText>
    ),
    image: ticket47,

    ratio: 1.26,
    //
    titleLevel: 2,
    imageLeft: 1,
    imageTop: 1,
    gap: 0,
    //
    //
    pmtype: 3,
    pmtop: 1,
    //
    pstype: 2,
    psTitle: <TicketTitle>Clone a new body</TicketTitle>,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [52, 27, 11],
  },
  {
    id: 48,
    name: "Technical Reanimation",
    type: 5,
    avail: 0,
    title: (
      <TicketTitle>
        Technical <br />
        Reanimation
      </TicketTitle>
    ),
    text: (
      <TicketText>
        Technical reanimation is the idea that someone can be brought back to
        life using their words and collections online to reconstruct thought
        patterns, creating a new digital entity or avatar.
      </TicketText>
    ),
    image: ticket48,

    ratio: 1.54,
    //
    titleLevel: 2,
    imageLeft: 1,
    imageTop: 1,
    gap: 0,
    //
    pmtype: 4,
    //
    pstype: 1,
    //
    books: [52, 22, 3],
  },
  {
    id: 49,
    name: "3D Printing Ashes",
    type: 5,
    avail: 0,
    title: <TicketTitle>3D Printing Ashes</TicketTitle>,
    text: (
      <TicketText>
        3D printers use a sediment material that can be printed with layers of
        glue to create any desired form. This sediment can be replaced with
        ashes to create a bespoke and appropriate memorial object.
      </TicketText>
    ),
    image: ticket49,

    ratio: 0.5,
    titleLevel: 1,
    imageLeft: 0,
    imageTop: 2,
    gap: 1,
    //
    pmtype: 3,
    pmtop: 1,
    //
    pstype: 2,

    psParPosition: 1,
    psTitlePosition: 1,
    psImagePosition: 1,
    //
    books: [52, 66, 67],
  },
  {
    id: 50,
    name: "Deletion Ritual",
    type: 2,
    avail: 1,
    cost: 1,
    title: <TicketTitle>Deletion Ritual</TicketTitle>,
    text: (
      <TicketText>
        A deletion ritual is when all or specific parts of a person's digital
        identity are deleted. This can be done using USB sticks that are then
        liquefied in acid or embalmed in plastic. Or through a technical
        tool/algorithm/virus that goes through all your accounts systematically
        deleting them.
      </TicketText>
    ),
    image: ticket50,

    ratio: 0.475,
    //
    titleLevel: 1,
    section: 1,
    //
    typev2: 5,
    //
    //
    pmtype: 5,
    pmImagePosition: 1,
    pmTitlePosition: 0,
    //

    pstype: 2,
    psParPosition: 2,
    psTitlePosition: 3,
    psImagePosition: 3,
    //
    books: [29, 55, 9],
  },
];

export default TicketsData;
