import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import QuestionsFrame from "./QuestionsFrame";
import { QUESTION_LABELS } from "../utils/constants";
import MultipleChoice from "./MultipleChoice";
import * as manager from "../utils/manager";
import next from "../images/icons/next.png";
import BoldText from "../typocomps/BoldText";
import HugeTitle from "../typocomps/HugeTitle";
import Image from "./Image";
import line from "../images/lines/lineJ.png";
import pline from "../images/lines/lineJ-P.png";
import BoldLabel from "../typocomps/BoldLabel";

import { motion } from "framer-motion";
import Sounds from "../components/Sounds";
import ScreenAnalytics from "./ScreenAnalytics";

const ScreenTypeD2 = ({ paragraph, paragraphPortrait, links, sound }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const numLabels = links.length; // either 4 or 5

  const lineW =
    versionToShow === 1
      ? 380
      : versionToShow === 2
      ? 440
      : versionToShow === 3
      ? 540
      : versionToShow === 4
      ? 640
      : 140;

  const lineRatio = 697 / 4230;
  const lineH = lineW * lineRatio;

  const offset =
    versionToShow === 1
      ? 30
      : versionToShow === 2
      ? 35
      : versionToShow === 3
      ? 60
      : versionToShow === 4
      ? 60
      : 60;

  const hOffset =
    versionToShow === 1
      ? 125
      : versionToShow === 2
      ? 125
      : versionToShow === 3
      ? 125
      : versionToShow === 4
      ? 125
      : 125;

  const H =
    versionToShow === 1
      ? 100
      : versionToShow === 2
      ? 120
      : versionToShow === 3
      ? 320
      : versionToShow === 4
      ? 320
      : 320;

  const landscapeComp = (
    <div className="centered-content">
    <Sounds sounds={sound}/>
    <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
      <div style={{ width: "95%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginBottom: "0px",
            width: "100%",
          }}
        >
          {paragraph}
        </div>
        <div
          className="row centered-content"
          style={{
            position: "relative",
            height: `${H}px`,
          }}
        >
          <div
            className="row"
            style={{
              position: "absolute",
              margin: "auto",
              left: 0,
              right: 0,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link className="no-decoration" to={links[0].path}>
              <BoldLabel style={{ marginRight: "10px" }}>
                {links[0].label}
              </BoldLabel>
            </Link>
            <img
              alt=""
              src={line}
              style={{
                height: `${lineH}px`,
                width: `${lineW}px`,
              }}
            />
            <Link className="no-decoration" to={links[numLabels - 1].path}>
              <BoldLabel style={{ marginLeft: "10px" }}>
                {links[numLabels - 1].label}
              </BoldLabel>
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: `${lineW - hOffset}px`,
            }}
          >
            {numLabels === 5 && (
              <Link className="no-decoration" to={links[1].path}>
                <BoldLabel
                  style={{ position: "relative", bottom: `${offset}px` }}
                >
                  {links[1].label}
                </BoldLabel>
              </Link>
            )}
            <Link
              className="no-decoration"
              to={numLabels === 5 ? links[2].path : links[1].path}
            >
              <BoldLabel style={{ position: "relative", top: `${offset}px` }}>
                {numLabels === 5 ? links[2].label : links[1].label}
              </BoldLabel>
            </Link>
            <Link
              className="no-decoration"
              to={numLabels === 5 ? links[3].path : links[2].path}
            >
              <BoldLabel
                style={{ position: "relative", bottom: `${offset}px` }}
              >
                {numLabels === 5 ? links[3].label : links[2].label}
              </BoldLabel>
            </Link>
          </div>
        </div>
      </div>
      </motion.div>
    </div>
  );

  const plineRatio = 1.09;
  const plineW =
    versionToShow === 1
      ? 180
      : versionToShow === 2
      ? 280
      : versionToShow === 3
      ? 450
      : versionToShow === 4
      ? 450
      : 450;
  const plineH = plineW * plineRatio;

  // for numLabels = 5
  const labelPositions5 = [
    [
      { left: -0.1, bottom: 0.02 },
      { left: -0.15, bottom: 0.5 },
      { left: 0.58, bottom: 0.75 },
      { left: 0.45, bottom: 1.2 },
      { left: 0.1, bottom: 0.02 },
    ],
    [
      { left: -0.1, bottom: 0 },
      { left: -0.05, bottom: 0.4 },
      { left: 0.5, bottom: 0.6 },
      { left: 0.6, bottom: 1 },
      { left: 0.1, bottom: 0.03 },
    ],
    [
      { left: -0.1, bottom: 0 },
      { left: 0.05, bottom: 0.4 },
      { left: 0.5, bottom: 0.58 },
      { left: 0.6, bottom: 1 },
      { left: 0.1, bottom: 0.03 },
    ],
    [
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
    ],
    [
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
    ],
  ];

  // for numLabels = 4
  const labelPositions4 = [
    [
      { left: -0.1, bottom: 0.05 },
      { left: -0.07, bottom: 0.55 },
      { left: 0.65, bottom: 0.8 },
      { left: 0.15, bottom: 0.03 },
    ],
    [
      { left: 0, bottom: 0.02 },
      { left: 0.13, bottom: 0.52 },
      { left: 0.72, bottom: 0.78 },
      { left: 0.15, bottom: 0.03 },
    ],
    [
      { left: -0.1, bottom: 0 },
      { left: 0.15, bottom: 0.52 },
      { left: 0.75, bottom: 0.75 },
      { left: 0.1, bottom: 0.03 },
    ],
    [
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
    ],
    [
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
      { left: 0, bottom: 0 },
    ],
  ];

  const positions =
    numLabels === 4
      ? labelPositions4[versionToShow - 1]
      : labelPositions5[versionToShow - 1];

  const portraitComp = (
    <div style={{ paddingTop: "20%", paddingLeft: "5%" }}>
      <Sounds sounds={sound}/>
      <div classname="centered-content" style={{ width: "95%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginBottom: "0px",
            width: "100%",
            marginBottom: "5%",
          }}
        >
          {paragraphPortrait}
        </div>
        <div className="centered-content">
          <div
            style={{
              width: `${plineW}px`,
              //height: `${plineH}px`,
            }}
          >
            <div style={{ textAlign: "right" }}>
              <Link
                className="no-decoration"
                to={numLabels === 5 ? links[4].path : links[3].path}
              >
                <BoldLabel
                  style={{
                    position: "relative",
                    left: `${positions[numLabels - 1].left * plineW}px`,
                    bottom: `${positions[numLabels - 1].bottom * plineH}px`,
                  }}
                >
                  {numLabels === 5 ? links[4].label : links[3].label}
                </BoldLabel>
              </Link>
            </div>
            <img
              alt=""
              src={pline}
              style={{
                height: `${plineH}px`,
                width: `${plineW}px`,
              }}
            />
            <Link className="no-decoration" to={links[0].path}>
              <BoldLabel
                style={{
                  position: "relative",
                  left: `${positions[0].left * plineW}px`,
                  bottom: `${positions[0].bottom * plineH}px`,
                }}
              >
                {links[0].label}
              </BoldLabel>
            </Link>
            <Link className="no-decoration" to={links[1].path}>
              <BoldLabel
                style={{
                  position: "relative",
                  left: `${positions[1].left * plineW}px`,
                  bottom: `${positions[1].bottom * plineH}px`,
                }}
              >
                {links[1].label}
              </BoldLabel>
            </Link>

            <Link className="no-decoration" to={links[2].path}>
              <BoldLabel
                style={{
                  position: "relative",
                  left: `${positions[2].left * plineW}px`,
                  bottom: `${positions[2].bottom * plineH}px`,
                }}
              >
                {links[2].label}
              </BoldLabel>
            </Link>
            {numLabels === 5 && (
              <Link className="no-decoration" to={links[3].path}>
                <BoldLabel
                  style={{
                    position: "relative",
                    left: `${positions[3].left * plineW}px`,
                    bottom: `${positions[3].bottom * plineH}px`,
                  }}
                >
                  {links[3].label}
                </BoldLabel>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  //
  return (
    <QuestionsFrame uplabel={QUESTION_LABELS.LAST}>
      {showPortrait ? portraitComp : landscapeComp}
    </QuestionsFrame>
  );
};

export default ScreenTypeD2;
