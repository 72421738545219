import React from "react";


import ScreenTypeA from "../../components/ScreenTypeA";
import transform from "../../images/transform.jpg";
import preserve from "../../images/preserve.jpg";
import PATHS from "../../utils/constants";
import MixedParagraphs from "../../components/MixedParagraphs";


const TransformScreen = () => {

  

  return (
    <ScreenTypeA
      mainComp={MixedParagraphs.TRANSFORM}
      imageUp={transform}
      imageDown={preserve}
      textUp="transform"
      textDown="preserve"
      pathUp={PATHS.Cremation}
      pathDown={PATHS.Technology}
      useLineF={true}
      noRestart={false}
      sound={"Unusual"}
      screenAnalytics={"transform"}
    />
  );
};

export default TransformScreen;
