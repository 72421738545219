import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import Text from "../typocomps/Text";
import BoldText from "../typocomps/BoldText";
import next from "../images/icons/next.png";
import restart from "../images/icons/restart.png";
import more from "../images/icons/share.png";
import * as manager from "../utils/manager";
import * as Constants from "../utils/constants";
import PATHS, { LIBRARIES } from "../utils/constants";

import ExitIcon from "../images/icons/ExitIcon.png";

const QuestionsFrame = ({
  uplabel,
  children,
  noLeft,
  leftLabel,
  hasRight,
  rightLabel,
  onLeftButtonClick,
  onRightButtonClick,
  showAvailability,
  available,
  libraryName,
  history,
  bookLink,
  bookid,
  modalToggle
}) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);
  const iconsMargin = showPortrait ? "15px" : "20px";

  const iconsSize =
    versionToShow === 1
      ? 40
      : versionToShow === 2
      ? 40
      : versionToShow === 3
      ? 50
      : versionToShow === 4
      ? 40
      : 40;


  const library = (JSON.parse(window.localStorage.getItem("library")) - 1);

  //should set these to error msgs    
  let bookTitle = "title";
  let bookAuthor = "author"
  let bookCategory = "fiction";
  let bookDeweyNo = "000.000" //needs to get unique from library

  for (let key in Constants.books) {
    if (Constants.books[key].id === bookid) {
      bookTitle = Constants.books[key].title;
      bookAuthor = Constants.books[key].author;
      bookDeweyNo = Constants.books[key].dewey[library];
      bookCategory = Constants.books[key].category[library];
    }
  }

  const LibraryModal = (bookid, bookTitle, bookAuthor,  bookCategory, bookDeweyNo, libraryName) => {
    console.log("MODAL");
    
    let modal = document.getElementById("LibraryModal");
    modal.style.display = "none";

    console.log(modal.style.display);

    if(modal.style.display == "none"){   
      console.log("opening modal");
      modal.style.display = "flex";
      console.log(modal.style.display);
      document.getElementById("LibraryModal").innerHTML = (
        '<div id="modalWindow">' +
          "<div id='LibraryModaltitle'>" +
          "<p>Find This Book</p>" +
          //"<img src='" + ExitIcon + "'> </img>" +
          "</div>" +
          "<p>Take a photo or jot down these details and ask a library assistant to help find a copy of this title.   </p>" + "<br>" +
          "<div id='bookDetails'>" +
            "<b>Title:</b> " + bookTitle + "<br>" +
            "<b>Author:</b> " + bookAuthor + "<br>" +
            "<b>Category:</b> " + bookCategory + "<br>" +
            "<b>Shelf Mark:</b> " + bookDeweyNo + "<br>" +
          "</div>" +
          "<span id='LibraryModalcloseText'>Tap to Close</span>" +
        "</div>" 
        );
        
    }else{
      modal.innerHTML = "";
      modal.style.display = "none";
      console.log("close modal");
    }
  }


  const openCatalogue = () => {
    if(bookLink == ""){
        alert("unable to connect to library catalogue")
      }else if(bookLink == "LOCAL"){
        //alert("book: " + bookid + " - " + bookTitle + " - " + bookAuthor + " - " + bookCategory + " - " + bookDeweyNo + " - " + libraryName);
        LibraryModal(bookid, bookTitle, bookAuthor,  bookCategory, bookDeweyNo, libraryName);
    }else{
      //window.location.assign(bookLink);
      window.open(bookLink);
    }
  };

  const openShare = () => {
    console.log("open share");
  };

  const tagsStyle = {
    backgroundColor: "white",
    borderRadius: 1,
    opacity: 0.7,
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "5px",
    paddingRight: "5px",
  };

  return (
    <div
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      {showAvailability && (
        <div
          className={showPortrait ? "column" : "row-centered"}
          style={{
            position: "absolute",
            top: iconsMargin,
            left: iconsMargin,
          }}
        >
          {libraryName !== LIBRARIES.GUEST.name && (
            <div
              style={{
                ...tagsStyle,
                marginRight: showPortrait ? "0px" : "5px",
                marginBottom: showPortrait ? "5px" : "0px",
              }}
            >
              <Text>
                {available
                  ? `Available at ${libraryName}`
                  : `Not Available at ${libraryName}`}
              </Text>
            </div>
          )}
          {libraryName !== LIBRARIES.GUEST.name && available ? (
            <div
              style={{ ...tagsStyle, cursor: "pointer" }}
              onClick={() => openCatalogue()}
            >
              <Text>View in Catalogue</Text>
            </div>
          ) : null}
        </div>
      )}
      {uplabel && (
        <div
          className="row-centered"
          style={{
            position: "absolute",
            right: iconsMargin,
            top: iconsMargin,
            cursor: uplabel === "share" ? "pointer" : "",
          }}
          onClick={() => uplabel === "share" && openShare()}
        >
          <Text>{uplabel}</Text>
          <img
            alt=""
            src={more}
            style={{ width: `${iconsSize + 10}px`, marginLeft: "5px" }}
          />
        </div>
      )}
      <div style={{ height: "100%" }}>{children}</div>
      {!noLeft && (
        <div
          className="row-centered"
          onClick={() =>
            onLeftButtonClick
              ? onLeftButtonClick()
              : history.push(PATHS.Conventional)
          }
          style={{
            position: "absolute",
            bottom: iconsMargin,
            left: iconsMargin,
            cursor: "pointer",
          }}
        >
          <img
            alt=""
            src={restart}
            style={{ width: `${iconsSize}px`, marginRight: "5px" }}
          />
          <BoldText>{leftLabel || "restart"}</BoldText>
        </div>
        //      </Link>
      )}
      {hasRight && (
        <div
          className="row-centered"
          onClick={onRightButtonClick}
          style={{
            position: "absolute",
            //right: `${iconsMargin - 5}px`,
            right: iconsMargin,
            bottom: iconsMargin,
            height: `${iconsSize}px`,
            cursor: "pointer",
          }}
        >
          <BoldText>{rightLabel}</BoldText>
          <img
            alt=""
            src={next}
            style={{
              width: `${iconsSize}px`,
              marginLeft: "7px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(QuestionsFrame);
