import React from "react";

import ScreenTypeB from "../../components/ScreenTypeB";
import { QUESTION_LABELS } from "../../utils/constants";
import shareB from "../../images/share-b.png";
import shareU from "../../images/share-u.png";
import PATHS from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";

const SharingScreen = () => {
  return (
    <ScreenTypeB
      text="What would you like your community to"
      word="share?"
      textUp={<BoldLabel>a virtual space</BoldLabel>}
      textDown={<BoldLabel>a common goal</BoldLabel>}
      pathUp={PATHS.Games}
      pathDown={PATHS.Tickets + "16"}
      imageU={shareU}
      imageB={shareB}
      questionLabel={QUESTION_LABELS.LAST}
      imageURatio={1.1}
      imageBRatio={1.13}
      sound="Community"
    />
  );
};

export default SharingScreen;
