import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import xray from "../../images/xray.jpg";
import line from "../../images/lines/lineH2pt.png";
import PATHS from "../../utils/constants";

const ManualScreen = () => {
  return (
    <WiredChoices
      labelUp={
        <BoldLabel>
          robots to perform <br />
          manual tasks to <br />
          aid humans
        </BoldLabel>
      }
      labelDown={
        <BoldLabel>
          robots to aid <br />
          humans in their <br />
          quest to immortality
        </BoldLabel>
      }
      pathUp={PATHS.Tickets + "23"}
      pathDown={PATHS.Tickets + "24"}
      image={xray}
      line={line}
      questionLabel={QUESTION_LABELS.LAST}
      labelNumlines={3}
      sound="Robots"
    />
  );
};

export default ManualScreen;
