import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import curtain from "../../images/curtain.jpg";
import lineC from "../../images/lines/lineC2pt.png";
import PATHS from "../../utils/constants";

const StoneScreen = () => {
  return (
    <WiredChoices
      labelUp={<BoldLabel>precious stone</BoldLabel>}
      labelDown={<BoldLabel>ink</BoldLabel>}
      pathUp={PATHS.Tickets + "38"}
      pathDown={PATHS.Tickets + "39"}
      image={curtain}
      line={lineC}
      questionLabel={QUESTION_LABELS.LAST}
      labelNumlines={1}
      sound="Memory"
    />
  );
};

export default StoneScreen;
