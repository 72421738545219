import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import QuestionsFrame from "../components/QuestionsFrame";
import past from "../images/past.jpg";
import modern from "../images/sea.jpg";
import lineB from "../images/lines/lineB2pt.png";
import PATHS from "../utils/constants";
import * as manager from "../utils/manager";
import BoldLabel from "../typocomps/BoldLabel";
import Title from "../typocomps/Title";
import Image from "../components/Image";
import Sounds from "../components/Sounds";


const TraditionsScreen = () => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const lineHeight =
    versionToShow === 1
      ? 1.8
      : versionToShow === 2
      ? 2
      : versionToShow === 3
      ? 2.4
      : versionToShow === 4
      ? 2.4
      : 2.4;

  const lparagraph = (
    <Title style={{ lineHeight: `${lineHeight}rem` }}>
      Do you prefer <br /> traditions from <br /> the past or modern <br />
      practices?
    </Title>
  );

  const pparagraph = (
    <Title style={{ lineHeight: `${lineHeight}rem` }}>
      Do you prefer <br /> traditions from the past
      <br /> or modern practices?
    </Title>
  );

  /******************************************** LANDSCAPE **************************************************/

  const lineW =
    versionToShow === 1
      ? 60
      : versionToShow === 2
      ? 60
      : versionToShow === 3
      ? 120
      : versionToShow === 4
      ? 120
      : 120;

  const imageW =
    versionToShow === 1
      ? 80
      : versionToShow === 2
      ? 80
      : versionToShow === 3
      ? 120
      : versionToShow === 4
      ? 140
      : 120;

  const imageRatio = 280 / 280; // 280, 280
  const lineRatio = 1228 / 2616; // 1228, 2616
  const imageH = imageW / imageRatio;
  const lineH = lineW / lineRatio;

  const textMarginRight =
    versionToShow === 1
      ? 20
      : versionToShow === 2
      ? 20
      : versionToShow === 3
      ? 140
      : versionToShow === 4
      ? 140
      : 140;

  const textMarginTop =
    versionToShow === 1
      ? 20
      : versionToShow === 2
      ? 20
      : versionToShow === 3
      ? 50
      : versionToShow === 4
      ? 50
      : 50;

  const landscapeComp = (
    <div>
      <Sounds sounds={"Traditional"}/>
      <div className="row">
        <div
          style={{
            marginRight: `${textMarginRight}px`,
            paddingTop: `${textMarginTop}px`,
          }}
        >
          {lparagraph}
        </div>
        <div className="row">
          <Link to={PATHS.Photography} className="no-decoration">
            <div
              style={{
                width: "150px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Image
                alt=""
                src={past}
                style={{
                  height: `${imageH}px`,
                  width: `${imageW}px`,
                  marginTop: "0px",
                  marginBottom: 0,
                }}
              />
              <BoldLabel>past traditions</BoldLabel>
            </div>
          </Link>
          <div style={{ paddingTop: "50px" }}>
            <img
              alt=""
              src={lineB}
              style={{
                height: `${lineH}px`,
                width: `${lineW}px`,
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="single-end-of-row"
        style={{
          marginRight: "-70px",
          marginTop: "-20px",
        }}
      >
        <Link to={PATHS.Funerary} className="no-decoration">
          <Image
            alt=""
            src={modern}
            style={{ height: `${imageH}px`, width: `${imageW}px` }}
          />
          <BoldLabel>modern practices</BoldLabel>
        </Link>
      </div>
    </div>
  );

  /*********************************** PORTRAIT ********************************/

  const pimageW = versionToShow === 1 ? 90 : versionToShow === 2 ? 90 : 150;
  const plineW = versionToShow === 1 ? 80 : versionToShow === 2 ? 90 : 120;

  const ptextBottom = versionToShow === 1 ? 10 : versionToShow === 2 ? 50 : 50;
  const bottomImageR =
    versionToShow === 1 ? 60 : versionToShow === 2 ? 20 : -20;

  const pimageH = pimageW / imageRatio;
  const plineH = plineW / lineRatio;

  const portraitComp = (
    <div>
      <Sounds sounds={"Traditional"}/>
      <div className={"column"}>
        <div
          style={{
            paddingBottom: `${ptextBottom}px`,
          }}
        >
          {pparagraph}
        </div>
        <div className="row">
          <Link to={PATHS.Photography} className="no-decoration">
            <div
              style={{
                width: `${pimageW + 20}px`,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Image
                alt=""
                src={past}
                style={{
                  height: `${pimageH}px`,
                  width: `${pimageW}px`,
                  marginTop: "0px",
                  marginBottom: 0,
                }}
              />
              <BoldLabel>past traditions</BoldLabel>
            </div>
          </Link>
          <div style={{ paddingTop: "50px" }}>
            <img
              alt=""
              src={lineB}
              style={{
                height: `${plineH}px`,
                width: `${plineW}px`,
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="single-end-of-row"
        style={{
          marginRight: `${-1 * bottomImageR}px`,
          marginTop: "-20px",
        }}
      >
        <Link to={PATHS.Funerary} className="no-decoration">
          <Image
            alt=""
            src={modern}
            style={{ height: `${pimageH}px`, width: `${pimageW}px` }}
          />
          <BoldLabel>modern practices</BoldLabel>
        </Link>
      </div>
    </div>
  );
  return (
    <QuestionsFrame>
      <div className="centered-content">
        {showPortrait ? portraitComp : landscapeComp}
      </div>
    </QuestionsFrame>
  );
};

export default TraditionsScreen;
