import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import newcastle from "../images/libraries/newcastle.jpg";
import kirklee from "../images/libraries/kirklees.jpg";
import redbridge from "../images/libraries/redbridge.jpg";
import LargeTitle from "../typocomps/LargeTitle";
import VeryLargeTitle from "../typocomps/VeryLargeTitle";
import PATHS, { LIBRARIES } from "../utils/constants";
import QuestionsFrame from "../components/QuestionsFrame";
import * as manager from "../../src/utils/manager";
import Image from "../components/Image";

const BooksIntroScreen = ({ history, match }) => {
  const tid = match ? parseInt(match.params.id) : 0;

  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 120
      : versionToShow === 2
      ? 160
      : versionToShow === 3
      ? 180
      : versionToShow === 4
      ? 180
      : 180;
  const imageH = 1.0 * imageW;

  const goToAllTickets = () => {
    history.push(PATHS.AllTickets);
  };

  const goToBooks = () => {
    history.push(PATHS.Books + tid);
  };

  const LibComp = ({ image, style }) => {
    return (
      <div style={{ ...style }}>
        <Image
          alt=""
          src={image}
          style={{
            width: `${imageW}px`,
            height: `${imageH}px`,
            objectFit: "contain",
            height: "auto",
          }}
        />
      </div>
    );
  };

  const landscapeComp = (
    <QuestionsFrame
      hasRight={true}
      leftLabel="all tickets"
      rightLabel="books"
      onRightButtonClick={() => goToBooks()}
      onLeftButtonClick={() => goToAllTickets()}
    >
      <div className="centered-content">
        <div style={{ width: "80%" }}>
          <div className="row spaced" style={{ marginBottom: "3%" }}>
            <LibComp image={newcastle} name={LIBRARIES.NEWCASTLE} />
            <LibComp image={kirklee} name={LIBRARIES.KIRKLEES} />
            <LibComp image={redbridge} name={LIBRARIES.REDBRIGDE} />
          </div>

          <LargeTitle
            SmallText
            style={{
              textAlign: "center",
            }}
          >
            Our library partners have <br /> curated a selection of books <br />
            based on your ticket.
          </LargeTitle>
        </div>
      </div>
    </QuestionsFrame>
  );

  const portraitComp = (
    <QuestionsFrame
      hasRight={true}
      leftLabel="all tickets"
      rightLabel="books"
      onRightButtonClick={() => goToBooks()}
      onLeftButtonClick={() => goToAllTickets()}
    >
      <div className="centered-content">
        <VeryLargeTitle
          SmallText
          style={{
            textAlign: "left",
          }}
        >
          Our library <br /> partners have <br /> curated a selection <br /> of
          books based <br /> on your ticket.
        </VeryLargeTitle>
      </div>
    </QuestionsFrame>
  );

  return showPortrait && versionToShow <= 2 ? portraitComp : landscapeComp;
};

export default BooksIntroScreen;
