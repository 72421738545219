import React from "react";
import { useMediaQuery } from "react-responsive";

import TwoVerticalOptions from "./TwoVerticalOptions";
import TwoHorizontalOptions from "./TwoHorizontalOptions";
import QuestionsFrame from "./QuestionsFrame";
import * as manager from "../utils/manager";
import HugeTitle from "../typocomps/HugeTitle";
import Label from "../typocomps/Label";
import Sounds from "../components/Sounds";
import ScreenAnalytics from "./ScreenAnalytics";

import { motion } from "framer-motion"

const ScreenTypeB = ({
  text,
  word,
  textUp,
  textDown,
  pathUp,
  pathDown,
  imageU,
  imageB,
  imageURatio,
  imageBRatio,
  questionLabel,
  sound
}) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  /************************************ landscape *************************************/

  const uimageW =
    versionToShow === 1
      ? 20
      : versionToShow === 2
      ? 20
      : versionToShow === 3
      ? 105
      : versionToShow === 4
      ? 105
      : 105;
  const uimageH = uimageW * imageURatio;

  const bimageW =
    versionToShow === 1
      ? 100
      : versionToShow === 2
      ? 20
      : versionToShow === 3
      ? 105
      : versionToShow === 4
      ? 200
      : 105;
  const bimageH = bimageW * imageBRatio;

  const optionsW =
    versionToShow === 1
      ? 170
      : versionToShow === 2
      ? 170
      : versionToShow === 3
      ? 170
      : versionToShow === 4
      ? 170
      : 170;

  const landscapeComp = (

    <div className="centered-content">
    <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
    <Sounds sounds={sound}/>
      <div>
        <div className="row" style={{ alignItems: "flex-end" }}>
          <Label>{text}</Label>
          <img
            alt=""
            src={imageU}
            style={{ height: `${uimageH}px`, width: `${uimageW}px` }}
          />
        </div>
        <div
          className="row"
          style={{ alignItems: "flex-start", marginTop: "5%" }}
        >
          <HugeTitle>{word}</HugeTitle>
          <img
            alt=""
            src={imageB}
            style={{ height: `${bimageH}px`, width: `${bimageW}px` }}
          />

          <div style={{ marginLeft: "3%", width: `${optionsW}px` }}>
            <TwoVerticalOptions
              textUp={textUp}
              textDown={textDown}
              pathUp={pathUp}
              pathDown={pathDown}
            />
          </div>
        </div>
      </div>
      </motion.div>
    </div>

  );

  /****************************************** Portrait *******************************************/

  const puimageW =
    versionToShow === 1
      ? 100
      : versionToShow === 2
      ? 120
      : versionToShow === 3
      ? 180
      : versionToShow === 4
      ? 105
      : 105;
  const puimageH = puimageW * imageURatio;

  const pbimageW =
    versionToShow === 1
      ? 60
      : versionToShow === 2
      ? 80
      : versionToShow === 3
      ? 130
      : versionToShow === 4
      ? 200
      : 105;
  const pbimageH = pbimageW * imageBRatio;

  const poptionsW =
    versionToShow === 1
      ? 120
      : versionToShow === 2
      ? 120
      : versionToShow === 3
      ? 170
      : versionToShow === 4
      ? 170
      : 170;

  const portraitComp = (
    <div style={{ padding: "5%", paddingTop: "10%", paddingLeft: "10%" }}>
      <Sounds sounds={sound}/>
      <div
        style={
          {
            //          height: "50%",
            //        display: "flex",
            //      flexDirection: "column",
            //    justifyContent: "flex-end",
          }
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "12%",
            marginBottom: "10%",
          }}
        >
          <img
            alt=""
            src={imageU}
            style={{ height: `${puimageH}px`, width: `${puimageW}px` }}
          />
        </div>
        <div>
          <Label style={{ marginBottom: "3%" }}>{text}</Label>
          <HugeTitle style={{ marginBottom: "10%" }}>{word}</HugeTitle>
        </div>
        <div style={{ width: `${poptionsW}px` }}>
          <TwoVerticalOptions
            textUp={textUp}
            textDown={textDown}
            pathUp={pathUp}
            pathDown={pathDown}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10%",
            marginTop: "-10%",
          }}
        >
          <img
            alt=""
            src={imageB}
            style={{ height: `${pbimageH}px`, width: `${pbimageW}px` }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <QuestionsFrame upLabel={questionLabel}>
      {showPortrait ? portraitComp : landscapeComp}
    </QuestionsFrame>
  );
};

export default ScreenTypeB;
