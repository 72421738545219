import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketTypePortrait2 = ({
  id,
  avail,
  cost,
  title,
  text,
  image,
  imageRatio,
  psTitle,
  psParPosition, // 1: center, 2: left, 3: 50% left
  psTitlePosition, // 1: with paragraph, 3: 50% left
  psImagePosition, // 1: left, 2: center, 3: 50% left
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.PORTRAIT[versionToShow - 1][id];

  const { TICKETS_CONTENT_MARGIN: margin } = manager.SIZES(useMediaQuery);

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      title={title}
      id={id}
    >
      <div
        style={{
          position: "relative",
          top: "15%",
          paddingLeft: `${margin}px`,
          paddingRight: `${margin}px`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent:
              psImagePosition === 1
                ? "flex-start"
                : psImagePosition === 2
                ? "center"
                : "flex-end",
            width: "100%",
            marginBottom: "5%",
          }}
        >
          <div>
            <img
              alt=""
              src={image}
              style={{ height: `${IMAGE_H}px`, width: `${IMAGE_W}px` }}
            />
            {psTitlePosition === 3 && (
              <div style={{ marginTop: "5%" }}>{psTitle ? psTitle : title}</div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent:
              psParPosition === 1
                ? "flex-start"
                : psParPosition === 2
                ? "center"
                : "flex-start",
            width: "100%",
          }}
        >
          <div>
            {psTitlePosition === 1 && (
              <div style={{ marginBottom: "10%" }}>
                {psTitle ? psTitle : title}
              </div>
            )}
            {text}
          </div>
        </div>
      </div>
    </Ticket>
  );
};

export default TicketTypePortrait2;
