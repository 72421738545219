import React from "react";
import { Link } from "react-router-dom";

import BoldText from "../typocomps/BoldText";
import PATHS from "../utils/constants";
import facebook from "../images/icons/facebook.png";
import twitter from "../images/icons/twitter.png";
import instagram from "../images/icons/instagram.png";

import Text from "../typocomps/Text";
import BoldLabel from "../typocomps/BoldLabel";
import Image from "../components/Image";



const SocialLinks = () => {

    const librayID  = JSON.parse(window.localStorage.getItem("library"));

    if(librayID <= 3){
        console.log("Library ID: " + librayID);
        console.log("social links ONLINE VERSION");

        return(
            <div style={{width: "85%", margin: "auto", display: "flex", flexWrap: "wrap"}}>
                <a target="_blank" href="https://twitter.com/deathpositivel1" style={{textDecoration: "none", width: "100%"}}>
                    <div style={{display: "flex", alignItems: "center", marginBottom: "2%", width: "100%"}}>
                        <Image
                            alt=""
                            src={twitter}
                            style={{
                                height: `30px`,
                                width: `30px`,
                                padding: "0px",
                            }}
                            />
                        <Text style={{marginLeft: "10px"}}>@deathpositivel1</Text>
                    </div>
                </a>
                <a target="_blank" href="https://www.facebook.com/DeathPositiveLibrary/" style={{textDecoration: "none", width: "100%"}}>
                    <div style={{display: "flex", alignItems: "center" , marginBottom: "2%" , width: "100%"}}>
                        <Image
                            alt=""
                            src={facebook}
                            style={{
                            height: `30px`,
                            width: `30px`,
                            padding: "0px",
                            }}
                        />
                        <Text style={{marginLeft: "10px"}}>@DeathPositiveLibrary</Text>
                    </div>
                </a>
                <a target="_blank" href="https://www.instagram.com/thedeathpositivelibrary/" style={{textDecoration: "none", width: "100%"}}>
                    <div style={{display: "flex", alignItems: "center" , marginBottom: "2%" , width: "100%"}}>
                        <Image
                            alt=""
                            src={instagram}
                            style={{
                            height: `30px`,
                            width: `30px`,
                            padding: "0px",
                            }}
                        />
                        <Text style={{marginLeft: "10px"}}>@thedeathpositivelibrary</Text>
                    </div>
                </a>
            </div>
        );
    }else{
        console.log("Library ID: " + librayID);
        console.log("social links library VERSION");

        return(
            <div style={{width: "85%", margin: "auto", display: "flex", flexWrap: "wrap"}}>
            <div style={{display: "flex", alignItems: "center", marginBottom: "2%", width: "100%"}}>
              <Image
                  alt=""
                  src={twitter}
                  style={{
                    height: `30px`,
                    width: `30px`,
                    padding: "0px",
                  }}
                />
              <Text style={{marginLeft: "10px"}}>@deathpositivel1</Text>
            </div>
            <div style={{display: "flex", alignItems: "center" , marginBottom: "2%" , width: "100%"}}>
              <Image
                alt=""
                src={facebook}
                style={{
                  height: `30px`,
                  width: `30px`,
                  padding: "0px",
                }}
              />
              <Text style={{marginLeft: "10px"}}>@DeathPositiveLibrary</Text>
            </div>
            <div style={{display: "flex", alignItems: "center" , marginBottom: "2%" , width: "100%"}}>
              <Image
                alt=""
                src={instagram}
                style={{
                  height: `30px`,
                  width: `30px`,
                  padding: "0px",
                }}
              />
              <Text style={{marginLeft: "10px"}}>@thedeathpositivelibrary</Text>
            </div>
          </div>
        );
    }
}

export default SocialLinks;