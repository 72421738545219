import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import embracing from "../../images/embracing.jpg";
import line from "../../images/lines/lineD2pt.png";
import PATHS from "../../utils/constants";

const ReversingScreen = () => {
  return (
    <WiredChoices
      labelUp={<BoldLabel>reversing death</BoldLabel>}
      labelDown={<BoldLabel>embracing death</BoldLabel>}
      pathUp={PATHS.Body}
      pathDown={PATHS.Creating}
      image={embracing}
      line={line}
      questionLabel={QUESTION_LABELS.ONEMORE}
      labelNumlines={1}
      sound="Technology"
    />
  );
};

export default ReversingScreen;
