import React from "react";
import { useMediaQuery } from "react-responsive";

import * as manager from "../utils/manager";

const Title = ({ children, style }) => {
  const classPostfix = manager.classPostfix(useMediaQuery);
  const cname = "title" + classPostfix;

  return (
    <div>
      <p className={cname} style={{ ...style }}>
        {children}
      </p>
    </div>
  );
};

export default Title;
