import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import upperbody from "../../images/upperbody.jpg";
import line from "../../images/lines/lineC2pt.png";
import PATHS from "../../utils/constants";

const LeaveScreen = () => {
  return (
    <WiredChoices
      labelUp={
        <BoldLabel>
          leave a part <br />
          of you behind
        </BoldLabel>
      }
      labelDown={
        <BoldLabel>
          keeping your <br />
          loved ones close
        </BoldLabel>
      }
      pathUp={PATHS.Tickets + "5"}
      pathDown={PATHS.Tickets + "6"}
      image={upperbody}
      line={line}
      questionLabel={QUESTION_LABELS.LAST}
      labelNumlines={2}
      sound="LeaveAPart"
    />
  );
};

export default LeaveScreen;
