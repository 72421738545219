import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import ScreenTypeD2 from "../../components/ScreenTypeD2";
import Title from "../../typocomps/Title";
import PATHS from "../../utils/constants";
import experience from "../../images/experience.jpg";
import remembrance from "../../images/remembrance.jpg";

//const paths = [
//  { limit: 0.2, path: PATHS.Tickets + "31" },
//  { limit: 0.4, path: PATHS.Tickets + "32" },
//  { limit: 0.6, path: PATHS.Tickets + "33" },
//  { limit: 0.8, path: PATHS.Tickets + "34" },
//  { limit: 1, path: PATHS.Tickets + "35" },
//];

const links = [
  { label: "experience", path: PATHS.Tickets + "31" },
  { label: "recreation", path: PATHS.Tickets + "32" },
  { label: "memory", path: PATHS.Tickets + "33" },
  { label: "object", path: PATHS.Tickets + "34" },
  { label: "mark", path: PATHS.Tickets + "35" },
];

const paragraph = (
  <Title>
    Do you want your memories to
    <br />
    inspire an experinece or to be
    <br />
    used as a form of remembrance?
  </Title>
);

const ExperienceScreen = ({ history }) => {
  return (
    <ScreenTypeD2
      history={history}
      paragraph={paragraph}
      paragraphPortrait={paragraph}
      imageLeft={experience}
      imageRight={remembrance}
      links={links}
      sound="No"
    />
  );
};

export default ExperienceScreen;
