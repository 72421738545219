import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import * as manager from "../../src/utils/manager";
import PATHS, { LIBRARIES } from "../utils/constants";
import TicketsData from "./TicketsData";
import SmallText from "../typocomps/SmallText";
import HugeTitle from "../typocomps/HugeTitle";
import Title from "../typocomps/Title";
import MyTicket from "../components/MyTicket";

//all ticket images
import AllTickets from "../utils/ticketImages.js";
import ExitIcon from "../images/icons/ExitIconLight.png";
import Sounds from "../components/Sounds";

const ThankYouScreen = ({ history }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 80
      : versionToShow === 2
      ? 80
      : versionToShow === 3
      ? 120
      : versionToShow === 4
      ? 180
      : 120;
  const imageH = 1.0 * imageW;

  const goNext = () => {
    history.push(PATHS.Start);
  };

  const ticketPreview = (index) => {
    console.log("els");
    if( document.getElementById("PreviewWindow" + index).style.display == "none"){
      let els = document.getElementsByClassName("PreviewWindows");
      Array.prototype.forEach.call(els, function(el) {
        el.style.display = "none";
        console.log("els" + el.tagName);
      });
      document.getElementById("PreviewWindow" + index).style.display = "flex";
    }else{
      document.getElementById("PreviewWindow" + index).style.display = "none";
    }
  }

  const numColumns = versionToShow <= 2 ? 3 : 4;
  const numRows = Math.ceil(50 / numColumns);
  const ticketNames = [];

  let count = 0;
  let column = [];

  for (let ticket of TicketsData) {
    if (count < numRows) {
      column.push(ticket.name);
      count++;
    } else {
      ticketNames.push([...column]);
      count = 1;
      column = [];
      column.push(ticket.name);
    }
  }
  ticketNames.push(column);

  const landscapeComp = (
    <div>
      <Sounds sounds={"AllTickets"} />
      <div
        className="single-end-of-row"
        style={{ marginRight: "10%", paddingTop: "5%" }}
      >
        <HugeTitle>All tickets</HugeTitle>
      </div>
      <MyTicket></MyTicket>
      <div className="row" style={{ width: "", padding: "5%" }}>
        {ticketNames.map((tickets, colIndex) => (
          <div
            className="column"
            style={{
              width: "100%",
            }}
          >
            {tickets.map((ticket, rowIndex) => (
              <Link
                className="no-decoration" 
                onClick={ticketPreview.bind(this, (rowIndex + numRows * colIndex + 1))}
              >
                <SmallText  className="allTicketText" style={{ marginBottom: "1%"}}>{ticket}
                  <div className="PreviewWindows" id={"PreviewWindow" + (rowIndex + numRows * colIndex + 1)} 
                    style={{
                      display: "none",
                      margin: "5px 5px 5px 0", 
                      padding: "5px", 
                      transition: "all 1s ease", 
                      flexWrap: "wrap", 
                      background: "#f4f4f2",
                      borderRadius: ".5rem",
                      padding: "1rem",
                    }}>
                    <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "5px"}}>
                      <Title style={{fontSize: "200%", width: "90%", height: "100%", display: "flex", alignItems: "center"}}>{ticket} </Title>
                      <span style={{width: "8%", textAlign: "right", height: "2rem", width: "2rem"}}>
                        <img src={ExitIcon} style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: ".5rem", overflow:"hidden"}}/>
                      </span>
                    </div>
                    <img src={ AllTickets[(rowIndex + numRows * colIndex + 1)] } style={{ width: "100%", maxHeight: "200px", overflow: "hidden", objectFit: "cover",borderRadius: ".3rem"}} />
                    <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: ".5rem"}}>
                      <Link to={`${PATHS.Tickets}${rowIndex + numRows * colIndex + 1}`} className="no-decoration" >
                        <p style={{margin: "auto", borderRadius: ".2rem", padding: ".5rem", color: "#f4f4f2", background: "#282828", }}>See Full Ticket</p>
                      </Link>
                    </div>
                  </div>
                </SmallText>
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );

  const portraitComp = (
    <div style={{ margin: "5%" }}>
    <Sounds sounds={"AllTickets"} />
      <div style={{ marginRight: "10%", paddingTop: "5%" }}>
        <HugeTitle>All tickets</HugeTitle>
      </div>
      <MyTicket></MyTicket>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {ticketNames
          .toString()
          .split(",")
          .map((ticket, index) => (
            <div>
              <Link
                className="no-decoration" 
                onClick={ticketPreview.bind(this, (index + 1))}
              >
                <SmallText  className="allTicketText" style={{ marginBottom: "1%"}}>{ticket}
                  <div className="PreviewWindows" id={"PreviewWindow" + (index + 1)} 
                    style={{
                      display: "none",
                      margin: "5px 5px 5px 0", 
                      padding: "5px", 
                      transition: "all 1s ease", 
                      flexWrap: "wrap", 
                      background: "#f4f4f2",
                      borderRadius: ".5rem",
                      padding: "1rem",
                    }}>
                    <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "5px"}}>
                      <Title style={{fontSize: "200%", width: "90%", height: "100%", display: "flex", alignItems: "center"}}>{ticket} </Title>
                      <span style={{width: "8%", textAlign: "right", height: "2rem", width: "2rem"}}>
                        <img src={ExitIcon} style={{ height: "100%", width: "100%", objectFit: "cover"}}/>
                      </span>
                    </div>
                    <img src={ AllTickets[(index + 1)] } style={{ width: "100%", maxHeight: "20rem", overflow: "hidden", objectFit: "cover"}} />
                    <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: ".5rem"}}>
                      <Link to={`${PATHS.Tickets}${index + 1}`} className="no-decoration" >
                        <p style={{margin: "auto", borderRadius: ".2rem", padding: ".5rem", color: "#f4f4f2", background: "#282828", }}>See Full Ticket</p>
                      </Link>
                    </div>
                  </div>
                </SmallText>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );

  const onRestartClick = () => {
    history.push(PATHS.Conventional);
  };

  return <div>{showPortrait ? portraitComp : landscapeComp}</div>;
};

export default ThankYouScreen;
