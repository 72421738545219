import React from "react";
import { useMediaQuery } from "react-responsive";

import * as device from "../utils/device";

const AppFrame = ({ children }) => {
  const { isMobile, isTablet } = device.deviceType(useMediaQuery);

  return (
    <div style={{ height: "100%" }}>
      {!isMobile && !isTablet ? (
        <main className="container">
          <div className="wrapper">{children}</div>
        </main>
      ) : (
        <main className="mobile-container">{children}</main>
      )}
    </div>
  );
};
export default AppFrame;
