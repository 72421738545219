import React from "react";
import { useMediaQuery } from "react-responsive";

import * as device from "../../src/utils/device";
import * as manager from "../../src/utils/manager";
import PATHS from "../utils/constants";
import BooksLandscape from "./BooksLandscape";
import BooksPortrait from "./BooksPortrait";
import TicketsData from "./TicketsData";
import Image from "../components/Image";

const BooksScreen = ({ match, history }) => {
  const tid = match ? parseInt(match.params.id) : 1;
  //const data = TicketsData[id - 1];


  const ids = TicketsData[tid - 1].books;

  const { showPortrait } = manager.getVersionToShow(useMediaQuery);

  const onAllTicketsClick = () => {
    history.push(PATHS.AllTickets);
  };

  const onContinueClick = () => {
    history.push(PATHS.Thanks);
  };

  return showPortrait ? (
    <BooksPortrait
      ids={ids}
      onRightButtonClick={() => onContinueClick()}
      onLeftButtonClick={() => onAllTicketsClick()}
      sound="Books"
      //
    />
  ) : (
    <BooksLandscape
      ids={ids}
      onRightButtonClick={() => onContinueClick()}
      onLeftButtonClick={() => onAllTicketsClick()}
      sound="Books"
    />
  );
};

export default BooksScreen;
