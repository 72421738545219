import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketType3 = ({
  id,
  avail,
  cost,
  title,
  text,
  image,
  //imageW,
  imageRatio,
  left,
  titleLevel,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.LANDSCAPE[versionToShow - 1][id];

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      text={text}
      image={image}
      id={id}
      title={title}
    >
      <div
        style={{
          paddingLeft: left === 1 ? "25%" : "50%",
          height: "100%",
          width: "35%",
        }}
      >
        <div
          style={{
            position: "relative",
            top: titleLevel === 1 || titleLevel == 2 ? "25%" : "0%",
            height: "25%",
            display: "flex",
            flexDirection: "column",
            justifyContent:
              titleLevel === 2 || titleLevel === 3 ? "flex-end" : "flex-start",
          }}
        >
          <div>{title}</div>

          {titleLevel === 1 && (
            <div>
              <div style={{ marginTop: "7%" }}>{text}</div>
              <div
                style={{
                  marginTop: "7%",
                }}
              >
                <Image
                  alt=""
                  src={image}
                  style={{ height: `${IMAGE_H}px`, width: `${IMAGE_W}px` }}
                />
              </div>
            </div>
          )}
        </div>
        {titleLevel !== 1 && (
          <div
            style={{
              position: "relative",
              top: titleLevel === 1 || titleLevel == 2 ? "25%" : null,
              marginTop: "7%",
            }}
          >
            {text}
            <div
              style={{
                marginTop: "7%",
              }}
            >
              <Image
                alt=""
                src={image}
                style={{ height: `${IMAGE_H}px`, width: `${IMAGE_W}px` }}
              />
            </div>
          </div>
        )}
      </div>
    </Ticket>
  );
};

export default TicketType3;
