import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import upperbody from "../../images/upperbody.jpg";
import lineC from "../../images/lines/lineC2pt.png";
import PATHS from "../../utils/constants";

const PreservingScreen = () => {
  return (
    <WiredChoices
      labelUp={
        <BoldLabel>
          preserving the <br />
          living image
        </BoldLabel>
      }
      labelDown={
        <BoldLabel>
          capturing <br />
          the spirit
        </BoldLabel>
      }
      pathUp={PATHS.Tickets + "1"}
      pathDown={PATHS.Tickets + "2"}
      image={upperbody}
      line={lineC}
      questionLabel={QUESTION_LABELS.LAST}
      labelNumlines={2}
      sound="Preserving"
    />
  );
};

export default PreservingScreen;
