import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import TicketContent from "./TicketContent";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketTypePortrait5 = ({
  id,
  avail,
  cost,
  title,
  text,
  image,
  imageRatio,
  titlev2,
  imagePosition,
  titlePosition,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.PORTRAIT[versionToShow - 1][id];

  const {
    WIDHT: W,
    HEIGHT: H,
    TICKETS_CONTENT_MARGIN: margin,
  } = manager.SIZES(useMediaQuery);

  const tmpTitle = versionToShow <= 2 && titlev2 ? titlev2 : title;

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      title={title}
      id={id}
    >
      <div
        style={{
          paddingLeft: `${margin}px`,
          paddingRight: `${margin}px`,
          //          position: "absolute",
          //         left: 0,
          //         right: 0,
          //        top: 0,
          //        bottom: 0,
          boxSizing: "border-box",
          height: "100%",
          width: "100%",
          paddingTop: "15%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems:
              imagePosition === 0
                ? "flex-start"
                : imagePosition === 1
                ? "flex-end"
                : "center",
            width: "100%",
          }}
        >
          <Image
            alt=""
            src={image}
            style={{
              height: `${IMAGE_H}px`,
              width: `${IMAGE_W}px`,
              backgroundColor: "green",
            }}
          />
          {titlePosition === 0 && (
            <div style={{ marginTop: "5%", width: `${IMAGE_W}px` }}>
              {tmpTitle}
            </div>
          )}
        </div>
        <div
          style={{
            position: "relative",
            top: "10%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "70%" }}>
              {titlePosition === 1 && <div>{tmpTitle}</div>}
              <div style={{ marginTop: "3%" }}>{text}</div>
            </div>
          </div>
        </div>
      </div>
    </Ticket>
  );
};

export default TicketTypePortrait5;
