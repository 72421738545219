import React from "react";

import ChooseScreen from "../../components/ChooseScreen";
import photography from "../../images/photography.jpg";
import objects from "../../images/objects.jpg";
import burial from "../../images/burial.jpg";
import PATHS from "../../utils/constants";
import Sounds from "../../components/Sounds";

const PhotographyScreen = () => {
  return (
    <ChooseScreen
      optionsData={{
        option1: {
          label: "photography",
          image: photography,
          path: PATHS.Preserving,
        },

        option2: {
          label: "objects",
          image: objects,
          path: PATHS.Afterlife,
        },
        option3: {
          label: "burial",
          image: burial,
          path: PATHS.Community,
        },
      }}
      rotateImagesForPortrait={true}
      rotateImagesForMobileLandscape={true}
      imageTypeSmall={false}
      sound="PastTraditions"
    />
  );
};

export default PhotographyScreen;
