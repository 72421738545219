import React from "react";
import { Link } from "react-router-dom";

import BoldText from "../typocomps/BoldText";
import PATHS from "../utils/constants";
import facebook from "../images/icons/facebook.png";
import twitter from "../images/icons/twitter.png";
import instagram from "../images/icons/instagram.png";

import Text from "../typocomps/Text";
import BoldLabel from "../typocomps/BoldLabel";
import Image from "../components/Image";
import surveyQR from "../images/SurveyQR.png";


const SocialLinks = () => {

    const librayID  = JSON.parse(window.localStorage.getItem("library"));

    const openQuestionnaire = () => {
        window.open("https://www.surveymonkey.co.uk/r/EL2public");
      }

    if(librayID <= 3){
        console.log("surveyLink ONLINE VERSION");
        return(
            <button id="questionnaireButton" className="ThanksButton" onClick={openQuestionnaire}>Feedback Questionnaire</button>
         );
    }else{
        console.log("surveyLink library VERSION");
        return(
            <details id="questionnaireButton" className="ThanksButton" >
                <summary style={{cursor: "pointer"}}>Feedback Questionnaire</summary> 
                <p style={{width: "100%", color: "#dcdad4"}}>If you would like to help Death Positive Libraries please follow this link to complete a feedback questionnaire.</p>
                <p style={{color: "#dcdad4"}}>Scan this link with your phone camera app to find the online questionnaire</p>
                <img style={{width: "30%", margin: "1rem auto"}} src={surveyQR} />
            </details>
        );
    }
}

export default SocialLinks;