
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc, getDocs } from "firebase/firestore";


//tickets
const ScreenAnalytics = (screen) => {

    console.log("screen Analytics for:");
    console.log(screen); // {screen: 'transform'}

    const quest = screen[Object.keys(screen)[0]];

    const pairs = {
        transform : ["unusual", "traditional", "conventional" ],
        unusual : ["unusual", "traditional" ],
    }

    console.log("PAIRAS");
    console.log(pairs[quest]);

    const targets = pairs[quest];
   // console.log(targets[0]);
   // console.log(targets[1]);
   // console.log(targets[2]);

    //setting ticket vlaues in db
    async function setAnalytics(targets){

        
        const db = getFirestore();
    
        const docRef = doc(db, "screens", "transform");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
            await setDoc(doc(db, "screens", "transform"), {
                preserve: (docSnap.data().preserve + 1),
                transform: (docSnap.data().transform)
            });
        } else {
            //console.log("No such document!");
        }

    }
    setAnalytics(targets);

    return null;
};

export default ScreenAnalytics;
 