import React, { useState, useContext } from "react";
import { useMediaQuery } from "react-responsive";

import Book from "./Book";
import BoldLabel from "../typocomps/BoldLabel";
import Text from "../typocomps/Text";
import HugeTitle from "../typocomps/HugeTitle";
import { books } from "../utils/constants";
import next from "../images/icons/next.png";
import * as manager from "../../src/utils/manager";
import QuestionsFrame from "../components/QuestionsFrame";
import * as Constants from "../utils/constants";
import Image from "../components/Image";
import Sounds from "../components/Sounds";
//import LibraryContext from "../screens/context";

const BooksPortrait = ({ ids, onRightButtonClick, onLeftButtonClick, match }) => {
  const [index, setIndex] = useState(0);

  //const { library } = useContext(LibraryContext);

  const library = JSON.parse(window.localStorage.getItem("library"));

  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW = versionToShow === 1 ? 100 : versionToShow === 2 ? 160 : 320;
  const imageH = 1.5 * imageW;

  const arrowW = versionToShow === 1 ? 45 : versionToShow === 2 ? 45 : 85;
  const arrowH = 0.9 * arrowW;

  const { availability: avails } = Constants.books[ids[index] - 1];
  const title = books[ids[index] - 1].title;
  const author = books[ids[index] - 1].author;
  const image = books[ids[index] - 1].image;

  const available = avails[library >= 1 ? library - 1 : 0]; ////???TODO PARVIN GUEST?

  let name = "";
  for (let key in Constants.LIBRARIES) {
    if (Constants.LIBRARIES[key].index === library) {
      name = Constants.LIBRARIES[key].name;
    }
  }

  const goNext = () => {
    setIndex(index === ids.length - 1 ? 0 : index + 1);
  };

  const goPrevious = () => {
    setIndex(index === 0 ? ids.length - 1 : index - 1);
  };

  const id = match ? parseInt(match.params.bid) : 1;
  let bookid = id;

  let link = "";
  for (let key in Constants.books) {
    if (Constants.books[key].id === id) {
      let libraryID = (JSON.parse(window.localStorage.getItem("library")) - 1);
      link = books[ids[index] - 1].links[libraryID];
    }
  }

  const modalToggle = () => {
    document.getElementById("LibraryModal").style.display = "none";
  }

  return (
    <QuestionsFrame
      //uplabel="share"
      hasRight={true}
      leftLabel="all tickets"
      rightLabel="continue"
      onRightButtonClick={onRightButtonClick}
      onLeftButtonClick={onLeftButtonClick}
      showAvailability={true}
      available={available}
      libraryName={name}
      bookLink={link}
      bookid={bookid}
      modalToggle={() => modalToggle()}
    >
    <Sounds sounds="Books" />
    <div id="LibraryModal" onClick={modalToggle}></div>
      <div className="centered-content" style={{ margin: "5%" }}>
        <div className="row">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              alt=""
              src={next}
              onClick={() => goPrevious()}
              style={{
                height: `${arrowH}px`,
                width: `${arrowW}px`,
                marginRight: 20,
                transform: "rotate(180deg)",
              }}
            />
          </div>
          <Book
            title={title}
            author={author}
            image={image}
            imageW={imageW}
            imageH={imageH}
          ></Book>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              alt=""
              src={next}
              onClick={() => goNext()}
              style={{
                height: `${arrowH}px`,
                width: `${arrowW}px`,
              }}
            />
          </div>
        </div>
      </div>
    </QuestionsFrame>
  );
};

export default BooksPortrait;
