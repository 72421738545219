import React from "react";

import Text from "../typocomps/Text";
import SmallText from "../typocomps/SmallText";
import BoldSmallText from "../typocomps/BoldSmallText";
import Image from "../components/Image";


const Book = ({ title, author, image, imageW, imageH }) => {
  return (
    <div style={{ width: `${imageW + 20}px` }}>
      <Image
        alt=""
        src={image}
        style={{
          height: `${imageH}px`,
          width: `${imageW}px`,
        }}
      />
      <BoldSmallText>{title}</BoldSmallText>
      <SmallText>{author}</SmallText>
    </div>
  );
};

export default Book;
