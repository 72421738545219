import React from "react";
import { Link } from "react-router-dom";

import * as manager from "../utils/manager";
import PATHS from "../utils/constants";

import facebook from "../images/icons/facebook.png";
import twitter from "../images/icons/twitter.png";
import download from "../images/icons/download.png";
import facebookQR from "../images/FaceBookQR.png";



const SocialDropDown = ({title, DownloadPath}) => {

    const librayID  = JSON.parse(window.localStorage.getItem("library"));

    //some title are array, some are single strings, this checks to see which, and converts arrays to strings
    // additonally, some title strings begin with an "&", which twitter and facebook dont allow, so if they are detected, the are repalced with "and"
    function CheckTitle(TitleObject){
        let title = TitleObject;
        let concatTitle = [];
        const regexAmpersand = /^\&.*$/ 

        if(Array.isArray(title)){
            title.forEach(element => {
                if(typeof element === "string"){
                    if (regexAmpersand.test(element)){
                        concatTitle.push(element.replace("&", "and"));
                    }else{
                        concatTitle.push(element);
                    }
                }
            });
            return concatTitle.join(' ');
        }else{
            return TitleObject;
        }
    }

    const TwitterShare = () => {
        const postTitle = CheckTitle(title.props.children); 
        window.open("https://twitter.com/intent/tweet?text=My ticket for the afterlife is " + postTitle + ". You can too explore a range of choices for what to do with your body, memories, and legacies with the DeathPositiveLibraries&hashtags=DeathPositiveLibraries,TicketForTheAfterlife" );
    }

    const FacebookShare = () => {
        let quote = ("My ticket to after life is " + CheckTitle(title.props.children) + " - " + "You can too explore a range of choices for what to do with your body, memories, and legacies with the DeathPositiveLibraries");
        window.open("https://www.facebook.com/dialog/share?app_id=851492678841826&display=popup&href=https://loveafterdeath-5ba5c.web.app/&hashtag=%23DeathPositiveLibraries&redirect_uri=https://loveafterdeath-5ba5c.web.app/&quote=" + quote , ",_blank")
    }

    if(librayID <= 3){
        console.log("Library ID: " + librayID);
        console.log("SOCIAL DROP DOWN ONLINE VERSION");
        return( 
            <div className="socialDropDownLinks">
                <a className="socialDropDownLinksText" onClick={TwitterShare} >
                    <img src={twitter} />
                    <p>twitter</p>
                </a>
                    <div className="socialDropDownLinksText" onClick={FacebookShare}>
                        <img src={facebook}/>
                        <p>facebook</p>
                    </div>
                <a href={DownloadPath} download className="socialDropDownLinksText">
                    <img src={download}/>
                    <p>download</p>
                </a>
            </div>
        );
    } else {
        console.log("Library ID: " + librayID);
        console.log("SOCIAL DROP DOWN LOCAL LIBRARY VERSION");
        return(
            <div className="socialDropDownLinks" style={{width: "100%", display: "flex", flexWrap: "wrap"}}>
                <p style={{width: "100%", color: "rgb(40, 40, 40)", marginBottom: "1rem"}}>Scan this QR code with your phones camera app to share your ticket to Facebook</p>
                <img src={facebookQR} style={{width: "100%", height: "100%"}}/>
            </div>
        );
    }
}

export default SocialDropDown;