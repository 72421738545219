import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import TicketContent from "./TicketContent";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketTypePortrait3 = ({
  id,
  avail,
  cost,
  title,
  text,
  image,
  imageRatio,
  pmtop,
  titlev2,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.PORTRAIT[versionToShow - 1][id];

  const {
    WIDHT: W,
    HEIGHT: H,
    TICKETS_CONTENT_MARGIN: margin,
  } = manager.SIZES(useMediaQuery);

  const tmpTitle = versionToShow <= 2 && titlev2 ? titlev2 : title;

  const SECTIONS = ["35%", "55%"];
  const TOP = pmtop === 1 ? "20%" : pmtop === 2 ? "40%" : "55%";

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      title={title}
      id={id}
    >
      <div
        className="row"
        style={{
          paddingLeft: `${margin}px`,
          paddingRight: `${margin}px`,
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "15%",
            width: SECTIONS[0],
          }}
        >
          <Image
            alt=""
            src={image}
            style={{ height: `${IMAGE_H}px`, width: `${IMAGE_W}px` }}
          />
        </div>
        <div
          style={{
            position: "relative",
            top: TOP,
            width: SECTIONS[1],
          }}
        >
          <div style={{}}>{tmpTitle}</div>
          <div style={{ marginTop: "5%" }}>
            <div>{text}</div>
          </div>
        </div>
      </div>
    </Ticket>
  );
};

export default TicketTypePortrait3;
