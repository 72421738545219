import React from "react";

import ChooseScreen from "../../components/ChooseScreen";
import technology from "../../images/technology.jpg";
import body from "../../images/body.jpg";
import memories from "../../images/memories.jpg";
import PATHS from "../../utils/constants";

const TechnologyScreen = () => {
  return (
    <ChooseScreen
      optionsData={{
        option1: {
          label: "technology",
          image: technology,
          path: PATHS.Robots,
        },
        option2: {
          label: "body",
          image: body,
          path: PATHS.Aging,
        },
        option3: {
          label: "memories",
          image: memories,
          path: PATHS.Messages,
        },
      }}
      rotateImagesForPortrait={true}
      rotateImagesForMobileLandscape={true}
      imageTypeSmall={false}
      sound="Preserve"
    />
  );
};

export default TechnologyScreen;
