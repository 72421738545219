import LargeTitle from "../typocomps/LargeTitle";
import simple from "../images/simple.jpg";
import elaborate from "../images/elaborate.jpg";
import transform from "../images/transform.jpg";
import preserve from "../images/preserve.jpg";
import conventional from "../images/conventional.jpg";
import unusual from "../images/unusual.jpg";
import Image from "./Image";
import Sounds from "../components/Sounds";

export default {
  ELABORATE: (version) => {
    const IMGW =
      version === 1
        ? 50
        : version === 2
        ? 70
        : version === 3
        ? 70
        : version === 4
        ? 70
        : 70;

    return (
      <div style={{ width: "100%" }}>
        <div className="row-centered">
          <LargeTitle>Do you prefer</LargeTitle>
          <div style={{ marginLeft: "5px" }}>
            <Image
              alt=""
              src={elaborate}
              style={{
                verticalAlign: "middle",
                marginRight: "5px",
                width: `${IMGW}px`,
              }}
            />
          </div>
          <LargeTitle> elaborate</LargeTitle>
        </div>
        <div className="row-centered">
          <LargeTitle>ceremonies or</LargeTitle>
          <LargeTitle style={{ marginLeft: "5px" }}>simple, quiet</LargeTitle>
          <div>
            <Image
              alt=""
              src={simple}
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                marginTop: "15px",
                width: `${IMGW}px`,
              }}
            />
          </div>
          <LargeTitle> ones?</LargeTitle>
        </div>
      </div>
    );
  },

  ELABORATE_PORTRAIT: (version) => {
    const IMGW =
      version === 1
        ? 20
        : version === 2
        ? 70
        : version === 3
        ? 70
        : version === 4
        ? 70
        : 70;
    return (
      <div style={{ width: "100%", marginLeft: 0 }}>
        <LargeTitle style={{ marginBottom: "5px" }}>Do you prefer</LargeTitle>
        <div className="row-centered" style={{ marginBottom: "5px" }}>
          <Image
            alt=""
            src={elaborate}
            style={{
              verticalAlign: "middle",
              marginRight: "5px",
              width: `${IMGW}px`,
            }}
          />
          <LargeTitle> elaborate</LargeTitle>
        </div>
        <LargeTitle style={{ marginBottom: "5px" }}>
          ceremonies or simple,
        </LargeTitle>
        <div className="row-centered">
          <LargeTitle>quiet</LargeTitle>
          <Image
            alt=""
            src={simple}
            style={{
              marginLeft: "5px",
              marginRight: "5px",
              width: `${IMGW}px`,
            }}
          />
          <LargeTitle> ones?</LargeTitle>
        </div>
      </div>
    );
  },

  CONVENTIONAL: (version) => {
    const IMGW =
      version === 1
        ? 50
        : version === 2
        ? 70
        : version === 3
        ? 70
        : version === 4
        ? 70
        : 70;
    return (
      <div>
        <LargeTitle style={{ marginBottom: "5px" }}>
          Are you interested in a
        </LargeTitle>
        <div className="row-centered" style={{ marginBottom: "5px" }}>
          <Image
            alt=""
            src={conventional}
            style={{ marginRight: "10px", width: `${IMGW}px` }} //80
          />

          <LargeTitle>traditional</LargeTitle>
        </div>
        <LargeTitle style={{ marginBottom: "10px" }}>
          departure from the
        </LargeTitle>
        <LargeTitle style={{ marginBottom: "5px" }}>
          living world or a more
        </LargeTitle>
        <div className="row-centered">
          <LargeTitle>unusual</LargeTitle>
          <Image
            alt=""
            src={unusual}
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              width: `${IMGW}px`,
            }}
          />
          <LargeTitle>one?</LargeTitle>
        </div>
      </div>
    );
  },

  TRANSFORM: (version) => {
    const IMGW =
      version === 1
        ? 50
        : version === 2
        ? 70
        : version === 3
        ? 70
        : version === 4
        ? 70
        : 70;

    return (
      <div>
        <LargeTitle style={{ marginBottom: "5px" }}>Do you wish to</LargeTitle>
        <div className="row-centered" style={{ marginBottom: "5px" }}>
          <Image
            src={preserve}
            alt="preserve"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              width: `${IMGW}px`, //80
            }}
          />
          <LargeTitle>preserve your </LargeTitle>
        </div>
        <LargeTitle style={{ marginBottom: "10px" }}>
          identity, possesions
        </LargeTitle>
        <LargeTitle style={{ marginBottom: "5px" }}>and remains or</LargeTitle>
        <div className="row-centered">
          <LargeTitle>transform</LargeTitle>
          <Image
            src={transform}
            alt="transform"
            style={{
              verticalAlign: "middle",
              marginLeft: "10px",
              marginRight: "10px",
              width: `${IMGW}px`,
            }}
          />
          <LargeTitle>them?</LargeTitle>
        </div>
      </div>
    );
  },
};
