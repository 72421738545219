import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import * as manager from "../utils/manager";
import restart from "../images/icons/restartInvert.png";
import next from "../images/icons/nextInvert.png";
import BoldText from "../typocomps/BoldText";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import Text from "../typocomps/Text";

const tagsStyle = {
    backgroundColor: "white",
    borderRadius: 1,
    opacity: 0.7,
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "5px",
    paddingRight: "5px",
};

const UserTicket = ({ children, avail, cost, onRestart, onContinue, onAllTickets, title, text, image, id }) => {
    const { showPortrait } = manager.getVersionToShow(useMediaQuery);
    const size = manager.getVersionToShow(useMediaQuery).versionToShow;

    const bottomIconsSize = [35, 35, 45, 45, 45];

    const bottomButtonLabelsLeft = ["Back", "Back" , "Back" , "Back" , "Back"];
    const bottomButtonLabelsRight = ["All Tickets", "All Tickets" , "All Tickets" , "All Tickets" , "All Tickets"];

    const cornerMargin = manager.SIZES(useMediaQuery).TICKETS_CONTENT_MARGIN;

    const db = getFirestore();

    const FetchData = async () => {
        //fetch records where index == id :)
        const q = query(collection(db, "userTickets"), where("index", "==", id));
                
        const querySnapshot = await getDocs(q);
        console.log(querySnapshot);

        querySnapshot.forEach((doc) => {
            document.getElementById("ticketTitle").innerHTML = doc.data().title;
            document.getElementById("ticketText").innerHTML = doc.data().text;
            document.getElementById("ticketImg").src = doc.data().imgURL;
            document.getElementById("uploadDate").innerHTML = doc.data().uploadDate;
            if(doc.data().BookRecommendation){
                document.getElementById("bookReq").innerHTML = ("Recommended Books: " + doc.data().BookRecommendation);
            }
        });
    }
    FetchData();

    return (   
        <div className="ticketScreen"
            style={{
                height: "100%",
                position: "relative",
                background: "#282828",
            }}
        >


        <div style={{ height: "100%" }}>{children}</div>
        <div className="row-centered"
            style={{
                position: "absolute",
                zIndex: "2",
                bottom: `${cornerMargin - 8}px`,
                left: `${cornerMargin - 5}px`,
                cursor: "pointer",
            }}
            onClick={() => onRestart()}
        >
            <img
                alt=""
                src={restart}
                style={{
                    width: `${bottomIconsSize[size - 1]}px`,
                    marginRight: "7px",
                }}
            />
            <BoldText>{bottomButtonLabelsLeft[size - 1]}</BoldText>
        </div>
        <div
            className="row-centered"
            style={{
            position: "absolute",
            zIndex: "2",
            right: `${cornerMargin - 5}px`,
            bottom: `${cornerMargin - 8}px`,
            cursor: "pointer",
            }}
            onClick={() => onAllTickets()}
        >
            <BoldText>{bottomButtonLabelsRight[size - 1]}</BoldText>
            <img
                alt=""
                src={next}
                style={{
                    width: `${bottomIconsSize[size - 1]}px`,
                    marginLeft: "7px",
                }}
            />
        </div>
    </div>
  );
};

export default UserTicket;
