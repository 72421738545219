import React from "react";
import { useMediaQuery } from "react-responsive";

import QuestionsFrame from "../components/QuestionsFrame";
import TwoVerticalOptions from "./TwoVerticalOptions";
import line from "../images/lines/lineG2pt.png";
import * as manager from "../utils/manager";
import Image from "../components/Image";
import Sounds from "../components/Sounds";
import ScreenAnalytics from "./ScreenAnalytics";

import { motion } from "framer-motion"

const ScreenTypeC = ({
  text,
  optionUp,
  optionDown,
  pathUp,
  pathDown,
  image,
  questionLabel,
  showLine,
  sound
}) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const answers = (size) => {
    return (
      <div style={{ width: `${size}px`, alignSelf: "flex-end" }}>
        <TwoVerticalOptions
          side={size}
          textUp={optionUp}
          textDown={optionDown}
          pathUp={pathUp}
          pathDown={pathDown}
        />
      </div>
    );
  };

  /************************************ landscape *************************************/

  const imageW =
    versionToShow === 1
      ? 90
      : versionToShow === 2
      ? 140
      : versionToShow === 3
      ? 260
      : versionToShow === 4
      ? 260
      : 260;

  const containerW =
    versionToShow === 1
      ? 340
      : versionToShow === 2
      ? 450
      : versionToShow === 3
      ? 760
      : versionToShow === 4
      ? 760
      : 760;

  const gap =
    versionToShow === 1
      ? 10
      : versionToShow === 2
      ? 10
      : versionToShow === 3
      ? 40
      : versionToShow === 4
      ? 40
      : 20;

  const imageRatio = 420 / 280; // 280, 420
  const imageH = imageW * imageRatio;

  const landscapeComp = (

    <div className="centered-content">
    <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
    <Sounds sounds={sound}/>
      <div
        className="row"
        style={{
          width: `${containerW}px`,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ marginBottom: `${gap}px` }}>{text}</div>
          <div style={{ alignSelf: "flex-start" }}>{answers(120)}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Image
            alt=""
            src={image}
            style={{ width: `${imageW}px`, height: `${imageH}px` }}
          />
        </div>
      </div>
      </motion.div>
    </div>

  );

  /*********************************** Portrait *********************************/

  const pimageW = versionToShow === 1 ? 90 : versionToShow === 2 ? 140 : 300;

  const pimageGap = versionToShow === 1 ? 10 : versionToShow === 2 ? 10 : 20;
  const ptextGap = versionToShow === 1 ? 10 : versionToShow === 2 ? 20 : 20;

  const pimageH = pimageW * imageRatio;

  const portraitComp = (
    <div className="centered-content">
    <Sounds sounds={sound}/>
      <div style={{ width: "80%" }}>
        <Image
          alt=""
          src={image}
          style={{
            width: `${pimageW}px`,
            height: `${pimageH}px`,
            marginBottom: `${pimageGap}px`,
          }}
        />
        <div style={{ marginBottom: `${ptextGap}px`, width: "100%" }}>
          {text}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          {answers(versionToShow === 3 ? 180 : 120)}
        </div>
      </div>
    </div>
  );

  return (
    <QuestionsFrame uplabel={questionLabel}>
      {showPortrait ? portraitComp : landscapeComp}
    </QuestionsFrame>
  );
};

export default ScreenTypeC;
