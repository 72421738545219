import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import QuestionsFrame from "./QuestionsFrame";
import line from "../images/lines/lineE2pt.png";
import pline from "../images/lines/lineErotated.png";
import * as manager from "../utils/manager";
import Image from "../components/Image";

import { motion } from "framer-motion";
import Sounds from "../components/Sounds";

const CoupledChoices = ({
  labelUp,
  labelDown,
  imageUp,
  imageDown,
  pathUp,
  pathDown,
  questionLabel,
  sound
}) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 100
      : versionToShow === 2
      ? 100
      : versionToShow === 3
      ? 120
      : versionToShow === 4
      ? 120
      : 120;

  const lineW =
    versionToShow === 1
      ? 200
      : versionToShow === 2
      ? 200
      : versionToShow === 3
      ? 300
      : versionToShow === 4
      ? 300
      : 300;

  const lineRatio = 0.425; // 400, 170
  const imageRatio = 0.56; // 280, 157

  const imageH = imageW * imageRatio;
  const lineH = lineW * lineRatio;

  //
  const landscapeComp = ( 
    <div className="centered-content">
    <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
      <Sounds sounds={sound} />
      <div className="row" style={{ height: `${imageH * 2 + lineH}px` }}>
        <Link to={pathUp} className="no-decoration">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: `${imageH / 2}px`,
            }}
          >
            <Image
              alt=""
              src={imageUp}
              style={{ alignSelf: "flex-end", width: `${imageW}px` }}
            />
            <div style={{ textAlign: "right" }}>{labelUp}</div>
          </div>
        </Link>
        <img
          alt=""
          src={line}
          style={{
            width: `${lineW}px`,
            objectFit: "contain",
          }}
        />
        <Link
          to={pathDown}
          className="no-decoration"
          style={{ marginTop: `${lineH}px` }}
        >
          <Image alt="" src={imageDown} style={{ width: `${imageW}px` }} />
          {labelDown}
        </Link>
      </div>
      </motion.div>
    </div>
  );

  /************************** portrait ******************************/
  // portrait
  const plineW =
    versionToShow === 1
      ? 140
      : versionToShow === 2
      ? 200
      : versionToShow === 3
      ? 300
      : versionToShow === 4
      ? 300
      : 300;

  const pimageW =
    versionToShow === 1
      ? 90
      : versionToShow === 2
      ? 100
      : versionToShow === 3
      ? 180
      : versionToShow === 4
      ? 120
      : 120;

  const plineRatio = 1.2; // 3052, 3668 // W/H
  const plineH = plineW * plineRatio;
  const pimageH = pimageW * imageRatio;

  const portraitComp = (
    <div className="centered-content">
      <Sounds sounds={sound} />
      <div className="column">
        <div className="row" style={{ marginLeft: `-${pimageW / 2}px` }}>
          <Link to={pathUp} className="no-decoration">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Image
                alt=""
                src={imageUp}
                style={{ width: `${pimageW}px`, zIndex: 2 }}
              />
              {labelUp}
            </div>
          </Link>

          <img
            alt=""
            src={pline}
            style={{
              width: `${plineW}px`,
              height: `${plineH}px`,
              objectFit: "contain",
              marginTop: `${pimageH - 5}px`,
              marginLeft: "-7px",
            }}
          />
        </div>
        <Link
          to={pathDown}
          className="no-decoration"
          style={{
            alignSelf: "flex-end",
            marginRight: `-${pimageW / 2}px`,

            position: "relative",
            bottom: "5px",
          }}
        >
          <Image alt="" src={imageDown} style={{ width: `${pimageW}px` }} />
          {labelDown}
        </Link>
      </div>
    </div>
  );

  return (
    <QuestionsFrame uplabel={questionLabel}>
      {showPortrait ? portraitComp : landscapeComp}
    </QuestionsFrame>
  );
};

export default CoupledChoices;
