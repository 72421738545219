import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Route } from "react-router-dom";

/*import { setDefaultBreakpoints } from "react-socks";

setDefaultBreakpoints([
  { xsmall: 0 }, // small mobile phones like iPhone 5s
  { small: 321 }, // mobile devices
  { medium: 576 }, // mobile devices (not sure which one's this big)
  { large: 768 }, // ipad, ipad pro, ipad mini, etc
  { xlarge: 1600 }, // laptops and desktops ??
]);*/

ReactDOM.render(
  <BrowserRouter>
    <Route path="/" component={App} />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*???
<React.StrictMode>
*/
