import React from "react";

import ScreenTypeC from "../../components/ScreenTypeC";
import { QUESTION_LABELS } from "../../utils/constants";
import Title from "../../typocomps/Title";
import cards from "../../images/cards.jpg";
import PATHS from "../../utils/constants";

const MessagesScreen = () => {
  return (
    <ScreenTypeC
      text={
        <Title>
          Do you wish to pre-record
          <br />
          messages or specific parts
          <br />
          of your life with the intention
          <br />
          to use this material after
          <br />
          your death?
        </Title>
      }
      optionUp={"yes"}
      optionDown={"no"}
      pathUp={PATHS.Scripted}
      pathDown={PATHS.Experience}
      image={cards}
      questionLabel={QUESTION_LABELS.ONEMORE}
      sound="Memories"
    />
  );
};

export default MessagesScreen;
