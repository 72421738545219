import React from "react";

import WiredChoices from "../../components/WiredChoices";
import { QUESTION_LABELS } from "../../utils/constants";
import BoldLabel from "../../typocomps/BoldLabel";
import dry from "../../images/dry.jpg";
import lineD from "../../images/lines/lineD2pt.png";
import PATHS from "../../utils/constants";

const BurialScreen = () => {
  return (
    <WiredChoices
      labelUp={<BoldLabel>burial grounds</BoldLabel>}
      labelDown={<BoldLabel>burial enclosure</BoldLabel>}
      pathUp={PATHS.Wildlife}
      pathDown={PATHS.Bio}
      image={dry}
      line={lineD}
      questionLabel={QUESTION_LABELS.ONEMORE}
      labelNumlines={1}
      sound="Burial2"
    />
  );
};

export default BurialScreen;
