import React, { useEffect, useState, useContext } from "react";
import { useMediaQuery } from "react-responsive";

import line from "../images/lines/lineG2ptPariCut.png";
import Title from "../typocomps/Title";
//import SmallTitle from "../typocomps/SmallTitle";
import QuestionsFrame from "../components/QuestionsFrame";
import * as Constants from "../utils/constants";
import * as manager from "../../src/utils/manager";
//import LibraryContext from "../screens/context";
import Image from "../components/Image";
import PATHS from "../utils/constants";

import { motion } from "framer-motion"

const BookSingle = ({ match, history }) => {
  const id = match ? parseInt(match.params.bid) : 1;
  let bookid = id;

  const library = JSON.parse(window.localStorage.getItem("library"));

  const { image, quote, availability: avails } = Constants.books[id - 1];
  const available = avails[library >= 1 ? library - 1 : 0]; 

  let name = "";
  for (let key in Constants.LIBRARIES) {
    if (Constants.LIBRARIES[key].index === library) {
      name = Constants.LIBRARIES[key].name;
    }
  }

  let link = "";
  for (let key in Constants.books) {
    if (Constants.books[key].id === id) {
      let libraryID = (JSON.parse(window.localStorage.getItem("library")) - 1);
      link = Constants.books[key].links[libraryID];
    }
  }

  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const imageW =
    versionToShow === 1
      ? 95
      : versionToShow === 2
      ? 90
      : versionToShow === 3
      ? 240
      : versionToShow === 4
      ? 240
      : 200;
  const imageH = 1.5 * imageW;

  const lineW =
    versionToShow === 1
      ? 35
      : versionToShow === 2
      ? 90
      : versionToShow === 3
      ? 80
      : versionToShow === 4
      ? 80
      : 200;
  const lineH = 2.5 * lineW;

  const marginPerc =
    versionToShow === 1
      ? 15
      : versionToShow === 2
      ? 15
      : versionToShow === 3
      ? 10
      : versionToShow === 4
      ? 10
      : 10;


  const onAllBooksClick = () => {
    const tid = JSON.parse(window.localStorage.getItem("ticket"));
    history.push(`${PATHS.Books}${tid}`);

    //debug
    console.log("tid");
    console.log(tid);
    console.log(JSON.parse(window.localStorage.getItem("ticket")));
  };

  const onContinueClick = () => {
    history.push(PATHS.Thanks);
  };

  const modalToggle = () => {
    document.getElementById("LibraryModal").style.display = "none";
  }

  return (
    <QuestionsFrame
      //uplabel="share"
      hasRight={true}
      rightLabel="continue"
      leftLabel="back to books"
      onRightButtonClick={() => onContinueClick()}
      onLeftButtonClick={() => onAllBooksClick()}
      showAvailability={true}
      available={available}
      libraryName={name}
      bookLink={link}
      bookid={bookid}
      modalToggle={() => modalToggle()}
    >
      <div id="LibraryModal" onClick={ modalToggle}></div>
      <div
        className="row centered-content"
        style={{
          marginLeft: `${marginPerc}%`,
          marginRight: `${marginPerc}%`,
          justifyContent: showPortrait ? "center" : "space-between",
        }}
      >

        {showPortrait ? null : (
          <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
            <Title
              style={{
                marginRight: "20%",
              }}
            >
              {quote ||
                "Death is the engine that keeps us running, giving us the motivation to achieve, learn, love, and create."}
            </Title>
          </motion.div>
        )}
        <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
        <div className="centered-content">
          <Image
            alt=""
            src={image}
            style={{
              height: `${imageH}px`,
              width: `${imageW}px`,
              cursor: "default"
            }}
          />
          <img
            alt=""
            src={line}
            style={{
              height: `${lineH}px`,
              width: `${lineW}px`,
            }}
          />
        </div>
        </motion.div>
      </div>
    </QuestionsFrame>
  );
};

export default BookSingle;
