import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import Text from "../typocomps/Text";
import PATHS from "../utils/constants";
import * as manager from "../utils/manager";
import allTickets from "../images/icons/allticketsInvert2.png";

import MyTicketIcon from "../images/icons/Myticket.png";

const MyTicketBottomButton = (props) => {

    const bottomIconsSize = [35, 35, 45, 45, 45];
    const size = manager.getVersionToShow(useMediaQuery).versionToShow;
    
    const TicketID =  JSON.parse(window.localStorage.getItem("firstTicketID"));

    console.log("current ticket ID");
    console.log(props.currentTicketID);
    
    if(TicketID > 0 && TicketID != props.currentTicketID){
        return(
            <Link to={`${PATHS.Tickets}${TicketID}`} className="no-decoration" >    
                <span className="userTicketBottomButtons">
                <Text style={{textAlign: "center"}}>Return to your Ticket</Text>
                <img
                    alt=""
                    src={MyTicketIcon}
                    style={{
                    width: `${bottomIconsSize[size - 1]}px`,
                    marginLeft: "7px",
                    }}
                />
                </span> 
            </Link>
        );
    }else{
        return(
            <span className="userTicketBottomButtons">
                <Text style={{textAlign: "center"}}>This is your Ticket</Text>
                <img
                    alt=""
                    src={MyTicketIcon}
                    style={{
                    width: `${bottomIconsSize[size - 1]}px`,
                    marginLeft: "7px",
                    }}
                />
            </span> 
        )
    }
}

export default MyTicketBottomButton;