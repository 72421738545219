import React, { useState, useContext } from "react";
import { useMediaQuery } from "react-responsive";

import newcastle from "../images/libraries/newcastle.jpg";
import kirklee from "../images/libraries/kirklees.jpg";
import redbridge from "../images/libraries/redbridge.jpg";
import * as manager from "../../src/utils/manager";
import SmallText from "../typocomps/SmallText";
import LargeTitle from "../typocomps/LargeTitle";
import BoldText from "../typocomps/BoldText";
import PATHS, { LIBRARIES } from "../utils/constants";
import LibraryContext from "./context";
import Image from "../components/Image";
import useSound from "use-sound";
import Sounds from "../components/Sounds";


import { motion } from "framer-motion"

const LibrarySelectionScreen = ({ history }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  const { setLibrary } = useContext(LibraryContext);

  const imageW =
    versionToShow === 1
      ? 80
      : versionToShow === 2
      ? 120
      : versionToShow === 3
      ? 180
      : versionToShow === 4
      ? 180
      : 180;
  const imageH = 1.0 * imageW;

  const nextPage = (name, id) => {
    setLibrary(id);
    window.localStorage.setItem("library", id);
    //history.push(PATHS.Introduction);
    history.push(PATHS.Conventional);
  };

  const LibComp = ({ image, name, id, style }) => {
    return (
      <div
        style={{ ...style, cursor: "pointer" }}
        onClick={() => nextPage(name, id)}
      >
      <Sounds sounds={"Library"}/>
        <Image
          alt=""
          src={image}
          style={{
            width: `${imageW}px`,
            height: `${imageH}px`,
            objectFit: "contain",
            height: "auto",
          }}
        />

        <BoldText style={{ textAlign: "center", marginTop: "2%" }}>
          {name === LIBRARIES.REDBRIGDE
            ? `${name} Library`
            : `${name} Libraries`}
        </BoldText>
      </div>
    );
  };

  console.log("restart");
  const library = JSON.parse(window.localStorage.getItem("library"));
  console.log(library);
  if(library < 3){
    console.log("on online");
    console.log(library);
  }else{
    console.log("on Local - skip this screen");
    console.log(library);
    history.push(PATHS.Conventional);
  }


  const landscapeComp = (
    <motion.div   initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} >
    <div className="centered-content">
      <div style={{ width: "80%" }}>
        <div className="center-in-a-row" style={{ marginBottom: "5%" }}>
          <LargeTitle>Do you use any of these libraries?</LargeTitle>
        </div>

        <div className="row spaced" style={{ marginBottom: "5%" }}>
          <LibComp
            image={newcastle}
            name={LIBRARIES.NEWCASTLE.name}
            id={LIBRARIES.NEWCASTLE.index}
          />
          <LibComp
            image={kirklee}
            name={LIBRARIES.KIRKLEES.name}
            id={LIBRARIES.KIRKLEES.index}
          />
          <LibComp
            image={redbridge}
            name={LIBRARIES.REDBRIGDE.name}
            id={LIBRARIES.REDBRIGDE.index}
          />
        </div>

        <div className="centered-content">
          <div style={{ width: "80%" }}>
            <SmallText style={{ textAlign: "center" }}>
              We will be using this information to give you book recommendation
              available in our library partners. If you’re accessing this from
              home, continue as a guest{" "}
              <span
                onClick={() =>
                  nextPage(LIBRARIES.GUEST.name, LIBRARIES.GUEST.index)
                }
                style={{ 
                  backgroundColor: "#3A3B3C",
                  color: "#f4f4f2",
                  borderRadius: ".4rem",
                  display: "flex",
                  width: "8rem",
                  height: "3rem",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "1rem auto",
                  fontSize: "140%"
                }}
              >
                guest
              </span>
              <details>
                <summary>Library Admin</summary>
                <p>These below options are intended only for use when onsite, and by library staff. Your experience and Catalogue results may differ between online and onsite version of this app. If you are using this quiz on your own device, please choose one of the above options.  </p>
                <div className="LibrarysLocalOuter">
                  <button className="LibrarysLocal" onClick={() => nextPage(LIBRARIES.NEWCASTLELOCAL.name, LIBRARIES.NEWCASTLELOCAL.index)}>
                    Newcastle Tablet
                  </button>
                  <button className="LibrarysLocal" onClick={() => nextPage(LIBRARIES.KIRKLEESLOCAL.name, LIBRARIES.KIRKLEESLOCAL.index)}>
                    Kirklees Tablet
                  </button>
                  <button className="LibrarysLocal" onClick={() => nextPage(LIBRARIES.REDBRIGDELOCAL.name, LIBRARIES.REDBRIGDELOCAL.index)}>
                    Redbridge Yeti Tablet
                  </button>
                </div>
              </details>
            </SmallText>
          </div>
        </div>
      </div>
    </div>
    </motion.div>
  );

  const portraitComp = (
    <div style={{ marginTop: "7%" }}>
      <div className="center-in-a-row" style={{ marginBottom: "5%" }}>
        <LargeTitle>Are you in any of these libraries?</LargeTitle>
      </div>

      <LibComp
        image={newcastle}
        name={LIBRARIES.NEWCASTLE.name}
        id={LIBRARIES.NEWCASTLE.index}
        style={{
          marginBottom: "5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      />
      <LibComp
        image={kirklee}
        name={LIBRARIES.KIRKLEES.name}
        id={LIBRARIES.KIRKLEES.index}
        style={{
          marginBottom: "5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      />
      <LibComp
        image={redbridge}
        name={LIBRARIES.REDBRIGDE.name}
        id={LIBRARIES.REDBRIGDE.index}
        style={{
          marginBottom: "2%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      />

      <div className="centered-content">
        <div style={{ width: "90%" }}>
          <SmallText style={{ textAlign: "center"}}>
            If you’re accessing this from
            home, continue as a guest{" "}
            <span
              onClick={() =>
                nextPage(LIBRARIES.GUEST.name, LIBRARIES.GUEST.index)
              }
              style={{ 
                backgroundColor: "#3A3B3C",
                color: "#f4f4f2",
                borderRadius: ".4rem",
                display: "flex",
                width: "8rem",
                height: "3rem",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "1rem auto",
                fontSize: "140%"
              }}
            >
              guest
            </span>
            .
          </SmallText>
        </div>
      </div>
    </div>
  );

  return (
    <div className="centered-content">
      {showPortrait ? portraitComp : landscapeComp}
    </div>
  );
};

export default LibrarySelectionScreen;
