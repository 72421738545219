import React from "react";
import { useMediaQuery } from "react-responsive";

import Ticket from "./Ticket";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const TicketType4 = ({
  id,
  avail,
  cost,
  title,
  text,
  image,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.LANDSCAPE[versionToShow - 1][id];

  return (
    <Ticket
      avail={avail}
      cost={cost}
      onRestart={onRestart}
      onContinue={onContinue}
      onAllTickets={onAllTickets}
      text={text}
      image={image}
      id={id}
      title={title}
    >
      <div
        style={{
          paddingLeft: "50%",
          width: "50%",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            paddingTop: "15%",
          }}
        >
          <Image alt="" src={image} style={{ width: `${imageW}px` }} />
        </div>
        <div
          style={{
            width: "50%",
            marginTop: "60px",
          }}
        >
          <div>{title}</div>
          <div style={{ marginTop: "30px" }}>{text}</div>
        </div>
      </div>
    </Ticket>
  );
};

export default TicketType4;
