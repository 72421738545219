import * as device from "./device";
import { useMediaQuery } from "react-responsive";

export const classPostfix = (useMediaQuery) => {
  const { type, isPortrait } = device.deviceType(useMediaQuery);

  return type === device.DEVICE_TYPE.XSMALL
    ? "-xs"
    : type === device.DEVICE_TYPE.SMALL
    ? "-s"
    : isPortrait && type === device.DEVICE_TYPE.MEDIUM
    ? "-m"
    : type === device.DEVICE_TYPE.XLARGE
    ? "-xl"
    : "";
};

export const getVersionToShow = (useMediaQuery) => {
  const { type, isMobile, isTablet, isPortrait } =
    device.deviceType(useMediaQuery);

  return {
    showPortrait: isPortrait,
    versionToShow:
    //Conditional (Ternary operator)
      type === device.DEVICE_TYPE.XSMALL
        ? 1
        : type === device.DEVICE_TYPE.SMALL
        ? 2
        : type === device.DEVICE_TYPE.MEDIUM
        ? 3
        : type === device.DEVICE_TYPE.LARGE
        ? 4
        : type === device.DEVICE_TYPE.XLARGE
        ? 5
        : 0,
  };
};

export const SIZES = (useMediaQuery) => {
  const { type } = device.deviceType(useMediaQuery);

  return {
    WIDTH:
      type === device.DEVICE_TYPE.XSMALL
        ? 490
        : type === device.DEVICE_TYPE.SMALL
        ? 600
        : type === device.DEVICE_TYPE.MEDIUM
        ? 1000
        : type === device.DEVICE_TYPE.LARGE
        ? 1000
        : 1000,
    W2H: 3,
    TICKETS_CONTENT_MARGIN:
      type === device.DEVICE_TYPE.XSMALL
        ? 10
        : type === device.DEVICE_TYPE.SMALL
        ? 20
        : 30,
  };
};

export const IMAGE_WIDTHS = {
  LANDSCAPE: [
    {
      1: 90,
      2: 80,
      3: 110,
      4: 100,
      5: 70,
      6: 90,
      7: 95,
      8: 60,
      9: 50,
      10: 190,
      11: 75,
      12: 75,
      13: 60,
      14: 50,
      15: 60,
      16: 110,
      17: 90,
      18: 80,
      19: 80,
      20: 60,
      21: 60,
      22: 60,
      23: 70,
      24: 100,
      25: 90,
      26: 85,
      27: 70,
      28: 120,
      29: 75,
      30: 85,
      31: 130,
      32: 60,
      33: 50,
      34: 90,
      35: 60,
      36: 60,
      37: 190,
      38: 60,
      39: 130,
      40: 65,
      41: 75,
      42: 110,
      43: 70,
      44: 70,
      45: 75,
      46: 130,
      47: 70,
      48: 95,
      49: 175,
      50: 130,
    },
    {
      1: 110,
      2: 110,
      3: 155,
      4: 135,
      5: 100,
      6: 125,
      7: 130,
      8: 85,
      9: 70,
      10: 265,
      11: 105,
      12: 90,
      13: 85,
      14: 70,
      15: 75,
      16: 155,
      17: 125,
      18: 110,
      19: 110,
      20: 85,
      21: 85,
      22: 85,
      23: 100,
      24: 140,
      25: 125,
      26: 120,
      27: 100,
      28: 170,
      29: 105,
      30: 60,
      31: 175,
      32: 85,
      33: 70,
      34: 125,
      35: 110,
      36: 85,
      37: 265,
      38: 85,
      39: 140,
      40: 90,
      41: 70,
      42: 155,
      43: 100,
      44: 100,
      45: 105,
      46: 125,
      47: 100,
      48: 110,
      49: 245,
      50: 180,
    },
    {
      1: 170,
      2: 150,
      3: 210,
      4: 245,
      5: 130,
      6: 170,
      7: 180,
      8: 115,
      9: 115,
      10: 360,
      11: 140,
      12: 140,
      13: 115,
      14: 95,
      15: 140,
      16: 210,
      17: 170,
      18: 150,
      19: 150,
      20: 115,
      21: 115,
      22: 115,
      23: 135,
      24: 190,
      25: 180,
      26: 160,
      27: 135,
      28: 230,
      29: 140,
      30: 80,
      31: 245,
      32: 115,
      33: 95,
      34: 170,
      35: 115,
      36: 115,
      37: 360,
      38: 115,
      39: 245,
      40: 120,
      41: 95,
      42: 210,
      43: 130,
      44: 130,
      45: 140,
      46: 245,
      47: 130,
      48: 180,
      49: 330,
      50: 245,
    },
    {
      1: 180,
      2: 160,
      3: 220,
      4: 260,
      5: 140,
      6: 180,
      7: 190,
      8: 120,
      9: 120,
      10: 340,
      11: 150,
      12: 150,
      13: 120,
      14: 100,
      15: 150,
      16: 220,
      17: 180,
      18: 160,
      19: 160,
      20: 120,
      21: 120,
      22: 120,
      23: 140,
      24: 200,
      25: 180,
      26: 170,
      27: 140,
      28: 240,
      29: 150,
      30: 85,
      31: 260,
      32: 120,
      33: 100,
      34: 180,
      35: 120,
      36: 120,
      37: 380,
      38: 120,
      39: 260,
      40: 130,
      41: 100,
      42: 220,
      43: 140,
      44: 140,
      45: 150,
      46: 260,
      47: 140,
      48: 190,
      49: 350,
      50: 260,
    },
    [],
  ],
  PORTRAIT: [
    {
      1: 90,
      2: 100,
      3: 100,
      4: 120,
      5: 80,
      6: 90,
      7: 160,
      8: 100,
      9: 40,
      10: 150,
      11: 120,
      12: 60,
      13: 120,
      14: 100,
      15: 70,
      16: 120,
      17: 120,
      18: 100,
      19: 100,
      20: 60,
      21: 100,
      22: 60,
      23: 100,
      24: 100,
      25: 150,
      26: 80,
      27: 70,
      28: 160,
      29: 100,
      30: 50,
      31: 140,
      32: 100,
      33: 80,
      34: 120,
      35: 100,
      36: 100,
      37: 180,
      38: 80,
      39: 100,
      40: 80,
      41: 100,
      42: 120,
      43: 100,
      44: 80,
      45: 120,
      46: 120,
      47: 80,
      48: 100,
      49: 150,
      50: 140,
    },
    {
      1: 150,
      2: 150,
      3: 160,
      4: 160,
      5: 140,
      6: 140,
      7: 190,
      8: 120,
      9: 100,
      10: 180,
      11: 150,
      12: 100,
      13: 150,
      14: 140,
      15: 120,
      16: 160,
      17: 150,
      18: 120,
      19: 160,
      20: 100,
      21: 120,
      22: 80,
      23: 120,
      24: 120,
      25: 180,
      26: 100,
      27: 100,
      28: 180,
      29: 120,
      30: 75,
      31: 160,
      32: 120,
      33: 100,
      34: 160,
      35: 120,
      36: 120,
      37: 280,
      38: 100,
      39: 120,
      40: 100,
      41: 100,
      42: 160,
      43: 140,
      44: 100,
      45: 150,
      46: 160,
      47: 100,
      48: 100,
      49: 200,
      50: 200,
    },
    {
      1: 170,
      2: 200,
      3: 220,
      4: 260,
      5: 240,
      6: 180,
      7: 380,
      8: 180,
      9: 170,
      10: 320,
      11: 200,
      12: 150,
      13: 260,
      14: 220,
      15: 160,
      16: 320,
      17: 200,
      18: 180,
      19: 180,
      20: 180,
      21: 200,
      22: 120,
      23: 160,
      24: 210,
      25: 200,
      26: 190,
      27: 180,
      28: 440,
      29: 180,
      30: 120,
      31: 260,
      32: 180,
      33: 180,
      34: 240,
      35: 245,
      36: 220,
      37: 380,
      38: 160,
      39: 260,
      40: 150,
      41: 200,
      42: 220,
      43: 180,
      44: 120,
      45: 220,
      46: 260,
      47: 140,
      48: 180,
      49: 350,
      50: 260,
    },
  ],
};
