import React from "react";

import ChooseScreen from "../../components/ChooseScreen";
import cremation from "../../images/cremation.jpg";
import natural from "../../images/natural.jpg";
import sockets from "../../images/sockets.jpg";
import PATHS from "../../utils/constants";

const CremationScreen = () => {
  return (
    <ChooseScreen
      optionsData={{
        option1: {
          label: "cremation",
          image: cremation,
          path: PATHS.Become,
        },
        option2: {
          label: "natural",
          image: natural,
          path: PATHS.Green,
        },
        option3: {
          label: "technology",
          image: sockets,
          path: PATHS.Reversing,
        },
      }}
      rotateImagesForPortrait={true}
      rotateImagesForMobileLandscape={true}
      imageTypeSmall={false}
      sound="Transform2"
    />
  );
};

export default CremationScreen;
