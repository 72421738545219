import React from "react";
import { useMediaQuery } from "react-responsive";

import UserTicket from "./UserTicket";
import UserTicketContent from "./UserTicketContent";
import * as manager from "../utils/manager";
import Image from "../components/Image";

const UserTicketType1 = ({
  id,
  avail,
  cost,
  title,
  text,
  image,
  imageTitle,
  imageRatio,
  titleLevel,
  gapFraction,
  titlev2,
  textv2,
  onRestart,
  onContinue,
  onAllTickets
}) => {
  const { versionToShow } = manager.getVersionToShow(useMediaQuery);

  const imageW = manager.IMAGE_WIDTHS.LANDSCAPE[versionToShow - 1][id];

  const SECTIONS =
    versionToShow <= 2
      ? ["0%", "70%", "30%", "0%"]
      : ["18%", "39%", "25%", "18%"];

  const tmpTitleLevel = versionToShow <= 2 ? 1 : titleLevel;
  const tmpGapFraction = versionToShow <= 2 ? 5 : gapFraction;
  const tmpTitle = versionToShow <= 2 && titlev2 ? titlev2 : title;
  const tmpText = versionToShow <= 2 && textv2 ? textv2 : text;

  const IMAGE_W = imageW;
  const IMAGE_H = IMAGE_W * imageRatio;


    return (
        <UserTicket
            onRestart={onRestart}
            onContinue={onContinue}
            onAllTickets={onAllTickets}
            title={title}
            text={text}
            image={image}
            id={id}
        >
        <UserTicketContent>
            <div className="ticketContentOuter">
                <Image id="ticketImg" alt="" src={image} style={{ height: "15rem", width: "15rem", MarginBottom: "1rem" }} />
                <div id="userTicketTextOuter" >
                    <div id="ticketTitle" >
                        {tmpTitle}
                    </div>
                    <div id="ticketText">
                        {tmpText}
                    </div>
                    <p style={{ MarginTop: "1rem" }}><span id="bookReq"></span></p>
                    <p style={{ MarginTop: "1rem" }}> Created: <span id="uploadDate"></span></p>
                </div>
            </div>
        </UserTicketContent>
        </UserTicket>
    );
    };

export default UserTicketType1;
