import React from "react";
import { useMediaQuery } from "react-responsive";

import MixedParagraphs from "../../components/MixedParagraphs";
import ScreenTypeD2 from "../../components/ScreenTypeD2";
import PATHS from "../../utils/constants";
import simple from "../../images/simple.jpg";
import elaborate from "../../images/elaborate.jpg";
import * as manager from "../../utils/manager";

//const paths = [
//  { limit: 0.25, path: PATHS.Tickets + "10" },
//  { limit: 0.5, path: PATHS.Tickets + "11" },
//  { limit: 0.75, path: PATHS.Tickets + "12" },
//  { limit: 1.0, path: PATHS.Tickets + "13" },
//];

const links = [
  { label: "simple", path: PATHS.Tickets + "10" },
  { label: "creative", path: PATHS.Tickets + "11" },
  { label: "lively", path: PATHS.Tickets + "12" },
  { label: "elaborate", path: PATHS.Tickets + "13" },
];

const ElaborateScreen = ({ history }) => {
  const { showPortrait, versionToShow } =
    manager.getVersionToShow(useMediaQuery);

  return (
    <ScreenTypeD2
      history={history}
      paragraph={MixedParagraphs.ELABORATE(versionToShow)}
      paragraphPortrait={MixedParagraphs.ELABORATE_PORTRAIT(versionToShow)}
      imageLeft={simple}
      imageRight={elaborate}
      links={links}
      sound="FuneralActivities"
    />
  );
};

export default ElaborateScreen;
